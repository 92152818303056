<template>
  <div>
    <!-- Banner -->
    <BannerComponent :data="data_banner" />

    <div class="description-about-us-card py-12">
      <v-container>
        <div class="v-html" v-html="about_us.description"></div>
      </v-container>
    </div>

    <v-container class="mt-0 mt-md-12">
      <div class="v-html" v-html="about_us.detail"></div>
    </v-container>

    <div
      v-if="about_us_thai_trade_center"
      :class="$vuetify.breakpoint.smAndUp ? 'container mb-16' : ''"
    >
      <!-- Desktop size -->
      <v-row
        v-if="$vuetify.breakpoint.smAndUp"
        justify="center"
        class="mb-6 mt-12"
      >
        <v-col cols="6" class="pb-1">
          <v-layout
            wrap
            justify-space-between
            align-center
            class="fill-height map-fill py-0"
          >
            <v-flex
              class="mt-12"
              style="font-family: Chulabhorn Likit Text;font-weight: bold;font-size: 32px;text-align: center;"
              xs12
            >
              <span>NORTH AMERICA </span>
            </v-flex>

            <v-flex mt-6 xs12>
              <v-img
                :aspect-ratio="4 / 3"
                eager
                alt="Canada"
                src="../../../assets/images/map/CANADA.png"
              ></v-img>
            </v-flex>

            <v-flex xs12>
              <v-img
                :aspect-ratio="4 / 3"
                eager
                alt="America"
                src="../../../assets/images/map/AMERICA.png"
              ></v-img>
            </v-flex>

            <v-flex xs12>
              <v-img
                :aspect-ratio="4 / 3"
                eager
                alt="Mexico"
                src="../../../assets/images/map/MEXICO.png"
              ></v-img>
            </v-flex>
          </v-layout>
        </v-col>
        <v-col cols="6" class="pb-0">
          <!-- CANADA -->
          <div
            class="expan-card mb-1"
            v-for="item in about_us_thai_trade_center.CANADA"
            :key="item.id"
          >
            <AboutUsDesktopCard :icon="icon" :item="item" />
          </div>

          <!-- AMERICA -->
          <div
            class="expan-card mb-1"
            v-for="item in about_us_thai_trade_center.NORTH_AMERICA"
            :key="item.id"
          >
            <AboutUsDesktopCard :icon="icon" :item="item" />
          </div>

          <!-- MAXICO -->
          <div
            class="expan-card mb-1"
            v-for="item in about_us_thai_trade_center.MAXICO"
            :key="item.id"
          >
            <AboutUsDesktopCard :icon="icon" :item="item" />
          </div>
        </v-col>
      </v-row>

      <v-layout v-else wrap mb-14>
        <v-flex class="bg-map" xs12 py-8>
          <!-- CANADA -->
          <div
            style="font-family: Chulabhorn Likit Text;font-weight: bold;font-size: 20px;text-align: center;"
          >
            NORTH AMERICA
          </div>
          <v-img
            alt="Canada"
            src="../../../assets/images/map/CANADA.png"
          ></v-img>
        </v-flex>
        <v-flex xs12 pa-1>
          <div
            class="expan-card mb-1"
            v-for="(item, i) in about_us_thai_trade_center.CANADA"
            :key="i"
          >
            <AboutUsMobileCard v-if="item" :icon="icon" :item="item" />
          </div>
        </v-flex>

        <!-- AMERICA -->
        <v-flex class="bg-map" xs12 py-8 mt-n1>
          <v-img
            alt="America"
            src="../../../assets/images/map/AMERICA.png"
          ></v-img>
        </v-flex>
        <v-flex xs12 pa-1>
          <div
            class="expan-card mb-1"
            v-for="(item, i) in about_us_thai_trade_center.NORTH_AMERICA"
            :key="i"
          >
            <AboutUsMobileCard v-if="item" :icon="icon" :item="item" />
          </div>
        </v-flex>

        <!-- MAXICO -->
        <v-flex class="bg-map" xs12 py-8 mt-n1>
          <v-img
            alt="Mexico"
            src="../../../assets/images/map/MEXICO.png"
          ></v-img>
        </v-flex>
        <v-flex xs12 pa-1>
          <div
            class="expan-card mb-1"
            v-for="(item, i) in about_us_thai_trade_center.MAXICO"
            :key="i"
          >
            <AboutUsMobileCard v-if="item" :icon="icon" :item="item" />
          </div>
        </v-flex>
      </v-layout>
    </div>

    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import BannerComponent from "../../../components/banner/BannerComponent.vue";
import AboutUsDesktopCard from "../../../components/card/AboutUsDesktopCard.vue";
import AboutUsMobileCard from "../../../components/card/AboutUsMobileCard.vue";
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import Service from "../../../services/";
import { domain_front } from "../../../services/Constants";

export default {
  components: {
    BannerComponent,
    AboutUsMobileCard,
    AboutUsDesktopCard,
    LoadingCircular,
  },
  data() {
    return {
      loading: false,
      data_banner: {},
      about_us: [],
      about_us_thai_trade_center: null,
      panel: [0],
      icon: null,
    };
  },
  metaInfo() {
    return {
      title: `About Us - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: this.about_us ? this.about_us.description : "",
        },
        {
          property: "og:title",
          content: "About Us - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: this.about_us ? this.about_us.description : "",
        },
        { property: "og:type", content: "About Us" },
        {
          property: "og:url",
          content: domain_front + "about-us",
        },
        {
          property: "og:image",
          content: this.data_banner.path_mobile,
        },
      ],
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      Service.about_us.getData().then((res) => {
        const { banner, about_us, about_us_thai_trade_center, icon } = res.data;
        this.data_banner = banner;
        this.about_us = about_us;
        this.about_us_thai_trade_center = about_us_thai_trade_center;
        this.icon = icon;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.v-html >>> img {
  width: 100% !important;
  height: auto !important;
}
.v-html >>> iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 1.78341013825;
}
@media only screen and (max-width: 768px) {
  .v-html >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html >>> iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1.78341013825;
  }
}
</style>

<style>
.description-about-us-card {
  background: #f9f9f9;
  font-family: ChulabhornLikitText;
  text-align: center;
}
.map-title {
  background: #f9f9f9;
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
}
.expan-card {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
}
.expan-card:hover {
  background: #ffffff;
  border: 1px solid #a40802;
  box-sizing: border-box;
}
.map-detail {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
}
.map-line {
  height: 5px;
}
.map-fill {
  background: linear-gradient(180deg, #d7e6f1 0%, #c3dff3 100%);
}
.v-application--is-ltr .v-expansion-panel-header__icon {
  padding-right: 20px;
}
.map-social:hover {
  background: chocolate;
}
.bg-map {
  background: linear-gradient(180deg, #d7e6f1 0%, #c3dff3 100%);
  /* transform: rotate(90deg); */
}
</style>
