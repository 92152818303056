import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/layout/Main.vue";
import Events from "../views/pages/event/Events.vue";
import Home from "../views/pages/home/Home.vue";
import EventDetail from "../views/pages/event/Detail.vue";
import AboutUs from "../views/pages/about-us/AboutUs.vue";
import News from "../views/pages/news/News.vue";
import NewsDetail from "../views/pages/news/Detail.vue";
import Faq from "../views/pages/faq/Faq.vue";
import ContactUs from "../views/pages/contact-us/ContactUs.vue";
import Thaipage from "../views/pages/thaipage/Thaipage.vue";
import ThaipageDetail from "../views/pages/thaipage/Detail.vue";
import SpanishPage from "../views/pages/spanish/SpanishPage.vue";
import MeetTradePartners from "../views/pages/meet-trade-partner/MeetTradePartners.vue";
import MeetTradePartnerList from "../views/pages/meet-trade-partner/MeetTradePartnerList.vue";
import MeetTradePartnerDetail from "../views/pages/meet-trade-partner/MeetTradePartnerDetail.vue";
import NewsProjectDetail from "../views/pages/news/NewsProjectDetail.vue";
import Search from "../views/pages/Search.vue";
import PageNotFound from "../views/layout/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/events/",
        name: "Events",
        component: Events,
      },
      {
        path: "/events/category/:slug",
        name: "Events",
        component: Events,
      },
      {
        path: "/news",
        name: "News",
        component: News,
      },
      {
        path: "/news/:slug",
        name: "NewsDetail",
        component: NewsDetail,
      },
      {
        path: "/project/:domain",
        name: "NewsProjectDetail",
        component: NewsProjectDetail,
      },
      {
        path: "/events/:slug",
        name: "EventDetail",
        component: EventDetail,
      },
      {
        path: "/about-us",
        name: "AboutUs",
        component: AboutUs,
      },
      {
        path: "/faq",
        name: "FAQ",
        component: Faq,
      },
      {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
      },
      {
        path: "/contact-us/:type",
        name: "ContactUs",
        component: ContactUs,
      },
      {
        path: "/thaipage",
        name: "Thaipage",
        component: Thaipage,
      },
      {
        path: "/thaipage/:slug",
        name: "ThaipageDetail",
        component: ThaipageDetail,
      },
      {
        path: "/spanish",
        name: "SpanishPage",
        component: SpanishPage,
      },
      {
        path: "/partners",
        name: "MeetTradePartners",
        component: MeetTradePartners,
      },
      {
        path: "/partner-list",
        name: "MeetTradePartnerList",
        component: MeetTradePartnerList,
      },
      {
        path: "/partners/:slug",
        name: "MeetTradePartnerDetail",
        component: MeetTradePartnerDetail,
      },
      {
        path: "/search/:slug_type",
        name: "Search",
        component: Search,
      },
      {
        path: "*",
        name: "404",
        component: PageNotFound,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
