<template>
  <div class=" fill-height">
    <v-layout class="d-flex justify-center align-center fill-height">
      <v-flex xs12 class="text-center mt-16">
        <v-icon style="font-size: 7rem" color="#FFA500">mdi-alert</v-icon>
        <div style="font-size: 48px" class=" font-weight-bold">
          Data Not Found.
        </div>

        <div class="mt-3">
          <v-btn outlined @click="$router.go(-1)" depressed tile>
            Go Back
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: "/",
    },
    textBtn: {
      type: String,
      default: "Go Back",
    },
  },
};
</script>

<style></style>
