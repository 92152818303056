<template>
  <v-container class="thaipage-con">
    <v-row class="my-sm-3">
      <v-col class="thaipage-head">
        FOR THAI EXPORTERS | คลังข้อมูล:
        <span style="color: #000;font-size: 26px;opacity: 0.5;">
          สำหรับผู้ส่งออก
        </span>
      </v-col>
    </v-row>

    <v-row class="section-1 mb-md-6 mt-0">
      <v-col cols="12" sm="12" md="8" order="2" order-md="1">
        <v-card flat tile>
          <v-img
            width="100%"
            :aspect-ratio="16 / 9"
            :src="
              $vuetify.breakpoint.smAndUp
                ? banner.path_desktop
                : banner.path_mobile
                ? banner.path_mobile
                : banner.path_desktop
            "
            :alt="subcategory_thaipage_name"
          ></v-img>
          <div class="card-detail py-3">
            <div class="card-date">{{ category_thaipage_name }}</div>
            <div class="card-title">
              <span class="link-hover">
                {{ subcategory_thaipage_name }}
              </span>
            </div>
            <div
              class="card-desc"
              :class="$vuetify.breakpoint.mdAndUp ? 'ellipsis-2' : 'ellipsis-4'"
            >
              {{ subcategory_thaipage_description }}
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        order="1"
        order-md="2"
        class="cate-list pt-3"
      >
        <div v-if="categorys.length > 0">
          <!-- Desktop size -->
          <div v-if="$vuetify.breakpoint.mdAndUp">
            <v-list class="py-0">
              <v-list-group
                color="#F9F9F9"
                class="mb-1 v-list-item--active"
                v-for="(category, cate) in categorys"
                :key="cate"
                no-action
                :append-icon="
                  category.subcategory_thaipages.length > 0
                    ? 'mdi-chevron-down'
                    : ''
                "
                active-class="active-cate"
              >
                <template v-slot:activator>
                  <v-list-item-content class="title-cate">
                    <v-list-item-title
                      style="overflow: unset;"
                      @click="
                        category.subcategory_thaipages.length == 0
                          ? onClickFilter(category)
                          : ''
                      "
                      v-text="category.name"
                    >
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-if="category.subcategory_thaipages.length > 0">
                  <v-list-item
                    v-for="(subcategory, sub) in category.subcategory_thaipages"
                    :key="sub"
                    class="mb-1 bg-sub-list"
                    :class="
                      category_thaipage_id == category.id &&
                      subcategory_thaipage_id == subcategory.id
                        ? 'active-list'
                        : ''
                    "
                    @click="onClickFilter(category, subcategory)"
                  >
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-content class="title-subcate mb-1">
                          <v-list-item-title
                            v-bind="attrs"
                            v-on="on"
                            style="overflow: unset;"
                            v-text="subcategory.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <span>{{ subcategory.name }}</span>
                    </v-tooltip>
                  </v-list-item>
                </template>
              </v-list-group>
            </v-list>
          </div>
          <!-- Mobile size -->
          <div v-else class="filter-mobile mt-4">
            <v-row>
              <v-col cols="12">
                <v-select
                  menu-props="offsetY"
                  placeholder="Category"
                  v-model="category_thaipage_id"
                  hide-details
                  clearable
                  :items="categorys"
                  item-value="id"
                  item-text="name"
                  outlined
                  return-object
                  @change="onHandleSeleteCategory"
                >
                  <template v-slot:selection="{ item }">
                    <span class="font-weight-bold">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <!-- <v-col cols="12" v-if="subcategoryList.length > 0"> -->
              <v-col cols="12">
                <v-select
                  menu-props="offsetY"
                  placeholder="Subcategory"
                  v-model="subcategory_thaipage_id"
                  item-value="id"
                  item-text="name"
                  hide-details
                  clearable
                  :items="subcategoryList"
                  outlined
                  return-object
                  @change="onHandleSeleteSubcategory"
                >
                  <template v-slot:selection="{ item }">
                    <span class="font-weight-bold">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- News -->
    <div style="margin-bottom: 5rem" class="mt-md-10">
      <v-row v-if="thaipage_news.length > 0">
        <v-col
          cols="12"
          sm="4"
          md="4"
          v-for="(item, i) in thaipage_news"
          :key="i"
          class="mb-6"
        >
          <v-card flat tile>
            <v-row>
              <v-col md="12" sm="12" cols="4">
                <v-img
                  @click="viewDetailThaipage(item)"
                  class="image-hover"
                  :aspect-ratio="$vuetify.breakpoint.mdAndUp ? 4 / 3 : 1"
                  :src="item.thumbnail"
                  :alt="item.title"
                  contain
                  lazy-src="../../../assets/images/default/default_4_3.png"
                >
                </v-img>
              </v-col>
              <v-col md="12" sm="12" cols="8" class="pt-2 pt-sm-0 pl-0 pl-sm-3">
                <div class="card-detail">
                  <div class=" thaipage-card-date">
                    {{ item.created_at }}
                  </div>
                  <div class="thaipage-card-title ellipsis-3">
                    <span class="link-hover" @click="viewDetailThaipage(item)">
                      {{ item.title }}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- Button Home Page -->
      <div v-if="thaipage_news.length > 0" class="mt-16">
        <v-layout
          justify-center
          my-6
          v-if="paginate.last_page != paginate.current_page"
        >
          <v-flex shrink>
            <btn-more :loading="loadingBtn" @on-click="onClickMore()" />
          </v-flex>
        </v-layout>
      </div>
    </div>
    <loading-circular :loading="loading" />
  </v-container>
</template>

<script>
import BtnMore from "../../../components/button/BtnMore.vue";
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import { domain_front } from "../../../services/Constants";

export default {
  components: { BtnMore, LoadingCircular },
  data() {
    return {
      banner: {},
      categorys: [],
      subcategoryList: [],
      thaipage_news: [],
      paginate: {
        current_page: 1,
        last_page: 1,
      },
      category_thaipage_id: "",
      subcategory_thaipage_id: "",
      category_thaipage_name: "",
      subcategory_thaipage_name: "",
      subcategory_thaipage_description: "",
      loadingBtn: false,
      loading: false,
    };
  },
  metaInfo() {
    return {
      title: `Thaipage - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: "Thaipage on Thai Trade USA",
        },
        {
          property: "og:title",
          content: "Thaipage - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: "Thaipage on ThaiTradeUSA.com",
        },
        { property: "og:type", content: "Thaipage" },
        {
          property: "og:url",
          content: domain_front + `thaipage?category=1&subcategory=8`,
        },
        {
          property: "og:image",
          content: this.banner ? this.banner.path_desktop : "",
        },
      ],
    };
  },
  async created() {
    // set default router and params
    if (!this.$route.query.category) {
      this.$router.replace(`/thaipage?category=1&subcategory=8`);
    }

    window.scrollTo(0, 0);

    if (this.$route.query) {
      this.category_thaipage_id =
        this.$route.query.category === undefined
          ? ""
          : this.$route.query.category;
      this.subcategory_thaipage_id =
        this.$route.query.subcategory === undefined
          ? ""
          : this.$route.query.subcategory;
    }

    this.loading = true;
    await Promise.all([
      this.getData(),
      this.getThaipageNews(this.paginate.current_page),
    ])
      .then((res) => {
        const categorys = this.categorys.find(
          (element) => element.id == this.$route.query.category
        );
        const subcategorys = categorys.subcategory_thaipages.find(
          (element) => element.id == this.$route.query.subcategory
        );
        this.category_thaipage_name = categorys ? categorys.name : "";
        this.subcategory_thaipage_name = subcategorys ? subcategorys.name : "";
        this.subcategory_thaipage_description = subcategorys
          ? subcategorys.description
          : "";

        this.loading = false;
      })
      .catch((error) => {
        console.log("error", error);
        this.loading = false;
      });
  },
  methods: {
    async getThaipageNews(page = 1) {
      const { data } = await this.$service.thaipage.getThaipageNews({
        page: page,
        category_thaipage_id: this.category_thaipage_id,
        subcategory_thaipage_id: this.subcategory_thaipage_id,
      });
      this.thaipage_news = data.data;
      this.paginate = {
        current_page: data.current_page,
        last_page: data.last_page,
      };
      return true;
    },
    async getData() {
      const { data } = await this.$service.thaipage.getData();
      const { banner, categorys } = data;
      this.banner = banner;
      this.categorys = categorys;
      return true;
    },
    onClickFilter(category_thaipage = null, subcategory_thaipage = null) {
      this.category_thaipage_name = category_thaipage
        ? category_thaipage.name
        : "";
      this.subcategory_thaipage_name = subcategory_thaipage
        ? subcategory_thaipage.name
        : "";
      this.subcategory_thaipage_description = subcategory_thaipage
        ? subcategory_thaipage.description
        : "";
      this.category_thaipage_id = category_thaipage ? category_thaipage.id : "";
      this.subcategory_thaipage_id =
        subcategory_thaipage && subcategory_thaipage.id
          ? subcategory_thaipage.id
          : "";

      this.loading = true;
      this.getThaipageNews(1).then((res) => {
        this.loading = false;
      });

      if (subcategory_thaipage != null) {
        this.$router.replace(
          `/thaipage?category=${category_thaipage.id}&subcategory=${subcategory_thaipage.id}`
        );
      } else {
        this.$router.replace(`/thaipage?category=${category_thaipage.id}`);
      }
    },
    onClickMore() {
      this.loadingBtn = true;
      this.$service.thaipage
        .getThaipageNews({
          page: this.paginate.current_page + 1,
          category_thaipage_id: this.category_thaipage_id,
          subcategory_thaipage_id: this.subcategory_thaipage_id,
        })
        .then((res) => {
          const { data } = res;
          this.thaipage_news = [...this.thaipage_news, ...data.data];
          this.paginate = {
            current_page: data.current_page,
            last_page: data.last_page,
          };
          this.loadingBtn = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingBtn = false;
        });
    },
    onHandleSeleteCategory(item) {
      if (item) {
        // set list of subcateList
        this.subcategoryList = item.subcategory_thaipages;
        // set cate_id
        this.category_thaipage_id = item.id;
        // set name cate
        this.category_thaipage_name = item.name;

        // check ว่ามี subcate ไหม ถ้าไม่ก็ filter แค่ cate
        if (item.subcategory_thaipages.length == 0) {
          // replace route
          this.$router.replace(`/thaipage?category=${item.id}`);

          // if subcategory list = null => set subcate name and subcate desc = ""
          this.subcategory_thaipage_name = "";
          this.subcategory_thaipage_description = "";

          this.subcategory_thaipage_id = "";
          this.loading = true;
          this.getThaipageNews(1).then((res) => {
            this.loading = false;
          });
        }
      } else {
        this.category_thaipage_id = "";
        this.subcategory_thaipage_id = "";
        this.subcategoryList = [];
      }
    },
    onHandleSeleteSubcategory(subcategory_thaipage) {
      if (subcategory_thaipage) {
        this.$router.replace(
          `/thaipage?category=${this.category_thaipage_id}&subcategory=${subcategory_thaipage.id}`
        );

        // set name subcate
        this.subcategory_thaipage_name = subcategory_thaipage.name;
        // set desc subcate
        this.subcategory_thaipage_description =
          subcategory_thaipage.description;

        // check ว่ามี subcate ไหม ถ้ามี ก็ filter ทั้ง cate และ subcate
        this.subcategory_thaipage_id = subcategory_thaipage.id;
        this.loading = true;
        this.getThaipageNews(1).then((res) => {
          this.loading = false;
        });
      } else {
        this.subcategory_thaipage_id = "";
      }
    },
  },
};
</script>

<style>
.thaipage-card-date {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  color: #6a6a6a;
}
.thaipage-card-title {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
}
.card-detail .card-date {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}
.card-detail .card-title {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}
.card-detail .card-desc {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}
.thaipage-head {
  font-family: ChulabhornLikitText;
  font-style: normal;
  /* font-weight: 500; */
  font-size: clamp(28px, 4.5vw, 40px);
  line-height: 100%;
  color: #000000;
}
.cate-list {
  max-height: 450px !important;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #938e94;
}
.cate-list::-webkit-scrollbar {
  width: 3px;
}
.cate-list::-webkit-scrollbar-track {
  background: #e2e2e2;
}
.cate-list::-webkit-scrollbar-thumb {
  background-color: #938e94;
  border-radius: 6px;
  border: 3px solid #938e94;
}
.bg-sub-list {
  background-color: #f9f9f9;
}
.bg-sub-list:hover {
  cursor: pointer;
  background: #e8e8f2;
}
.active-cate {
  background: #0066b3 !important;
  color: #fff !important;
}
.active-subcate {
  background: #e8e8f2 !important;
}
.title-cate {
  font-family: Chulabhorn Likit Text;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.title-subcate {
  font-family: Chulabhorn Likit Text;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
}
.active-list {
  background: #e8e8f2 !important;
}
</style>
