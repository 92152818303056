<template>
  <div class="mb-6">
    <div v-if="data">
      <div v-if="data.title">
        <v-layout justify-start class=" py-3" style="background: #f9f9f9;">
          <v-flex>
            <v-container>
              <v-layout
                justify-center
                :class="
                  $vuetify.breakpoint.smAndUp
                    ? 'title-detail'
                    : 'title-detail-mobile'
                "
              >
                <v-flex>
                  {{ data.title }}
                </v-flex>
              </v-layout>

              <v-layout wrap class="title-desc mt-3 mt-sm-6">
                <v-flex xs12 sm2 class="font-weight-bold">
                  Products Category :
                </v-flex>
                <v-flex xs12 sm10 class="pl-0 pl-sm-2">
                  <span
                    v-for="(subcate, i) in data.meet_trade_partner_subcategorys"
                    :key="i"
                  >
                    {{
                      subcate && subcate.subcategory.name
                        ? subcate.subcategory.name
                        : ""
                    }}
                    <span
                      v-if="
                        i + 1 != data.meet_trade_partner_subcategorys.length
                      "
                      >,</span
                    >
                  </span>
                </v-flex>
              </v-layout>
              <v-layout wrap class="title-desc">
                <v-flex xs12 sm2 class="font-weight-bold">
                  Products :
                </v-flex>
                <v-flex xs12 sm10 class="pl-0 pl-sm-2">
                  {{ data.products ? data.products : "-" }}
                </v-flex>
              </v-layout>
              <v-layout wrap class="title-desc">
                <v-flex xs12 sm2 class="font-weight-bold">
                  Export experiences :
                </v-flex>
                <v-flex xs12 sm10 class="pl-0 pl-sm-2">
                  {{ data.export_experiences ? data.export_experiences : "-" }}
                </v-flex>
              </v-layout>
              <v-layout wrap class="title-desc">
                <v-flex xs12 sm2 class="font-weight-bold">
                  Established :
                </v-flex>
                <v-flex xs12 sm10 class="pl-0 pl-sm-2">
                  {{ data.established ? data.established : "-" }}
                </v-flex>
              </v-layout>
              <v-layout wrap class="title-desc">
                <v-flex xs12 sm2 class="font-weight-bold">
                  Number of employees :
                </v-flex>
                <v-flex xs12 sm10 class="pl-0 pl-sm-2">
                  {{ data.employee_no ? data.employee_no : "-" }}
                </v-flex>
              </v-layout>
              <v-layout wrap class="title-desc">
                <v-flex xs12 sm2 class="font-weight-bold">
                  Credentials :
                </v-flex>
                <v-flex xs12 sm10 class="pl-0 pl-sm-2">
                  {{ data.credentials ? data.credentials : "-" }}
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>

        <v-container class="mt-3" v-if="$vuetify.breakpoint.smAndUp">
          <v-layout wrap class="title-desc">
            <v-flex xs12 sm2 class="font-weight-bold">
              Address :
            </v-flex>
            <v-flex xs12 sm10 class="pl-0 pl-sm-2">
              {{ data.address ? data.address : "-" }}
            </v-flex>
          </v-layout>
          <v-layout wrap class="title-desc">
            <v-flex xs12 sm2 class="font-weight-bold">
              Tel :
            </v-flex>
            <v-flex xs12 sm10 class="pl-0 pl-sm-2">
              {{ data.tel ? data.tel : "-" }}
            </v-flex>
          </v-layout>
          <v-layout wrap class="title-desc">
            <v-flex xs12 sm2 class="font-weight-bold">
              Fax :
            </v-flex>
            <v-flex xs12 sm10 class="pl-0 pl-sm-2">
              {{ data.fax ? data.fax : "-" }}
            </v-flex>
          </v-layout>
          <v-layout wrap class="title-desc">
            <v-flex xs12 sm2 class="font-weight-bold">
              Contact :
            </v-flex>
            <v-flex xs12 sm10 class="pl-0 pl-sm-2">
              {{ data.contact ? data.contact : "-" }}
            </v-flex>
          </v-layout>
          <v-layout wrap class="title-desc">
            <v-flex xs12 sm2 class="font-weight-bold">
              Website :
            </v-flex>
            <v-flex xs12 sm10 class="pl-0 pl-sm-2" style="color: #0066B3">
              <a
                v-if="data.facebook"
                :href="data.website ? website : ''"
                class="mr-3"
                target="_blank"
              >
                {{ data.website ? data.website : "-" }}
              </a>
            </v-flex>
          </v-layout>
          <v-layout wrap class="title-desc">
            <v-flex xs12 sm2 class="font-weight-bold">
              Email :
            </v-flex>
            <v-flex xs12 sm10 class="pl-0 pl-sm-2" style="color: #0066B3">
              <span v-if="data.email">
                <a :href="'mailto:' + data.email" class="mr-3" target="_blank">
                  {{ data.email }}
                </a>
              </span>
              <span v-else>-</span>
            </v-flex>
          </v-layout>
          <v-layout wrap class="title-desc mt-1">
            <v-flex xs12 sm2 class="font-weight-bold">
              Social Media :
            </v-flex>
            <v-flex xs12 sm10 class="pl-2 d-flex">
              <a
                v-if="data.facebook"
                :href="data.facebook"
                class="mr-3"
                target="_blank"
              >
                <v-img
                  contain
                  width="28"
                  aspect-ratio="1"
                  :src="data.icon.facebook_color"
                  alt=""
                />
              </a>

              <a
                v-if="data.instagram"
                :href="data.instagram"
                class="mr-3"
                target="_blank"
              >
                <v-img
                  contain
                  width="28"
                  aspect-ratio="1"
                  :src="data.icon.instagram_color"
                  alt=""
                />
              </a>

              <a
                v-if="data.twitter"
                :href="data.twitter"
                class="mr-3"
                target="_blank"
              >
                <v-img
                  contain
                  width="28"
                  aspect-ratio="1"
                  :src="data.icon.twitter_color"
                  alt=""
                />
              </a>

              <a
                v-if="data.youtube"
                :href="data.youtube"
                class="mr-3"
                target="_blank"
              >
                <v-img
                  contain
                  width="28"
                  aspect-ratio="1"
                  :src="data.icon.youtube_color"
                  alt=""
                />
              </a>

              <a
                v-if="data.linkin"
                :href="data.linkin"
                class="mr-3"
                target="_blank"
              >
                <v-img
                  contain
                  width="28"
                  aspect-ratio="1"
                  :src="data.icon.linkin_color"
                  alt=""
                />
              </a>

              <a
                v-if="data.tiktok"
                :href="data.tiktok"
                class="mr-3"
                target="_blank"
              >
                <v-img
                  contain
                  width="28"
                  aspect-ratio="1"
                  :src="data.icon.tiktok_color"
                  alt=""
                />
              </a>

              <a
                v-if="data.line"
                :href="data.line"
                class="mr-3"
                target="_blank"
              >
                <v-img
                  contain
                  width="28"
                  aspect-ratio="1"
                  :src="data.icon.line_color"
                  alt=""
                />
              </a>

              <a
                v-if="data.wechat"
                :href="data.wechat"
                class="mr-3"
                target="_blank"
              >
                <v-img
                  contain
                  width="28"
                  aspect-ratio="1"
                  :src="data.icon.wechat_color"
                  alt=""
                />
              </a>

              <a
                v-if="data.pinterest"
                :href="data.pinterest"
                class="mr-3"
                target="_blank"
              >
                <v-img
                  contain
                  width="28"
                  aspect-ratio="1"
                  :src="data.icon.pinterest_color"
                  alt=""
                />
              </a>

              <a
                v-if="data.whatapp"
                :href="data.whatapp"
                class="mr-3"
                target="_blank"
              >
                <v-img
                  contain
                  width="28"
                  aspect-ratio="1"
                  :src="data.icon.whatapp_color"
                  alt=""
                />
              </a>
            </v-flex>
          </v-layout>

          <div class="v-html mt-12 v-html-desktop" v-html="data.detail"></div>
        </v-container>

        <v-container class="mt-1" v-else>
          <v-layout wrap class="title-desc">
            <v-flex xs12 sm2 class="font-weight-bold">
              Address :
            </v-flex>
            <v-flex xs12 sm10 class="pl-0 pl-sm-2">
              {{ data.address ? data.address : "-" }}
            </v-flex>
          </v-layout>
          <div v-if="in_show_desc">
            <v-layout wrap class="title-desc">
              <v-flex xs12 sm2 class="font-weight-bold">
                Tel :
              </v-flex>
              <v-flex xs12 sm10 class="pl-0 pl-sm-2">
                {{ data.tel ? data.tel : "-" }}
              </v-flex>
            </v-layout>
            <v-layout wrap class="title-desc">
              <v-flex xs12 sm2 class="font-weight-bold">
                Fax :
              </v-flex>
              <v-flex xs12 sm10 class="pl-0 pl-sm-2">
                {{ data.fax ? data.fax : "-" }}
              </v-flex>
            </v-layout>
            <v-layout wrap class="title-desc">
              <v-flex xs12 sm2 class="font-weight-bold">
                Contact :
              </v-flex>
              <v-flex xs12 sm10 class="pl-0 pl-sm-2">
                {{ data.contact ? data.contact : "-" }}
              </v-flex>
            </v-layout>
            <v-layout wrap class="title-desc">
              <v-flex xs12 sm2 class="font-weight-bold">
                Website :
              </v-flex>
              <v-flex xs12 sm10 class="pl-0 pl-sm-2" style="color: #0066B3">
                <a
                  v-if="data.facebook"
                  :href="data.website ? website : ''"
                  class="mr-3"
                  target="_blank"
                >
                  {{ data.website ? data.website : "-" }}
                </a>
              </v-flex>
            </v-layout>
            <v-layout wrap class="title-desc">
              <v-flex xs12 sm2 class="font-weight-bold">
                Email :
              </v-flex>
              <v-flex xs12 sm10 class="pl-0 pl-sm-2" style="color: #0066B3">
                <span v-if="data.email">
                  <a
                    :href="'mailto:' + data.email"
                    class="mr-3"
                    target="_blank"
                  >
                    {{ data.email }}
                  </a>
                </span>
                <span v-else>-</span>
              </v-flex>
            </v-layout>
            <v-layout wrap class="title-desc mt-1">
              <v-flex xs12 sm2 class="font-weight-bold">
                Social Media :
              </v-flex>
              <v-flex xs12 sm10 class="pl-0 pl-sm-2 d-flex mt-2 mt-sm-0">
                <a
                  v-if="data.facebook"
                  :href="data.facebook"
                  class="mr-3"
                  target="_blank"
                >
                  <v-img
                    contain
                    width="28"
                    aspect-ratio="1"
                    :src="data.icon.facebook_color"
                    alt=""
                  />
                </a>

                <a
                  v-if="data.instagram"
                  :href="data.instagram"
                  class="mr-3"
                  target="_blank"
                >
                  <v-img
                    contain
                    width="28"
                    aspect-ratio="1"
                    :src="data.icon.instagram_color"
                    alt=""
                  />
                </a>

                <a
                  v-if="data.twitter"
                  :href="data.twitter"
                  class="mr-3"
                  target="_blank"
                >
                  <v-img
                    contain
                    width="28"
                    aspect-ratio="1"
                    :src="data.icon.twitter_color"
                    alt=""
                  />
                </a>

                <a
                  v-if="data.youtube"
                  :href="data.youtube"
                  class="mr-3"
                  target="_blank"
                >
                  <v-img
                    contain
                    width="28"
                    aspect-ratio="1"
                    :src="data.icon.youtube_color"
                    alt=""
                  />
                </a>

                <a
                  v-if="data.linkin"
                  :href="data.linkin"
                  class="mr-3"
                  target="_blank"
                >
                  <v-img
                    contain
                    width="28"
                    aspect-ratio="1"
                    :src="data.icon.linkin_color"
                    alt=""
                  />
                </a>

                <a
                  v-if="data.tiktok"
                  :href="data.tiktok"
                  class="mr-3"
                  target="_blank"
                >
                  <v-img
                    contain
                    width="28"
                    aspect-ratio="1"
                    :src="data.icon.tiktok_color"
                    alt=""
                  />
                </a>

                <a
                  v-if="data.line"
                  :href="data.line"
                  class="mr-3"
                  target="_blank"
                >
                  <v-img
                    contain
                    width="28"
                    aspect-ratio="1"
                    :src="data.icon.line_color"
                    alt=""
                  />
                </a>

                <a
                  v-if="data.wechat"
                  :href="data.wechat"
                  class="mr-3"
                  target="_blank"
                >
                  <v-img
                    contain
                    width="28"
                    aspect-ratio="1"
                    :src="data.icon.wechat_color"
                    alt=""
                  />
                </a>

                <a
                  v-if="data.pinterest"
                  :href="data.pinterest"
                  class="mr-3"
                  target="_blank"
                >
                  <v-img
                    contain
                    width="28"
                    aspect-ratio="1"
                    :src="data.icon.pinterest_color"
                    alt=""
                  />
                </a>

                <a
                  v-if="data.whatapp"
                  :href="data.whatapp"
                  class="mr-3"
                  target="_blank"
                >
                  <v-img
                    contain
                    width="28"
                    aspect-ratio="1"
                    :src="data.icon.whatapp_color"
                    alt=""
                  />
                </a>
              </v-flex>
            </v-layout>
          </div>

          <div v-if="in_show_desc" class="show-desc">
            <div class="d-flex justify-center align-end mt-8">
              <v-btn
                @click.native="in_show_desc = false"
                class="text-capitalize font-weight-bold font-cl px-12 py-3"
                outlined
                color="#0066B3"
                tile
                style="font-size: 14px"
              >
                Short
              </v-btn>
            </div>
          </div>
          <div v-else>
            <div class=" d-flex justify-center align-end hide-desc">
              <btn-more @on-click="in_show_desc = true" />
            </div>
          </div>

          <div
            class="v-html mt-8 mb-12 v-html-mobile"
            v-html="data.detail"
          ></div>
        </v-container>
      </div>
      <div v-else>
        <data-not-found />
      </div>
    </div>
    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import BtnMore from "../../../components/button/BtnMore.vue";
import DataNotFound from "../../../components/DataNotFound.vue";
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import { domain_front } from "../../../services/Constants";

export default {
  components: { BtnMore, LoadingCircular, DataNotFound },
  data() {
    return {
      data: null,
      in_show_desc: false,
      loading: true,
    };
  },
  metaInfo() {
    return {
      title: `${this.data ? this.data.title : ""} - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: this.data
            ? this.data.title + " on ThaiTradeUSA - " + this.data.description
            : "",
        },
        {
          property: "og:title",
          content: `${this.data ? this.data.title : ""} - ThaiTradeUSA.com`,
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: this.data
            ? this.data.title + " on ThaiTradeUSA - " + this.data.description
            : "",
        },
        { property: "og:type", content: "meet trade partner" },
        {
          property: "og:url",
          content: domain_front + "partners/" + this.$route.params.slug,
        },
        {
          property: "og:image",
          content: this.data ? this.data.thumbnail : "",
        },
      ],
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getDetail();
  },
  computed: {
    website() {
      let website = this.data.website;
      if (
        this.data.website.search("https://") == -1 &&
        this.data.website.search("http://") == -1
      ) {
        website = "https://" + this.data.website;
      }
      return website;
    },
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.$service.partner
        .getPartnerById(
          this.$route.params.slug
            .split("-")
            .slice(-1)
            .pop()
        )
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err.response);
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.v-html-desktop {
  margin-bottom: 7rem;
}
.v-html-mobile {
  margin-bottom: 1rem;
}
</style>

<style scoped>
.v-html >>> img {
  width: 100% !important;
  height: auto !important;
}
.v-html >>> iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 1.78341013825;
}

@media only screen and (max-width: 768px) {
  .v-html >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html >>> iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1.78341013825;
  }
}
.title-detail {
  font-family: "ChulabhornLikitText";
  font-style: normal;
  font-size: 64px;
  line-height: 100%;
}
.title-detail-mobile {
  font-family: "ChulabhornLikitText";
  font-style: normal;
  font-size: 28px;
  line-height: 100%;
}
.title-desc {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-size: 14px;
  line-height: 28px;
}
.hide-desc {
  height: 175px;
  margin-top: -7rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 65%);
  position: relative;
  z-index: 999;
}
</style>
