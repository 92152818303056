<template>
  <v-row no-gutters justfy="center" align="center">
    <!-- Image -->
    <v-col cols="12">
      <v-img
        @click="viewDetail(item.id, item.title_event)"
        class="image-hover"
        :aspect-ratio="4 / 3"
        :src="item.thumbnail"
        :alt="item.title_event"
        lazy-src="../../assets/images/default/default_4_3.png"
      >
        <v-row class="white--text" no-gutters justfy="end" align="end">
          <v-col cols="12" align="end">
            <img
              v-if="item.type === 'ONLINE'"
              width="34"
              src="@/assets/images/events/type-online.png"
            />
            <img
              v-if="item.type === 'THAILAND'"
              width="34"
              src="@/assets/images/events/type-th.png"
            />
            <img
              v-if="item.type === 'USA'"
              width="34"
              src="@/assets/images/events/type-usa.png"
            />
            <img
              v-if="item.type === 'CANADA'"
              width="34"
              src="@/assets/images/events/type-canada.png"
            />
            <img
              v-if="item.type === 'MEXICO'"
              width="34"
              src="@/assets/images/events/type-mexico.png"
            />
            <img
              v-if="item.type === 'SPAIN'"
              width="34"
              src="@/assets/images/events/type-spain.png"
            />
          </v-col>
        </v-row>
       
      </v-img>
    </v-col>

    <!-- Description -->
    <v-col cols="12" class="font-clamp-10-14">
      <div
        class="event-title mt-4 mb-2 font-cl font-weight-bold"
        @click="viewDetail(item.id, item.title_event)"
      >
        {{ item.title_event }}
      </div>
      <div class="event-date">{{ item.start_date }} - {{ item.end_date }}</div>
      <div class="event-time ">{{ item.start_time }} - {{ item.end_time }}</div>
      <div class="event-location mb-1">{{ item.location }} {{ item.city }}</div>
      <span
        class="event-category"
        v-for="(subCate, i) in item.subcategorys"
        :key="i"
      >
        {{ subCate.name }}
        <span v-if="i + 1 != item.subcategorys.length">,</span>
      </span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    id: null,
    item: null,
    imagePosition: {
      default: "left",
      type: String,
    },
  },
};
</script>

<style></style>
