<template>
  <div>
    <!-- Banner -->
    <Banner :items="banners" />

    <!-- Container -->
    <v-container>
      <v-layout justify-center class="mt-sm-8 mt-2 mb-12">
        <v-flex lg8 md9 sm12>
          <!-- Category  -->

          <div v-if="categorys.length > 1">
            <!-- Desktop Size -->
            <v-layout wrap v-if="$vuetify.breakpoint.smAndUp" class="mx-n2">
              <v-flex
                class="px-2 xs4 mb-4"
                v-for="item in categorys.slice(0, 3)"
                :key="item.id"
              >
                <div class="category">
                  <v-btn
                    :outlined="item.id != category_id"
                    :color="item.id == category_id ? '#0066b3' : '#000'"
                    :dark="item.id == category_id"
                    class="text-capitalize font-weight-bold"
                    tile
                    depressed
                    block
                    @click="onClickCategory(item)"
                  >
                    {{ item.name }}
                  </v-btn>
                </div>
              </v-flex>

              <v-flex
                class=" px-2"
                v-for="item in categorys.slice(3, 7)"
                :key="item.id"
              >
                <v-btn
                  :outlined="item.id != category_id"
                  :color="item.id == category_id ? '#0066b3' : '#000'"
                  :dark="item.id == category_id"
                  class="text-capitalize font-weight-bold"
                  tile
                  depressed
                  block
                  @click="onClickCategory(item)"
                >
                  {{ item.name }}
                </v-btn>
              </v-flex>
            </v-layout>

            <!-- Mobile Size -->
            <div v-else class="event-carousel">
              <VueSlickCarousel
                v-bind="{
                  dots: true,
                  infinite: false,
                  speed: 500,
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  initialSlide: 0,
                  responsive: [
                    {
                      breakpoint: 480,
                      settings: {
                        dots: false,
                        infinite: true,
                        centerMode: true,
                        variableWidth: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ],
                }"
              >
                <div v-for="(item, i) in categorys" :key="i">
                  <v-layout class="mx-2">
                    <v-flex class="shrink">
                      <div class="category">
                        <v-btn
                          width="160"
                          :outlined="item.id != category_id"
                          :color="item.id == category_id ? '#0066b3' : '#000'"
                          :dark="item.id == category_id"
                          class="text-capitalize font-weight-bold"
                          tile
                          depressed
                          block
                          @click="onClickCategory(item)"
                        >
                          {{ item.name }}
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </div>
              </VueSlickCarousel>
            </div>
          </div>

          <!-- End -->
        </v-flex>
      </v-layout>

      <div v-for="(itemArray, index) in eventsRow" :key="index">
        <!-- Form Even -->
        <div v-if="itemArray.current_page % 2 == 0">
          <div v-if="itemArray.data">
            <v-row
              justify="center"
              class="mb-10"
              v-if="itemArray.data.length > 2"
            >
              <v-col
                cols="12"
                md="3"
                sm="6"
                class="mb-2 pa-3"
                v-for="(event, i) in itemArray.data.slice(0, 4)"
                :key="i"
              >
                <EventCardVertical :item="event" />
              </v-col>
            </v-row>
          </div>

          <v-layout justify-center class="">
            <v-flex lg8 md9 sm12>
              <div v-if="itemArray.data">
                <EventCardHorizontal
                  v-if="itemArray.data.length >= 5"
                  imagePosition="left"
                  :item="itemArray.data[4]"
                  class="mt-6 mt-sm-0 mb-sm-4 mb-10"
                />
                <EventCardHorizontal
                  v-if="itemArray.data.length >= 6"
                  imagePosition="right"
                  :item="itemArray.data[5]"
                  class="mb-3"
                />
              </div>
            </v-flex>
          </v-layout>
        </div>

        <!-- Form Odd -->
        <div v-else>
          <v-layout justify-center class="">
            <v-flex lg8 md9 sm12>
              <div v-if="itemArray.data">
                <EventCardHorizontal
                  v-if="itemArray.data.length > 0"
                  imagePosition="left"
                  :item="itemArray.data[0]"
                  class="mt-6 mt-sm-0 mb-sm-4 mb-10"
                />
                <EventCardHorizontal
                  v-if="itemArray.data.length > 1"
                  imagePosition="right"
                  :item="itemArray.data[1]"
                  class="mb-10"
                />
              </div>
            </v-flex>
          </v-layout>

          <div v-if="itemArray.data">
            <v-row
              justify="center"
              class="mb-10"
              v-if="itemArray.data.length > 2"
            >
              <v-col
                cols="12"
                md="3"
                sm="6"
                class="mb-2 pa-3"
                v-for="(event, i) in itemArray.data.slice(2)"
                :key="i"
              >
                <EventCardVertical :item="event" />
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <div v-if="events.length <= 2" class="mb-16"></div>
      <!-- Button Home Page -->
      <div v-if="events">
        <v-layout
          justify-center
          mt-6
          mb-12
          v-if="paginate.last_page != paginate.current_page"
        >
          <v-flex shrink>
            <BtnMore :loading="loadingBtn" @on-click="onClickMore()" />
          </v-flex>
        </v-layout>
      </div>
    </v-container>
    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import EventCardHorizontal from "../../../components/event-card/EventCardHorizontal.vue";
import Banner from "../../layout/Banner.vue";
import EventCardVertical from "../../../components/event-card/EventCardVertical.vue";
import BtnMore from "../../../components/button/BtnMore.vue";
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { domain_front } from "../../../services/Constants";

export default {
  components: {
    Banner,
    EventCardHorizontal,
    EventCardVertical,
    BtnMore,
    VueSlickCarousel,
    LoadingCircular,
  },
  data() {
    return {
      formType: 1,
      slide: null,
      loadingBtn: false,
      loading: false,
      category_id: 0,
      categorys: [{ id: 0, name: "All" }],
      events: [],
      eventsRow: {},
      banners: null,
      paginate: {
        current_page: 1,
        last_page: 1,
      },
      settings: {
        centerMode: true,
        centerPadding: "105px",
        focusOnSelect: true,
        touchMove: true,
        infinite: false,
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        dots: false,
      },
    };
  },
  metaInfo() {
    return {
      title: `Events - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: "Events Thai Trade USA",
        },
        {
          property: "og:title",
          content: "Events - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: "Events Thai Trade USA",
        },
        { property: "og:type", content: "Events" },
        {
          property: "og:url",
          content: domain_front + "events",
        },
        {
          property: "og:image",
          content: "../../../assets/images/default.png",
        },
      ],
    };
  },
  async created() {
    this.category_id =
      this.$route.params && this.$route.params.slug
        ? this.$route.params.slug
            .split("-")
            .slice(-1)
            .pop()
        : 0;
    this.loading = true;
    await Promise.all([this.getData(), this.getEvents(1, 6, this.category_id)])
      .then((res) => {
        this.loading = false;
      })
      .catch((error) => {
        console.log("error", error);
        this.loading = false;
      });
    window.scrollTo(0, 0);
  },
  computed: {
    category() {
      return this.$route.params && this.$route.params.slug
        ? this.$route.params.slug
            .split("-")
            .slice(-1)
            .pop()
        : 0;
    },
    centerPadding() {
      if (this.$vuetify.breakpoint.name === "xs") {
        return "0px";
      } else {
        return "99px";
      }
    },
  },
  watch: {
    category(id) {
      this.category_id = id;
      this.loading = true;
      this.getEvents(1, 6, id).then((res) => {
        this.loading = false;
      });
    },
  },
  methods: {
    async getEvents(page = 1, paginate = 2, category_id = 0) {
      const { data } = await this.$service.event.getEvents({
        page: page,
        paginate: paginate,
        category_id: category_id,
      });
      this.events = data.data;

      this.eventsRow[data.current_page] = data;

      this.paginate.current_page = data.current_page;
      this.paginate.last_page = data.last_page;
      return true;
    },
    async getData() {
      const { data } = await this.$service.event.getData();
      this.categorys = [...this.categorys, ...data.categorys];
      this.banners = data.banner_event;
      return true;
    },
    onClickCategory(item) {
      this.category_id = item.id;
      item.id == 0
        ? this.$router.replace("/events")
        : this.$router.replace(
            `/events/category/${item.name.replaceAll(" ", "-")}-${item.id}`
          );
    },
    onClickMore() {
      this.loadingBtn = true;
      this.$service.event
        .getEvents({
          page: this.paginate.current_page + 1,
          paginate: 6,
          category_id: this.category_id,
        })
        .then((res) => {
          this.events = [...this.events, ...res.data.data];

          this.eventsRow[res.data.current_page] = res.data;
          this.paginate.current_page = res.data.current_page;
          this.paginate.last_page = res.data.last_page;
          this.loadingBtn = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingBtn = false;
        });
    },
  },
};
</script>

<style>
.active--category {
  background-color: #0066b3;
}
.category {
  color: #000;
  /* width: 200px; */
}
</style>

<style lang="scss">
.event-carousel {
  .slick-list {
    padding: 0px 0px !important;
  }
}

// @media only screen and (max-width: 425px) {
//   .slick-track.slick-center {
//     // margin-left: 5rem !important;
//   }
// }

// @media only screen and (max-width: 320px) {
//   .slick-track.slick-center {
//     // margin-left: 4rem !important;
//   }
// }
</style>
