import Service from "../../services";

const state = {
  cookie_accepted: false,
  cookie_token: null,
  questionHome: null,
  questionEventDetail: null,
  questionNewsDetail: null,

  detailCookies: "",

  isTodoQuestionHome: null,
  isTodoQuestionEventDetail: null,
  isTodoQuestionNewsDetail: null,
};

const getters = {};

const actions = {
  setCookie({ commit }, payload) {
    return Service.common.setCookie(payload).then((res) => {
      commit("SET_COOKIE", res.data);
    });
  },
  saveAnswer({ commit }, payload) {
    return Service.common.saveAnswer(payload).then(() => {
      commit("SET_ANSWER", payload);
      return true;
    });
  },
  getQuestion({ commit }, payload) {
    return Service.common.getQuestion(payload).then((res) => {
      commit("SET_QUESTION", res.data);
    });
  },
};

const mutations = {
  SET_COOKIE(state, payload) {
    state.cookie_accepted = true;
    state.cookie_token = payload;
  },
  CLEAR_COOKIE(state) {
    state.cookie_accepted = false;
    state.cookie_token = null;
  },
  SET_QUESTION(state, payload) {
    state.detailCookies = payload.detail_cookies;
    state.questionHome = payload.question_home;
    state.questionEventDetail = payload.question_event_detail;
    state.questionNewsDetail = payload.question_news_detail;
  },
  SET_ANSWER(state, payload) {
    if (payload.type === "Home") {
      state.isTodoQuestionHome = payload.question_date;
    }
    if (payload.type === "EventDetail") {
      state.isTodoQuestionEventDetail = payload.question_date;
    }
    if (payload.type === "NewsDetail") {
      state.isTodoQuestionNewsDetail = payload.question_date;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
