<template>
  <div>
    <Slide v-if="highlights" :data="highlights" />

    <!-- News & Event -->
    <v-layout mt-12 mt-sm-12 mt-md-10 wrap>
      <!-- News -->
      <v-flex
        class="order-last order-md-first"
        xs12
        sm12
        md6
        pr-6
        style="background: #F9F9F9;"
      >
        <v-layout wrap class=" fill-height justify-md-space-between">
          <v-flex xs12>
            <div
              class=" pl-4 pl-md-16 pt-md-12 pb-md-12 pt-6 pb-12"
              :class="$vuetify.breakpoint.mdAndUp ? 'news-card' : ''"
              v-if="news.length > 0"
            >
              <div class="topic text-uppercase mb-2 font-cld">News</div>
              <!-- New Desktop size -->
              <div v-if="$vuetify.breakpoint.smAndUp">
                <div class="pr-4" v-for="(item, i) in news" :key="i">
                  <v-row>
                    <v-col
                      cols="auto"
                      class="cursor--pointer"
                      @click="viewDetailNews(item)"
                    >
                      <v-img
                        class="image-hover"
                        width="120"
                        lazy-src="../../../assets/images/default/default_1_1.png"
                        aspect-ratio="1"
                        :src="item.cover_image"
                        :alt="item.title"
                      >
                      </v-img>
                    </v-col>
                    <v-col>
                      <v-layout class="column fill-height" justify-start>
                        <v-flex>
                          <div
                            class="title-news font-weight-bold link-news mb-1"
                          >
                            <span
                              class="link-hover"
                              @click="viewDetailNews(item)"
                            >
                              {{ item.title }}
                            </span>
                          </div>
                          <div class="mb-1 text-sub-title">
                            {{ item.author }}
                          </div>
                          <div class="mb-1 text-sub-title">
                            {{ item.company }}
                          </div>
                        </v-flex>

                        <v-flex class="text-sub-title d-flex align-end">
                          {{ item.publish_date }}
                        </v-flex>
                      </v-layout>
                    </v-col>
                  </v-row>
                  <v-divider v-if="news.length != i + 1" class="my-5" />
                </div>
              </div>

              <!-- New Mobile size -->
              <div v-else>
                <v-layout>
                  <v-flex>
                    <v-carousel
                      :show-arrows="false"
                      hide-delimiter-background
                      hide-delimiters
                      v-model="newsSlide"
                      height="auto"
                      @change="changeNews"
                    >
                      <v-carousel-item
                        v-for="(item, i) in news"
                        :key="'mb-' + i"
                      >
                        <v-row>
                          <v-col
                            cols="4"
                            class="cursor--pointer"
                            @click="viewDetailNews(item)"
                          >
                            <v-img
                              width="120"
                              aspect-ratio="1"
                              :src="item.cover_image"
                              :alt="item.title"
                            >
                            </v-img>
                          </v-col>
                          <v-col cols="8">
                            <v-layout
                              class="column fill-height font-clamp-10-14"
                              justify-space-between
                            >
                              <v-flex class="news-created-at  d-flex">
                                {{ item.publish_date }}
                              </v-flex>
                              <v-flex>
                                <span
                                  class="font-weight-bold news-title ellipsis-2"
                                  @click="viewDetailNews(item)"
                                >
                                  {{ item.title }}
                                </span>
                              </v-flex>
                              <v-flex shrink class="news-des ellipsis-2">
                                {{ item.description }}
                              </v-flex>
                            </v-layout>
                          </v-col>
                        </v-row>
                      </v-carousel-item>
                    </v-carousel>

                    <v-layout justify-center mt-12>
                      <v-flex
                        shrink
                        v-for="i in news.length"
                        :key="i"
                        class="d-flex align-end ml-2 mb-n2"
                      >
                        <v-btn
                          width="8"
                          height="8"
                          fab
                          depressed
                          :outlined="
                            isActiveCarouselNews == i - 1 ? false : true
                          "
                          :color="
                            isActiveCarouselNews == i - 1
                              ? '#0066B3'
                              : '#E2E2E2'
                          "
                          :style="
                            isActiveCarouselNews == i - 1
                              ? { background: '#0066B3' }
                              : { background: '#E2E2E2' }
                          "
                          small
                          @click="changeNews(i - 1)"
                        >
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 class="d-flex align-end justify-center text-center mb-6">
            <button-outline
              @click.native="$router.push('/news')"
              text="View All >"
            />
          </v-flex>
        </v-layout>
      </v-flex>

      <!-- Events -->
      <v-flex class="order-first order-md-last" xs12 sm12 md6 pl-4 pl-md-6>
        <v-layout wrap class=" fill-height justify-md-space-between">
          <v-flex xs12>
            <div
              class="event-card  pr-4 pr-md-16 pt-md-12 pb-md-12 pt-0 pb-0"
              v-if="events.length > 0"
            >
              <div class="topic text-uppercase mb-2 font-cld">Events</div>
              <div v-if="$vuetify.breakpoint.smAndUp">
                <v-row justify="center" class="" v-if="events.length > 0">
                  <v-col
                    cols="12"
                    md="6"
                    sm="6"
                    class="mb-2"
                    v-for="(event, i) in events"
                    :key="i"
                  >
                    <EventCardVertical :item="event" />
                  </v-col>
                </v-row>
              </div>

              <div v-else class="event-carousel">
                <VueSlickCarousel v-bind="settings">
                  <div v-for="(event, i) in events" :key="i">
                    <v-card flat height="auto" width="252.42" class="mr-3">
                      <EventCardVertical :item="event" />
                    </v-card>
                  </div>
                </VueSlickCarousel>
                <!-- <v-sheet class="mx-auto">
                  <v-slide-group class="">
                    <v-slide-item
                      class="mr-3"
                      v-for="(event, i) in events"
                      :key="i"
                    >
                      <v-card flat height="auto" width="252.42">
                        <EventCardVertical :item="event" />
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
                </v-sheet> -->
              </div>
            </div>
          </v-flex>
          <v-flex
            xs12
            class="d-flex align-end justify-center text-center mb-6 mt-8 mt-sm-8 mt-md-0"
          >
            <button-outline
              @click.native="$router.push('/events')"
              text="View All >"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-container mt-12 mt-md-16>
      <!-- MEET TRADE PARTNERS -->
      <div class="meet-trade-partner-card" v-if="categorys.length > 0">
        <div class="topic text-center mb-4">MEET TRADE PARTNERS</div>

        <!-- Desktop size -->
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-row class="mb-12">
            <v-col v-for="(cate, i) in categorys" :key="i" cols="4">
              <v-img
                height="79"
                :src="cate.thumbnail"
                :alt="cate.name"
                @click="onClickCategory(cate.meet_trade_partner_categorys)"
              >
                <div class="category-card">
                  {{ cate.name }}
                </div>
              </v-img>
            </v-col>
          </v-row>

          <v-layout justify-center v-if="meet_trade_partners.length > 0">
            <v-flex
              class=" px-3 py-12"
              v-for="(item, i) in meet_trade_partners"
              :key="i"
              xs3
              :class="
                item && item.meet_trade_partners_home_highlight
                  ? 'meet-trade-card'
                  : ''
              "
            >
              <div v-if="item && item.meet_trade_partners_home_highlight">
                <div class="mb-5">
                  <span
                    class="font-cl font-14 font-weight-bold link-hover"
                    @click="
                      getPartnerDetail(
                        item.meet_trade_partners_home_highlight.id,
                        item.meet_trade_partners_home_highlight.status_form,
                        item.meet_trade_partners_home_highlight.website,
                        item.meet_trade_partners_home_highlight.title
                      )
                    "
                  >
                    {{ item.meet_trade_partners_home_highlight.title }}
                  </span>
                </div>
                <div class="font-cl font-14">
                  {{
                    item.meet_trade_partners_home_highlight
                      ? item.meet_trade_partners_home_highlight.description
                      : ""
                  }}
                </div>
              </div>
            </v-flex>
          </v-layout>
        </div>

        <!-- Mobile size -->
        <div v-else>
          <div class="meet-trade-mobile">
            <VueSlickCarousel v-bind="settingsCate">
              <div v-for="(cate, i) in categorys" :key="i">
                <v-card
                  tile
                  flat
                  width="224"
                  height="87"
                  @click="onClickCategory(cate.meet_trade_partner_categorys)"
                >
                  <v-img
                    width="100%"
                    height="79"
                    :src="cate.thumbnail"
                    :alt="cate.name"
                  >
                    <div class="category-card">
                      {{ cate.name }}
                    </div>
                  </v-img>
                </v-card>
              </div>
            </VueSlickCarousel>
          </div>

          <!-- <v-layout justify-center>
            <v-flex>
              <v-sheet class="mx-auto">
                <v-slide-group center-active v-model="categorySlideModel">
                  <v-slide-item
                    class="mr-3"
                    v-for="(cate, i) in categorys"
                    :key="i"
                    v-slot="{ toggle }"
                  >
                    <v-card
                      tile
                      flat
                      width="224"
                      @click="toggle"
                      @click.native="partnerSlideModel = 0"
                    >
                      <v-img
                        width="100%"
                        height="79"
                        :src="cate.thumbnail"
                        @click="
                          onClickCategory(cate.meet_trade_partner_categorys)
                        "
                      >
                        <div class="category-card">
                          {{ cate.name }}
                        </div>
                        
                      </v-img>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </v-sheet>
            </v-flex>
          </v-layout> -->

          <div class="mt-8 carouse-meet-trade-card-mobile">
            <VueSlickCarousel
              v-bind="settingsMeetTrade"
              v-if="meet_trade_partners.length > 0"
            >
              <div v-for="(item, i) in meet_trade_partners" :key="i">
                <v-card
                  tile
                  width="224"
                  flat
                  class="mx-2 meet-trade-card-mobile"
                  v-if="item.meet_trade_partners_home_highlight"
                >
                  <v-layout justify-center class="px-3 font-clamp-10-14">
                    <v-flex class=" py-12">
                      <div class="mb-5">
                        <span
                          @click="
                            getPartnerDetail(
                              item.meet_trade_partners_home_highlight.id,
                              item.meet_trade_partners_home_highlight
                                .status_form,
                              item.meet_trade_partners_home_highlight.website,
                              item.meet_trade_partners_home_highlight.title
                            )
                          "
                          class="text-title"
                        >
                          {{ item.meet_trade_partners_home_highlight.title }}
                        </span>
                      </div>
                      <div class="text-sub-title">
                        {{
                          item.meet_trade_partners_home_highlight
                            ? item.meet_trade_partners_home_highlight
                                .description
                            : ""
                        }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </div>
            </VueSlickCarousel>

            <!-- <v-sheet v-if="meet_trade_partners.length > 0">
              <v-slide-group v-model="partnerSlideModel" center-active>
                <v-slide-item
                  v-for="(item, i) in meet_trade_partners"
                  :key="i"
                  v-slot="{ toggle }"
                  class="meet-trade-card-mobile"
                >
                  <v-card
                    tile
                    width="224"
                    flat
                    class="mx-2"
                    @click="toggle"
                    v-if="item.meet_trade_partners_home_highlight"
                  >
                    <v-layout justify-center class="px-3 font-clamp-10-14">
                      <v-flex class=" py-12">
                        <div class="mb-5">
                          <span
                            @click="
                              $router.push(
                                `partners/${item.meet_trade_partners_home_highlight.id}`
                              )
                            "
                            class="text-title"
                          >
                            {{ item.meet_trade_partners_home_highlight.title }}
                          </span>
                        </div>
                        <div class="text-sub-title">
                          {{
                            item.meet_trade_partners_home_highlight
                              ? item.meet_trade_partners_home_highlight
                                  .description
                              : ""
                          }}
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet> -->
          </div>
        </div>
      </div>
    </v-container>

    <div v-if="new_project" class="mt-0 mb-6 mt-md-0 mb-md-16">
      <NewProject color="#fff" :data="new_project" />
    </div>

    <LoadingCircular :loading="loading" />
  </div>
</template>

<script>
import ButtonOutline from "../../../components/button/ButtonOutline.vue";
import EventCardVertical from "../../../components/event-card/EventCardVertical.vue";
import Loading from "../../../components/loading/LoadingCircular.vue";
import NewProject from "../../../components/new-project/NewProject.vue";
import Slide from "../../../components/slide/Slide.vue";
import homeService from "../../../services/";
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { domain_front } from "../../../services/Constants";

export default {
  components: {
    EventCardVertical,
    Slide,
    NewProject,
    ButtonOutline,
    LoadingCircular,
    VueSlickCarousel,
  },
  data() {
    return {
      loading: false,
      categorySlideModel: null,
      partnerSlideModel: null,
      highlights: null,
      meet_trade_partners: [],
      categorys: [],
      events: [],
      news: [],
      new_project: null,
      fullPage: false,
      isActiveCarouselNews: 0,
      newsSlide: 0,

      settings: {
        centerMode: true,
        centerPadding: "35px",
        focusOnSelect: true,
        touchMove: true,
        infinite: false,
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        dots: false,
      },
      settingsCate: {
        centerMode: true,
        adaptiveHeight: true,
        centerPadding: "54px",
        focusOnSelect: true,
        touchMove: true,
        infinite: false,
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        dots: false,
      },
      settingsMeetTrade: {
        centerMode: true,
        adaptiveHeight: true,
        centerPadding: "54px",
        focusOnSelect: true,
        touchMove: true,
        infinite: false,
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        dots: false,
      },
    };
  },
  metaInfo() {
    return {
      title: `Home - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: this.about_us ? this.about_us.description : "",
        },
        {
          property: "og:title",
          content: "Home - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: this.about_us ? this.about_us.description : "",
        },
        { property: "og:type", content: "Home" },
        {
          property: "og:url",
          content: domain_front,
        },
        {
          property: "og:image",
          content: "../../../assets/images/default.png",
        },
      ],
    };
  },
  created() {
    this.getData();
    window.scrollTo(0, 0);
  },
  methods: {
    getData() {
      this.loading = true;
      homeService.home.getHome().then((res) => {
        const { highlights, categorys, events, news, new_project } = res.data;
        this.highlights = highlights;
        this.categorys = categorys;
        this.events = events;
        this.news = news;
        this.new_project = new_project;
        this.meet_trade_partners = categorys[2].meet_trade_partner_categorys;
        this.loading = false;
      });
    },
    onClickCategory(val) {
      this.meet_trade_partners = val;
    },
    changeNews(val) {
      this.newsSlide = val;
      this.isActiveCarouselNews = val;
    },
  },
};
</script>

<style>
.news-card {
  background: #f9f9f9;
}
.news-created-at {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 26px;
  color: #6a6a6a;
}
.news-title {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: bold;
  /* font-size: 14px; */
  line-height: 20px;
  color: #000000;
}
.news-des {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: #000000;
}
/* .meet-trade-card-mobile:not(:last-child) {
  border-right: 1px solid #e2e2e2 !important;
} */

.v-slide-group__content {
  justify-content: center !important;
}
</style>

<style lang="scss">
.event-carousel {
  .slick-list {
    padding: 0px 0px !important;
  }
}
.meet-trade-mobile {
  .slick-list {
    height: 79px !important;
    padding: 0px 0px !important;
  }
}
.carouse-meet-trade-card-mobile {
  .slick-list {
    padding: 0px 0px !important;
  }
  .slick-slide:not(:last-child) {
    border-right: 1px solid #e2e2e2 !important;
  }
  .meet-trade-card-mobile:not(:last-child) {
    border-right: 1px solid #e2e2e2 !important;
  }
}
</style>
