var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[(_vm.data.type == 1)?_c('div',{staticClass:"container-slide"},[_c('v-layout',{staticClass:"d-flex justify-md-center align-center",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md5":""}},[_c('div',{staticClass:"v-html",domProps:{"innerHTML":_vm._s(_vm.data.youtube_url)}})]),_c('v-flex',{staticClass:"mt-n2 mt-sm-0",attrs:{"xs12":"","sm12":"","md7":""}},[_c('v-img',{attrs:{"cover":"","width":"100%","height":"auto","src":_vm.$vuetify.breakpoint.mdAndUp
              ? _vm.data.banner_image_desktop
              : _vm.data.banner_image_mobile}})],1)],1)],1):_vm._e(),(_vm.data.type == 2)?_c('div',[_c('v-layout',{class:_vm.$vuetify.breakpoint.mdAndUp
          ? 'btn-slide-control-desktop'
          : 'btn-slide-control-mobile'},_vm._l((_vm.$vuetify.breakpoint.smAndUp
          ? _vm.checkLengthDesktop
          : _vm.checkLengthMobile),function(i){return _c('v-flex',{key:i,staticClass:"d-flex align-end ml-2 mb-n2",attrs:{"shrink":""}},[_c('v-btn',{attrs:{"width":"25","height":"25","fab":"","depressed":"","outlined":_vm.isActiveCarousel == i - 1 ? false : true,"color":_vm.isActiveCarousel == i - 1 ? '#000' : '#000',"small":"","dark":""},on:{"click":function($event){return _vm.handleChange(i - 1)}}},[_vm._v(" "+_vm._s(i)+" ")])],1)}),1),_c('v-carousel',{staticClass:"banner-slide pa-0 ma-0",attrs:{"continuous":true,"show-arrows":false,"hide-delimiter-background":"","height":"auto","cycle":"","hide-delimiters":""},on:{"change":_vm.handleChange},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},[(_vm.data.slide_image_desktop_1 && _vm.data.slide_image_mobile_1)?_c('v-carousel-item',{attrs:{"eager":""}},[_c('a',{attrs:{"href":_vm.data.url_banner1,"target":"_blank"}},[_c('v-img',{attrs:{"eager":"","width":"100%","height":"auto","cover":"","src":_vm.$vuetify.breakpoint.mdAndUp
                ? _vm.data.slide_image_desktop_1
                : _vm.data.slide_image_mobile_1}})],1)]):_vm._e(),(_vm.data.slide_image_desktop_2 && _vm.data.slide_image_mobile_2)?_c('v-carousel-item',{attrs:{"eager":""}},[_c('a',{attrs:{"href":_vm.data.url_banner2,"target":"_blank"}},[_c('v-img',{attrs:{"eager":"","width":"100%","height":"auto","cover":"","src":_vm.$vuetify.breakpoint.mdAndUp
                ? _vm.data.slide_image_desktop_2
                : _vm.data.slide_image_mobile_2}})],1)]):_vm._e(),(_vm.data.slide_image_desktop_3 && _vm.data.slide_image_mobile_3)?_c('v-carousel-item',{attrs:{"eager":""}},[_c('a',{attrs:{"href":_vm.data.url_banner3,"target":"_blank"}},[_c('v-img',{attrs:{"eager":"","width":"100%","height":"auto","cover":"","src":_vm.$vuetify.breakpoint.mdAndUp
                ? _vm.data.slide_image_desktop_3
                : _vm.data.slide_image_mobile_3}})],1)]):_vm._e(),(_vm.data.slide_image_desktop_4 && _vm.data.slide_image_mobile_4)?_c('v-carousel-item',{attrs:{"eager":""}},[_c('a',{attrs:{"href":_vm.data.url_banner4,"target":"_blank"}},[_c('v-img',{attrs:{"eager":"","width":"100%","height":"auto","cover":"","src":_vm.$vuetify.breakpoint.mdAndUp
                ? _vm.data.slide_image_desktop_4
                : _vm.data.slide_image_mobile_4}})],1)]):_vm._e(),(_vm.data.slide_image_desktop_5 && _vm.data.slide_image_mobile_5)?_c('v-carousel-item',{attrs:{"eager":""}},[_c('a',{attrs:{"href":_vm.data.url_banner5,"target":"_blank"}},[_c('v-img',{attrs:{"eager":"","width":"100%","height":"auto","cover":"","src":_vm.$vuetify.breakpoint.mdAndUp
                ? _vm.data.slide_image_desktop_5
                : _vm.data.slide_image_mobile_5}})],1)]):_vm._e()],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }