<template>
  <v-card tile outlined>
    <div class="map-line" :style="{ background: checkLine(item.id) }"></div>
    <div class="map-title pa-2">{{ item.title }}</div>
    <div class="map-detail py-1 px-2 mt-1 font-12">
      <!-- Address -->
      <div class="map-address mb-2">
        <v-layout>
          <v-flex shrink>
            <v-icon class="mr-3" style="font-size: 14px" color="#A40802">
              mdi-map-marker
            </v-icon>
          </v-flex>
          <v-flex>
            <span>{{ item.address }}</span>
          </v-flex>
        </v-layout>
      </div>

      <!-- Tel & Fax -->
      <v-layout class="tel-fax mb-2" wrap>
        <v-flex xs6>
          <v-icon class="mr-2" style="font-size: 14px" color="#000">
            mdi-phone
          </v-icon>
          <span> Tel. {{ item.tel ? item.tel : "-" }} </span>
        </v-flex>
        <v-flex xs6>
          <span> Fax. {{ item.fax ? item.fax : "-" }} </span>
        </v-flex>
      </v-layout>

      <!-- Email & Website -->
      <v-layout class="tel-fax mb-2">
        <v-flex xs6>
          <v-icon class="mr-2" style="font-size: 14px" color="#000">
            mdi-email
          </v-icon>
          <span style="color: #0066B3">
            <a :href="'mailto:' + item.email" class="mr-3" target="_blank">
              {{ item.email ? item.email : "-" }}
            </a>
          </span>
        </v-flex>
        <v-flex xs6>
          <v-icon class="mr-2" style="font-size: 14px" color="#000">
            mdi-earth
          </v-icon>
          <span>
            <a :href="website" class="mr-3" target="_blank">
              {{ item.website ? item.website : "-" }}
            </a>
          </span>
        </v-flex>
      </v-layout>
    </div>

    <div class="px-2 pb-3">
      <v-row no-gutters align="center">
        <v-col
          cols="auto"
          class="mr-2"
          v-if="item.facebook && icon && icon.facebook_color"
        >
          <a :href="item.facebook" target="_blank">
            <v-img
              class="social-link"
              contain
              width="16.77"
              aspect-ratio="1"
              :src="icon.facebook_color"
              lazy-src="../../assets/images/default/default_1_1.png"
            >
            </v-img>
          </a>
        </v-col>

        <v-col
          cols="auto"
          class="mr-2"
          v-if="item.instagram && icon && icon.instagram_color"
        >
          <a :href="item.instagram" target="_blank">
            <v-img
              class="social-link"
              contain
              width="16.77"
              aspect-ratio="1"
              :src="icon.instagram_color"
              lazy-src="../../assets/images/default/default_1_1.png"
            >
            </v-img>
          </a>
        </v-col>

        <v-col
          cols="auto"
          class="mr-2"
          v-if="item.twitter && icon && icon.twitter_color"
        >
          <a :href="item.twitter" target="_blank">
            <v-img
              class="social-link"
              contain
              width="16.77"
              height="16.77"
              aspect-ratio="1"
              :src="icon.twitter_color"
              lazy-src="../../assets/images/default/default_1_1.png"
            >
            </v-img>
          </a>
        </v-col>

        <v-col
          cols="auto"
          class="mr-2"
          v-if="item.youtube && icon && icon.youtube_color"
        >
          <a :href="item.youtube" target="_blank">
            <v-img
              class="social-link"
              contain
              width="16.77"
              height="16.77"
              aspect-ratio="1"
              :src="icon.youtube_color"
              lazy-src="../../assets/images/default/default_1_1.png"
            >
            </v-img>
          </a>
        </v-col>

        <v-col
          cols="auto"
          class="mr-2"
          v-if="item.linkin && icon && icon.linkin_color"
        >
          <a :href="item.linkin" target="_blank">
            <v-img
              class="social-link"
              contain
              width="16.77"
              height="16.77"
              aspect-ratio="1"
              :src="icon.linkin_color"
              lazy-src="../../assets/images/default/default_1_1.png"
            >
            </v-img>
          </a>
        </v-col>

        <v-col
          cols="auto"
          class="mr-2"
          v-if="item.tiktok && icon && icon.tiktok_color"
        >
          <a :href="item.tiktok" target="_blank">
            <v-img
              class="social-link"
              contain
              width="16.77"
              height="16.77"
              aspect-ratio="1"
              :src="icon.tiktok_color"
              lazy-src="../../assets/images/default/default_1_1.png"
            >
            </v-img>
          </a>
        </v-col>

        <v-col
          cols="auto"
          class="mr-2"
          v-if="item.line && icon && icon.line_color"
        >
          <a :href="item.line" target="_blank">
            <v-img
              class="social-link"
              contain
              width="16.77"
              height="16.77"
              aspect-ratio="1"
              :src="icon.line_color"
              lazy-src="../../assets/images/default/default_1_1.png"
            >
            </v-img>
          </a>
        </v-col>

        <v-col
          cols="auto"
          class="mr-2"
          v-if="item.wechat && icon && icon.wechat_color"
        >
          <a :href="item.wechat" target="_blank">
            <v-img
              class="social-link"
              contain
              width="16.77"
              height="16.77"
              aspect-ratio="1"
              :src="icon.wechat_color"
              lazy-src="../../assets/images/default/default_1_1.png"
            >
            </v-img>
          </a>
        </v-col>

        <v-col
          cols="auto"
          class="mr-2"
          v-if="item.pinterest && icon && icon.pinterest_color"
        >
          <a :href="item.pinterest" target="_blank">
            <v-img
              class="social-link"
              contain
              width="16.77"
              height="16.77"
              aspect-ratio="1"
              :src="icon.pinterest_color"
              lazy-src="../../assets/images/default/default_1_1.png"
            >
            </v-img>
          </a>
        </v-col>

        <v-col
          cols="auto"
          class="mr-2"
          v-if="item.whatapp && icon && icon.whatapp_color"
        >
          <a :href="item.whatapp" target="_blank">
            <v-img
              class="social-link"
              contain
              width="16.77"
              height="16.77"
              aspect-ratio="1"
              :src="icon.whatapp_color"
              lazy-src="../../assets/images/default/default_1_1.png"
            >
            </v-img>
          </a>
        </v-col>
      </v-row>
    </div>

    <div class="px-3">
      <v-divider class="mb-2" />
      <div class="map-detail font-weight-bold mb-2">
        <span class="font-14">Service Areas</span>
      </div>
      <v-layout class="map-detail pb-4">
        <v-flex shrink>
          <v-icon class="mr-2" style="font-size: 14px" color="#000">
            mdi-map-marker
          </v-icon>
        </v-flex>
        <v-flex class="font-12">
          {{ item.service_areas }}
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    item: null,
    icon: null,
  },
  computed: {
    website() {
      let website = this.item.website;
      if (
        this.item.website.search("https://") == -1 &&
        this.item.website.search("http://") == -1
      ) {
        website = "https://" + this.item.website;
      }
      return website;
    },
  },
  methods: {
    checkLine(i) {
      switch (i) {
        case 1:
          return "#B6D398";
        case 2:
          return "#8AD839";
        case 3:
          return "#F4B88A";
        case 4:
          return "#F9FCBA";
        case 5:
          return "#C4C4C4";
        case 6:
          return "#FEE0E3";
        case 7:
          return "#C3AACA";
      }
    },
  },
};
</script>

<style></style>
