import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import category from "./modules/Category.store";
import common from "./modules/Common.store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    category,
    common,
  },
  plugins: [createPersistedState()],
});
