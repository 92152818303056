// import authService from "../authService";
import Service from "../Service";

const event = {
  getBanner() {
    return Service.get(`/api/contact-us`);
  },
  save(data) {
    return Service.post(`/api/contact-us`, data);
  },
};

export default event;
