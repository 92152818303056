<template>
  <div style="position: relative;">
    <!--  button contact -->
    <div
      class="btn-contact"
      v-if="
        $route.name !== 'EventDetail' &&
          $route.name !== 'FAQ' &&
          $route.name !== 'ContactUs'
      "
    >
      <v-btn
        height="28"
        class=" text-capitalize"
        color="#A40802"
        outlined
        rounded
        @click="$router.push('/contact-us')"
        >Contact Us
      </v-btn>
    </div>

    <v-card color="#555E7D" flat tile>
      <v-layout>
        <v-flex sm1 xs3 style="background-color: #A40802; height: 5px"></v-flex>
        <v-flex sm1 xs3 style="background-color: #0066B3; height: 5px"></v-flex>
        <v-flex
          sm10
          xs6
          style="background-color: #E2E2E2; height: 5px"
        ></v-flex>
      </v-layout>

      <!-- Menu bar footer -->
      <v-layout
        wrap
        justify-space-between
        class="px-8 pt-4 pb-4 d-flex align-center"
      >
        <v-flex xs12 md6 lg5 mb-4 mb-md-0 class="d-flex align-center">
          <!-- desktop size -->
          <v-layout wrap v-if="$vuetify.breakpoint.mdAndUp" justify-start>
            <v-flex
              shrink
              v-for="(item, i) in menu"
              :key="i"
              class="d-flex align-center"
            >
              <span
                class="font-12  footer-menu"
                @click="$router.push(item.link)"
              >
                {{ item.name }}
              </span>
              <div
                class="mx-2"
                style="height: 12px; border: 0.5px solid #fff; opacity: 0.75;"
              ></div>
            </v-flex>
          </v-layout>

          <!-- mobile size -->
          <v-layout wrap v-else>
            <v-flex xs12 v-for="(item, i) in menu" :key="i">
              <span
                class="font-12  footer-menu"
                @click="$router.push(item.link)"
              >
                {{ item.name }}
              </span>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs12 md3 lg4 mb-4 mb-md-0 class="d-flex align-center">
          <v-layout
            v-if="social"
            pl-0
            pl-md-4
            :justify-start="$vuetify.breakpoint.mdAndUp"
          >
            <!-- Facebook -->
            <v-flex
              v-if="social.is_show_facebook == 1"
              class="mx-md-2 mr-4 "
              shrink
            >
              <a :href="social.facebook" target="_blank" class="link-social">
                <v-img
                  aspect-ratio="1"
                  width="30"
                  :src="social.icon.facebook_color"
                  alt=""
                />
              </a>
            </v-flex>

            <!-- Instagram -->
            <v-flex
              v-if="social.is_show_instagram == 1"
              class="mx-md-2 mr-4 "
              shrink
            >
              <a :href="social.instagram" target="_blank" class="link-social">
                <v-img
                  aspect-ratio="1"
                  width="30"
                  :src="social.icon.instagram_color"
                  alt=""
                />
              </a>
            </v-flex>

            <!-- Twitter -->
            <v-flex
              v-if="social.is_show_twitter == 1"
              class="mx-md-2 mr-4 "
              shrink
            >
              <a :href="social.twitter" target="_blank" class="link-social">
                <v-img
                  aspect-ratio="1"
                  width="30"
                  :src="social.icon.twitter_color"
                  alt=""
                />
              </a>
            </v-flex>

            <!-- Youtube -->
            <v-flex
              v-if="social.is_show_youtube == 1"
              class="mx-md-2 mr-4 "
              shrink
            >
              <a :href="social.youtube" target="_blank" class="link-social">
                <v-img
                  aspect-ratio="1"
                  width="30"
                  :src="social.icon.youtube_color"
                  alt=""
                />
              </a>
            </v-flex>

            <!-- Linkedin -->
            <v-flex
              v-if="social.is_show_linkedin == 1"
              class="mx-md-2 mr-4 "
              shrink
            >
              <a :href="social.linkin" target="_blank" class="link-social">
                <v-img
                  aspect-ratio="1"
                  width="30"
                  :src="social.icon.linkin_color"
                  alt=""
                />
              </a>
            </v-flex>

            <!-- Tiktok -->
            <v-flex
              v-if="social.is_show_tiktok == 1"
              class="mx-md-2 mr-4 "
              shrink
            >
              <a :href="social.tiktok" target="_blank" class="link-social">
                <v-img
                  aspect-ratio="1"
                  width="30"
                  :src="social.icon.tiktok_color"
                  alt=""
                />
              </a>
            </v-flex>

            <!-- line -->
            <v-flex
              v-if="social.is_show_line == 1"
              class="mx-md-2 mr-4 "
              shrink
            >
              <a :href="social.line" target="_blank" class="link-social">
                <v-img
                  aspect-ratio="1"
                  width="30"
                  :src="social.icon.line_color"
                  alt=""
                />
              </a>
            </v-flex>

            <!-- whatsapp -->
            <v-flex
              v-if="social.is_show_whatsapp == 1"
              class="mx-md-2 mr-4 "
              shrink
            >
              <a :href="social.whatsapp" target="_blank" class="link-social">
                <v-img
                  aspect-ratio="1"
                  width="30"
                  :src="social.icon.whatapp_color"
                  alt=""
                />
              </a>
            </v-flex>

            <!-- pinterest -->
            <v-flex
              v-if="social.is_show_pinterest == 1"
              class="mx-md-2 mr-4 "
              shrink
            >
              <a :href="social.pinterest" target="_blank" class="link-social">
                <v-img
                  aspect-ratio="1"
                  width="30"
                  :src="social.icon.pinterest_color"
                  alt=""
                />
              </a>
            </v-flex>

            <!-- wechat -->
            <v-flex
              v-if="social.is_show_wechat == 1"
              class="mx-md-2 mr-4 "
              shrink
            >
              <a :href="social.wechat" target="_blank" class="link-social">
                <v-img
                  aspect-ratio="1"
                  width="30"
                  :src="social.icon.wechat_color"
                  alt=""
                />
              </a>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex
          xs12
          md3
          lg3
          mb-0
          mb-md-0
          class="font-weight-bold font-12 d-flex align-cente justify-md-end justify-start"
          style="color: #fff"
        >
          <a style="color:#fff" href="https://www.ditp.go.th/" target="_blank">
            DITP
          </a>
          <span class="mx-2">|</span>
          <a
            style="color:#fff"
            href="https://www.thaitradefair.com/"
            target="_blank"
          >
            ThaiTradeFair.com
          </a>
        </v-flex>
      </v-layout>

      <v-divider />

      <div class="py-3 white--text pl-8 pl-md-8 font-12">
        copyright © 2021
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu: [
        {
          name: "Home",
          link: "/home",
        },
        {
          name: "Meet Trade Partners",
          link: "/partners",
        },
        {
          name: "Events",
          link: "/events",
        },
        {
          name: "News",
          link: "/news",
        },
        {
          name: "FAQ",
          link: "/faq",
        },
        {
          name: "About Us",
          link: "/about-us",
        },
        {
          name: "Contact Us",
          link: "/contact-us",
        },
      ],
      social: null,
      expiredDate: null,
    };
  },
  created() {
    this.getSocialFooter();
    this.expiredDate = new Date();
    this.expiredDate.setMonth(this.expiredDate.getMonth() + 1);
  },

  methods: {
    getSocialFooter() {
      this.$service.footer.getData().then((res) => {
        this.social = res.data;
      });
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #304055;
}
.footer-menu:hover {
  text-decoration: underline;
  cursor: pointer;
}
.link-social {
  color: #fff;
}
.link-social:hover {
  color: #e2e2e2;
}

@media only screen and (min-width: 769px) {
  .btn-contact {
    position: absolute;
    right: 2.19%;
    bottom: 130px;
    font-family: Chulabhorn Likit Text;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 85.39%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #a40802;
  }
}

@media only screen and (max-width: 768px) {
  .btn-contact {
    position: fixed;
    right: 2.19%;
    bottom: 10px;
    z-index: 1;
    font-family: Chulabhorn Likit Text;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 85.39%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #a40802;
  }
  .btn-contact v-btn {
    background-color: #fff;
  }
}
.footer-menu {
  font-family: "ChulabhornLikitText";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
}
</style>
