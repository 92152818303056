import event from "./api/Event.Service";
import home from "./api/Home.service";
import about_us from "./api/AboutUs.service";
import category from "./api/Category.service";
import news from "./api/News.service";
import faq from "./api/Faq.service";
import contact_us from "./api/ContactUs.service";
import thaipage from "./api/Thaipage.service";
import spanish from "./api/Spanish.service";
import partner from "./api/Partner.service";
import footer from "./api/Footer.service";
import common from "./api/Common.service";

export default {
  event,
  home,
  about_us,
  category,
  news,
  faq,
  contact_us,
  thaipage,
  spanish,
  partner,
  footer,
  common,
};
