<template>
  <v-container class="mb-16">
    <div class="font-18 my-4">
      Keyword :
      <span class="font-weight-bold" style="color: #0066B3;">
        “{{ $route.query.keyword }}”
      </span>
    </div>

    <!-- Button Search -->
    <v-row v-if="$vuetify.breakpoint.mdAndUp">
      <v-col cols="3" class="px-1">
        <v-btn
          @click="onClickTpye('events')"
          depressed
          :outlined="$route.params.slug_type === 'events' ? true : false"
          dark
          tile
          block
          color="#0066B3"
        >
          <span class="font-weight-bold text-capitalize">Events</span>
        </v-btn>
      </v-col>
      <v-col cols="3" class="px-1">
        <v-btn
          @click="onClickTpye('news')"
          depressed
          :outlined="$route.params.slug_type === 'news' ? true : false"
          dark
          tile
          block
          color="#0066B3"
        >
          <span class="font-weight-bold text-capitalize">News</span>
        </v-btn>
      </v-col>
      <v-col cols="3" class="px-1">
        <v-btn
          @click="onClickTpye('partners')"
          depressed
          :outlined="$route.params.slug_type === 'partners' ? true : false"
          dark
          tile
          block
          color="#0066B3"
        >
          <span class="font-weight-bold text-capitalize">
            Meet Trade Partners
          </span>
        </v-btn>
      </v-col>
      <v-col cols="3" class="px-1">
        <v-btn
          @click="onClickTpye('thaipage')"
          depressed
          :outlined="$route.params.slug_type === 'thaipage' ? true : false"
          dark
          tile
          block
          color="#0066B3"
        >
          <span class="font-weight-bold text-capitalize">Thaipage</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-sheet class="search__btn" v-else>
      <v-slide-group show-arrows>
        <v-slide-item v-slot="{ toggle }">
          <v-card class="ma-1" @click="toggle">
            <v-btn
              @click="onClickTpye('events')"
              depressed
              :outlined="$route.params.slug_type === 'events' ? true : false"
              dark
              tile
              block
              color="#0066B3"
            >
              <span class="font-weight-bold text-capitalize">Events</span>
            </v-btn>
          </v-card>
        </v-slide-item>

        <v-slide-item v-slot="{ toggle }">
          <v-card class="ma-1" @click="toggle">
            <v-btn
              @click="onClickTpye('news')"
              depressed
              :outlined="$route.params.slug_type === 'news' ? true : false"
              dark
              tile
              block
              color="#0066B3"
            >
              <span class="font-weight-bold text-capitalize">News</span>
            </v-btn>
          </v-card>
        </v-slide-item>

        <v-slide-item v-slot="{ toggle }">
          <v-card class="ma-1" @click="toggle">
            <v-btn
              @click="onClickTpye('partners')"
              depressed
              :outlined="$route.params.slug_type === 'partners' ? true : false"
              dark
              tile
              block
              color="#0066B3"
            >
              <span class="font-weight-bold text-capitalize">
                Meet Trade Partners
              </span>
            </v-btn>
          </v-card>
        </v-slide-item>

        <v-slide-item v-slot="{ toggle }">
          <v-card class="ma-1" @click="toggle">
            <v-btn
              @click="onClickTpye('thaipage')"
              depressed
              :outlined="$route.params.slug_type === 'thaipage' ? true : false"
              dark
              tile
              block
              color="#0066B3"
            >
              <span class="font-weight-bold text-capitalize">Thaipage</span>
            </v-btn>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <div class="my-6 font-14">Total result: {{ paginate.total }}</div>

    <!-- Event List -->
    <div v-if="events.length > 0 && $route.params.slug_type === 'events'">
      <v-row>
        <v-col
          class="pa-1"
          cols="12"
          v-for="(item, i) in events"
          :key="'events' + i"
        >
          <EventCardSearch :item="item" />
        </v-col>
      </v-row>

      <div>
        <v-layout
          justify-center
          mt-6
          mb-12
          v-if="paginate.last_page != paginate.current_page"
        >
          <v-flex shrink>
            <BtnMore
              :loading="loadingBtn"
              @on-click="getArticle('events', paginate.current_page + 1, true)"
            />
          </v-flex>
        </v-layout>
      </div>
    </div>

    <!-- NEWS List -->
    <div v-if="news.length > 0 && $route.params.slug_type === 'news'">
      <v-row>
        <v-col
          class="pa-1"
          cols="12"
          v-for="(item, i) in news"
          :key="'news' + i"
        >
          <NewsCard @onClick="viewDetailNews(item)" v-if="item" :item="item" />
        </v-col>
      </v-row>

      <div>
        <v-layout
          justify-center
          mt-6
          mb-12
          v-if="paginate.last_page != paginate.current_page"
        >
          <v-flex shrink>
            <BtnMore
              :loading="loadingBtn"
              @on-click="getArticle('news', paginate.current_page + 1, true)"
            />
          </v-flex>
        </v-layout>
      </div>
    </div>

    <!-- Partner List -->
    <div v-if="partners.length > 0 && $route.params.slug_type === 'partners'">
      <v-card
        v-for="(partner, i) in partners"
        :key="'partner' + i"
        color="#F9F9F9"
        class="mb-sm-4 mb-2 pa-4"
        flat
        tile
      >
        <PartnerCard :item="partner" />
      </v-card>

      <div>
        <v-layout
          justify-center
          mt-6
          mb-12
          v-if="paginate.last_page != paginate.current_page"
        >
          <v-flex shrink>
            <BtnMore
              :loading="loadingBtn"
              @on-click="
                getArticle('partners', paginate.current_page + 1, true)
              "
            />
          </v-flex>
        </v-layout>
      </div>
    </div>

    <!-- Thaipage List -->
    <div v-if="thaipages.length > 0 && $route.params.slug_type === 'thaipage'">
      <v-row>
        <v-col
          class="pa-1"
          cols="12"
          v-for="(item, i) in thaipages"
          :key="'thaipage' + i"
        >
          <ThaipageCard
            @onClick="viewDetailThaipage(item)"
            v-if="item"
            :item="item"
          />
        </v-col>
      </v-row>

      <div>
        <v-layout
          justify-center
          mt-6
          mb-12
          v-if="paginate.last_page != paginate.current_page"
        >
          <v-flex shrink>
            <BtnMore
              :loading="loadingBtn"
              @on-click="
                getArticle('thaipage', paginate.current_page + 1, true)
              "
            />
          </v-flex>
        </v-layout>
      </div>
    </div>

    <loading-circular :loading="loading" />
  </v-container>
</template>

<script>
import BtnMore from "../../components/button/BtnMore.vue";
import EventCardSearch from "../../components/event-card/EventCardSearch.vue";
import LoadingCircular from "../../components/loading/LoadingCircular.vue";
import NewsCard from "../../components/search/NewsCard.vue";
import PartnerCard from "../../components/search/partnerCard.vue";
import ThaipageCard from "../../components/search/ThaipageCard.vue";
import { domain_front } from "../../services/Constants";

export default {
  components: {
    LoadingCircular,
    EventCardSearch,
    BtnMore,
    NewsCard,
    PartnerCard,
    ThaipageCard,
  },
  data() {
    return {
      model: null,
      events: [],
      partners: [],
      news: [],
      thaipages: [],
      loading: false,
      loadingBtn: false,
      paginate: {
        current_page: 1,
        last_page: 1,
        total: 0,
      },
    };
  },
  metaInfo() {
    return {
      title: `Search - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: "Search on Thai Trade USA",
        },
        {
          property: "og:title",
          content: "Search - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: "Search on ThaiTradeUSA.com",
        },
        { property: "og:type", content: "Search" },
        {
          property: "og:url",
          content:
            domain_front +
            `/search/${this.$route.params.slug_type}?keyword=${this.$route.query.keyword}`,
        },
        {
          property: "og:image",
          content: "../../assets/images/default.png",
        },
      ],
    };
  },
  created() {
    console.log(this.$route);
    this.getArticle("events", 1);
    if (this.$route.query.keyword !== "events") {
      this.$router
        .replace({
          path: `/search/events?keyword=${this.$route.query.keyword}`,
          params: { type: "events" },
        })
        .catch(() => {});
      window.scrollTo(0, 0);
    }
  },
  watch: {
    $route(to, from) {
      this.events = [];
      this.getArticle(this.$route.params.slug_type, 1);
    },
  },
  methods: {
    onClickTpye(type) {
      this.$router
        .replace({
          path: `/search/${type}?keyword=${this.$route.query.keyword}`,
          params: { type: type },
        })
        .catch(() => {});
      this.events = [];
      this.news = [];
      this.partners = [];
      this.thaipages = [];
    },
    getArticle(type, page = 1, isloadMore = false) {
      // events
      if (type === "events") {
        isloadMore ? (this.loadingBtn = true) : (this.loading = true);
        this.onHandleArticle(type, page)
          .then((res) => {
            this.events =
              this.events.length > 0 ? [...this.events, ...res.data] : res.data;
            this.paginate.current_page = res.current_page;
            this.paginate.last_page = res.last_page;
            this.paginate.total = res.total;
            this.loading = false;
            this.loadingBtn = false;
          })
          .catch((err) => {
            this.loading = false;
            this.loadingBtn = false;
          });
      }

      //  news
      if (type === "news") {
        isloadMore ? (this.loadingBtn = true) : (this.loading = true);
        this.onHandleArticle(type, page)
          .then((res) => {
            this.news =
              this.news.length > 0 ? [...this.news, ...res.data] : res.data;
            this.paginate.current_page = res.current_page;
            this.paginate.last_page = res.last_page;
            this.paginate.total = res.total;
            this.loading = false;
            this.loadingBtn = false;
          })
          .catch((err) => {
            this.loading = false;
            this.loadingBtn = false;
          });
      }

      //  partners
      if (type === "partners") {
        isloadMore ? (this.loadingBtn = true) : (this.loading = true);
        this.onHandleArticle(type, page)
          .then((res) => {
            this.partners =
              this.partners.length > 0
                ? [...this.partners, ...res.data]
                : res.data;
            this.paginate.current_page = res.current_page;
            this.paginate.last_page = res.last_page;
            this.paginate.total = res.total;
            this.loading = false;
            this.loadingBtn = false;
          })
          .catch((err) => {
            this.loading = false;
            this.loadingBtn = false;
          });
      }

      //  thaipages
      if (type === "thaipage") {
        isloadMore ? (this.loadingBtn = true) : (this.loading = true);
        this.onHandleArticle(type, page)
          .then((res) => {
            this.thaipages =
              this.thaipages.length > 0
                ? [...this.thaipages, ...res.data]
                : res.data;
            this.paginate.current_page = res.current_page;
            this.paginate.last_page = res.last_page;
            this.paginate.total = res.total;
            this.loading = false;
            this.loadingBtn = false;
          })
          .catch((err) => {
            this.loading = false;
            this.loadingBtn = false;
          });
      }
    },

    async onHandleArticle(type, page = 1) {
      if (type === "events") {
        const res = await this.$service.event.getEvents({
          page: page,
          paginate: 10,
          search: this.$route.query.keyword,
          category_id: "",
        });
        return res.data;
      }

      if (type === "news") {
        const res = await this.$service.news.getNews({
          search: this.$route.query.keyword,
          page: page,
          year: "",
          month: "",
          limit: 10,
        });
        return res.data;
      }

      if (type === "partners") {
        const res = await this.$service.partner.getPartners({
          search: this.$route.query.keyword,
          page: page,
          subcategory_id: "",
          category_id: "",
          limit: 10,
        });
        return res.data;
      }

      if (type === "thaipage") {
        const res = await this.$service.thaipage.getThaipageNews({
          search: this.$route.query.keyword,
          page: page,
          category_thaipage_id: "",
          subcategory_thaipage_id: "",
          limit: 10,
        });
        return res.data;
      }
    },
  },
};
</script>

<style lang="scss">
.search__btn {
  .v-slide-group__next,
  .v-slide-group__prev {
    min-width: unset !important;
  }
}
</style>
