import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./assets/css/main.css";
import router from "./router";
import store from "./store";
import Service from "./services";

// VueMeta
import VueMeta from "vue-meta";
Vue.use(VueMeta);

Vue.config.productionTip = false;
//! ===> Sweetalert 2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

//! ===> vue-ui-preloader
import loader from "vue-ui-preloader";
Vue.use(loader);

//! ===> Loading
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading, {
  color: "#0066B3",
  width: 150,
  height: 150,
  loader: "bars",
  opacity: 1,
});
Vue.component("loading", Loading);
Vue.prototype.$service = Service;

Vue.mixin({
  methods: {
    replaceUri(slug) {
      // var content = content.toString().replace(/["'“”`]/g, "");

      var path = slug.toString().replaceAll(" ", "-");
      path = path.toString().replaceAll("/", "-");
      path = path.toString().replaceAll("?", "");
      path = path.toString().replaceAll("%", "percent");
      path = path.toString().replaceAll('"', "");
      path = path.toString().replaceAll(",", "");
      path = path.toString().replaceAll(";", "");
      path = path.toString().replaceAll(":", "");
      path = path.toString().replaceAll("'", "");
      path = path.toString().replaceAll("…", "");
      path = path.toString().replaceAll(".", "");
      return path;
    },
    viewDetail(id, slug) {
      if (id != 0) {
        var path = this.replaceUri(slug);
        this.$router.push(`/events/${path}-${id}`);
      }
    },

    viewDetailNews(item) {
      var path = this.replaceUri(item.title);
      this.$router.push(`/news/${path}-${item.id}`);
    },

    viewDetailThaipage(item) {
      var path = this.replaceUri(item.title);
      this.$router.push(`/thaipage/${path}-${item.id}`);
    },

    viewFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    getPartnerDetail(id, type, web, title) {
      if (type == 2 && web) {
        if (web.search("https://") == -1 && web.search("http://") == -1) {
          window.open("https://" + web);
        } else {
          window.open(web);
        }
      } else {
        this.$router.push(`/partners/${title.replaceAll(" ", "-")}-${id}`);
      }
    },
  },
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
