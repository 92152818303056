// import authService from "../authService";
import Service from "../Service";

const common = {
  setCookie() {
    return Service.post(`/api/save-cookie`);
  },
  saveAnswer(payload) {
    return Service.post(`/api/save-answer`, payload);
  },
  getQuestion() {
    return Service.get(`/api/questions`);
  },
};

export default common;
