<template>
  <v-row no-gutters justfy="center" align="center">
    <!-- Image -->
    <v-col
      :class="
        $vuetify.breakpoint.xs
          ? ''
          : checkPosition !== 'right'
          ? 'pr-5'
          : 'pl-5'
      "
      cols="12"
      sm="6"
      md="6"
      :order="checkPosition === 'left' ? 'first' : 'last'"
      @click="viewDetail(item.id, item.title_event)"
    >
      <v-img
        class="event-image image-hover"
        :aspect-ratio="4 / 3"
        :src="item.thumbnail"
        :alt="item.title_event"
        lazy-src="../../assets/images/default/default_4_3.png"
      >
        <v-row class="white--text" no-gutters justfy="end" align="end">
          <v-col cols="12" align="end">
            <img
              v-if="item.type === 'ONLINE'"
              width="34"
              src="@/assets/images/events/type-online.png"
            />
            <img
              v-if="item.type === 'THAILAND'"
              width="34"
              src="@/assets/images/events/type-th.png"
            />
            <img
              v-if="item.type === 'USA'"
              width="34"
              src="@/assets/images/events/type-usa.png"
            />
            <img
              v-if="item.type === 'CANADA'"
              width="34"
              src="@/assets/images/events/type-canada.png"
            />
            <img
              v-if="item.type === 'MEXICO'"
              width="34"
              src="@/assets/images/events/type-mexico.png"
            />
            <img
              v-if="item.type === 'SPAIN'"
              width="34"
              src="@/assets/images/events/type-spain.png"
            />
          </v-col>
        </v-row>
      </v-img>
    </v-col>

    <!-- Description -->
    <v-col
      class="font-clamp-10-14"
      cols="12"
      sm="6"
      md="6"
      :order="checkPosition === 'right' ? 'first' : 'last'"
      :align="checkPosition === 'right' ? 'end' : 'start'"
    >
      <div
        class="event-title mt-4 mb-2 font-cl font-weight-bold"
        @click="viewDetail(item.id, item.title_event)"
      >
        {{ item.title_event }}
      </div>
      <div class="event-date">{{ item.start_date }} - {{ item.end_date }}</div>
      <div class="event-time">{{ item.start_time }} - {{ item.end_time }}</div>
      <div class="event-location mb-1">{{ item.location }} {{ item.city }}</div>
      <span
        class="event-category"
        v-for="(subCate, i) in item.subcategorys"
        :key="i"
      >
        {{ subCate.name }}
        <span v-if="i + 1 != item.subcategorys.length">,</span>
      </span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: null,
    event_id: null,
    imagePosition: {
      default: "left",
      type: String,
    },
  },
  computed: {
    checkPosition() {
      if (
        this.$vuetify.breakpoint.name === "xs" &&
        this.imagePosition === "right"
      ) {
        return "left";
      } else {
        return this.imagePosition;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.event-title {
  font-family: "ChulabhornLikitDisplay";
  font-style: normal;
  font-weight: bold;
  line-height: 118%;
  letter-spacing: -0.01em;
}

@media only screen and (max-width: 375px) {
  .event-title {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: bold;
    line-height: 118%;
    letter-spacing: -0.01em;
  }
  .event-image {
    width: 351px;
    height: 254.41px;
  }
}
</style>
