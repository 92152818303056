<template>
  <div>
    <!-- Navbar -->
    <Navbar />

    <!-- style="min-height: 100vh; height: 100%" -->
    <v-layout style="min-height: 80vh;margin-top: 54px">
      <v-flex>
        <router-view />
      </v-flex>
    </v-layout>

    <Footer />
  </div>
</template>

<script>
import Footer from "./Footer.vue";
import Navbar from "./Navbar.vue";
export default {
  components: { Navbar, Footer },
};
</script>
