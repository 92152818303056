// import authService from "../authService";
import Service from "../Service";

const event = {
  getNews(data) {
    return Service.get(
      `/api/news?page=${data.page}&year=${data.year}&month=${
        data.month
      }&search=${data.search ? data.search : ""}&limit=${
        data.limit ? data.limit : ""
      }`
    );
  },
  getNewsById(id) {
    return Service.get(`/api/news/${id}`);
  },
  getNewsData() {
    return Service.get(`/api/news-data`);
  },
  getNewsProductDetail(payload) {
    return Service.get(`/api/get-news-product-detail/${payload}`);
  },
};

export default event;
