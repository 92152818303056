// import authService from "../authService";
import Service from "../Service";

const event = {
  getData() {
    return Service.get(`/api/data-thaipage`);
  },
  getThaipageById(id) {
    return Service.get(`/api/thaipage/${id}`);
  },
  getThaipageNews(data) {
    return Service.get(
      `/api/thaipage?page=${data.page}&category_thaipage_id=${
        data.category_thaipage_id
      }&subcategory_thaipage_id=${data.subcategory_thaipage_id}&limit=${
        data.limit ? data.limit : ""
      }&search=${data.search ? data.search : ""}`
    );
  },
};

export default event;
