<template>
  <div>
    <div class="news" v-if="news">
      <v-layout
        justify-start
        class="news-title-detail pb-3 pt-2"
        style="background: #f9f9f9;"
      >
        <v-flex>
          <v-container class="mt-0 pt-0">
            <v-layout
              justify-center
              :class="
                $vuetify.breakpoint.smAndUp
                  ? 'title-detail'
                  : 'title-detail-mobile'
              "
            >
              <v-flex>
                {{ news.title }}
              </v-flex>
            </v-layout>
            <div class="created_date mt-1">{{ news.publish_date }}</div>
            <div class="author">{{ news.author }}</div>
            <div class="company">{{ news.company }}</div>
          </v-container>
        </v-flex>
      </v-layout>
      <v-container>
        <div class="detail mt-md-6 mb-16 v-html" v-html="news.detail"></div>
      </v-container>

      <!-- <template>
        <div class="text-center ">
          <v-dialog scrollable v-model="dialog" :width="widthDialog"> -->
      <div v-if="dialog" class="con-modal-event fade-in">
        <v-layout style="position:relative">
          <v-flex xs12>
            <v-btn
              small
              style="z-index: 999999"
              class="mt-n6"
              absolute
              top
              right
              @click="dialog = false"
              color="#fff"
              icon
            >
              <v-icon color="#A40802">mdi-close-circle</v-icon>
            </v-btn>

            <v-img
              src="../../../assets/images/Modal Event - Tag shadow.png"
              alt=""
            ></v-img>

            <v-layout
              wrap
              style="padding: 0px 10px 0px 6px;position:relative;"
              class=" mt-n3"
            >
              <v-flex xs12 class="card-modal-event pb-1">
                <v-img
                  :aspect-ratio="1"
                  width="100%"
                  :src="event_popup.thumbnail"
                >
                </v-img>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <div class="d-flex justify-center mt-6">
          <a target="_blank" :href="`/events/${event_popup.id}`">
            <v-btn min-width="200" tile color="#000" dark>
              <span class="font-cl">Go to Event</span>
            </v-btn>
          </a>
        </div>
      </div>

      <!-- </v-dialog>
        </div>
      </template> -->
    </div>

    <div v-else>
      <DataNotFound />
    </div>

    <!-- <pre>
      {{ news.title }}
    </pre> -->

    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import { mapState } from "vuex";
import DataNotFound from "../../../components/DataNotFound.vue";
import { domain_front } from "../../../services/Constants";
export default {
  components: { LoadingCircular, DataNotFound },
  data() {
    return {
      news: null,
      event_popup: null,
      dialog: false,
      loading: false,
    };
  },
  metaInfo() {
    return {
      title: `${
        this.news && this.news.title ? this.news.title : ""
      } - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: this.news
            ? this.news.title + " on ThaiTradeUSA - " + this.news.description
            : "",
        },
        {
          property: "og:title",
          content: `${
            this.news && this.news.title ? this.news.title : ""
          } - ThaiTradeUSA.com`,
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: this.news
            ? this.news.title + " on ThaiTradeUSA - " + this.news.description
            : "",
        },
        { property: "og:type", content: "news" },
        {
          property: "og:url",
          content: domain_front + "news/" + this.$route.params.slug,
        },
        {
          property: "og:image",
          content:
            this.news && this.news.cover_image ? this.news.cover_image : "",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      questionNewsDetail: (state) => state.common.questionNewsDetail,
      isTodoQuestionNewsDetail: (state) =>
        state.common.isTodoQuestionNewsDetail,
    }),
    widthDialog() {
      let h = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          h = 320;
          break;
        case "sm":
          h = 400;
          break;
        case "md":
          h = 600;
          break;
        case "lg":
          h = 600;
          break;
        case "xl":
          h = 700;
          break;
      }
      return h;
    },
  },

  created() {
    console.log(domain_front + "news/" + this.$route.params.slug);
    this.getNewsDetail();
    window.scrollTo(0, 0);
  },

  methods: {
    getNewsDetail() {
      this.loading = true;
      this.$service.news
        .getNewsById(
          this.$route.params.slug
            .split("-")
            .slice(-1)
            .pop()
        )
        .then((res) => {
          this.news = res.data.news;
          this.event_popup = res.data.event_popup;
          this.loading = false;
          if (
            (this.questionNewsDetail && this.questionNewsDetail.updated_at) ===
              this.isTodoQuestionNewsDetail &&
            this.event_popup.banner
          ) {
            setTimeout(() => {
              this.dialog = true;
            }, 500);
          }
        });
    },
  },
};
</script>

<style scoped>
.v-html >>> img {
  width: 100% !important;
  height: auto !important;
}

.v-html >>> iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 1.78341013825;
}
@media only screen and (max-width: 768px) {
  .v-html >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html {
    display: flex !important;
    align-items: center !important;
    flex-direction: column;
  }
}
@media only screen and (max-width: 375px) {
  .v-html >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html >>> iframe {
    width: 100% !important;
    display: block;
    height: auto !important;
    aspect-ratio: 1.78341013825;
  }
  .con-modal-event {
    right: 10px;
    width: 300px;
  }
}
</style>

<style lang="scss">
.news .news-title-detail {
  .title-detail {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 100%;
    // padding-bottom: 12%;
  }

  .title-detail-mobile {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
  }
  .created_date {
    font-family: "ChulabhornLikitText", "Prompt";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    color: #6a6a6a;
  }
  .author {
    font-family: "ChulabhornLikitText", "Prompt";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }
  .company {
    font-family: "ChulabhornLikitText", "Prompt";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }
}
.modal-events {
  top: 0;
  z-index: 99999;
  position: fixed;
  /* transform: translate(50%, -50%); */
  /* height: 100vh; */
  background: rgba(182, 179, 179, 0.453);
  width: 100%;
  height: 100vh;
}
.modal-event {
  padding-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-dialog {
  border-radius: unset;
}
.con-modal-event {
  position: fixed;
  z-index: 1;
  top: 82px;
  right: 32px;
  width: 336px;
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.card-modal-event {
  padding: 0px 5px;
  background: #fff;
  box-shadow: 3px 8px 8px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 3px 8px 8px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 3px 8px 8px rgba(0, 0, 0, 0.25);
}
</style>
