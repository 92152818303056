<template>
  <div>
    <div class="thaipage_news" v-if="thaipage_news">
      <v-layout
        justify-start
        class="news-title-detail py-3 py-sm-6"
        style="background: #F9F9F9;"
      >
        <v-flex>
          <v-container>
            <v-layout
              justify-center
              :class="
                $vuetify.breakpoint.smAndUp
                  ? 'title-detail'
                  : 'title-detail-mobile'
              "
            >
              <v-flex style="line-height: 130%;">
                {{ thaipage_news.title }}
              </v-flex>
            </v-layout>
            <div class="created_date mt-md-6 mt-3">
              {{ thaipage_news.created_at }}
            </div>
            <div class="author">{{ thaipage_news.author }}</div>
            <div class="company">{{ thaipage_news.company }}</div>
          </v-container>
        </v-flex>
      </v-layout>

      <v-container>
        <div class="font-14 font-weight-bold mt-2 mt-md-6">
          {{ thaipage_news.category_thaipage_name }}
        </div>
        <div class="font-14">
          {{ thaipage_news.subcategory_thaipage_name }}
        </div>
        <div class="font-14" v-if="thaipage_news.subcategory_thaipage">
          {{ thaipage_news.subcategory_thaipage.description }}
        </div>

        <div
          class="detail mt-md-6 mb-md-16 mb-6 v-html-thaipage"
          v-html="thaipage_news.detail"
        ></div>
      </v-container>

      <div class="thaipage-related-news mt-6">
        <v-container class="py-12">
          <v-layout
            justify-center
            :class="
              $vuetify.breakpoint.smAndUp
                ? 'title-detail'
                : 'title-detail-mobile'
            "
          >
            <v-flex>
              RELATED NEWS
            </v-flex>
          </v-layout>
          <div class="thaipage-category mt-6 mt-md-3 font-weight-bold">
            {{ thaipage_news.category_thaipage_name }}
          </div>
          <div>
            <span
              class="thaipage-subcategory"
              @click="onClickCate"
              :style="
                $vuetify.breakpoint.smAndUp
                  ? { color: '#000000' }
                  : { color: '#0066B3' }
              "
            >
              {{ thaipage_news.subcategory_thaipage_name }}
            </span>
          </div>

          <!-- Related news desktop size -->
          <template v-if="$vuetify.breakpoint.smAndUp">
            <v-row
              class="mt-2"
              v-if="related_news.length > 0"
              style="margin-bottom: 3rem"
            >
              <v-col
                cols="12"
                sm="4"
                md="4"
                v-for="(item, i) in related_news"
                :key="i"
              >
                <v-row>
                  <v-col cols="12">
                    <v-img
                      @click="onHandleNews(item)"
                      class="image-hover"
                      :aspect-ratio="4 / 3"
                      :src="item.thumbnail"
                      :alt="item.title"
                      lazy-src="../../../assets/images/default/default_4_3.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <div class="card-detail">
                      <div class="card-date-thaipage">
                        {{ item.created_at }}
                      </div>
                      <div class="card-title ellipsis-3">
                        <span
                          class="link-hover font-weight-bold"
                          @click="onHandleNews(item)"
                        >
                          {{ item.title }}
                        </span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>

          <!-- Related news mobile size -->
          <template v-else>
            <div class="related-news mt-4 mb-6" v-if="related_news.length > 0">
              <VueSlickCarousel v-bind="settings">
                <div v-for="(item, i) in related_news" :key="i">
                  <v-card color="#E8E8F2" tile flat width="283" class="mr-6">
                    <v-row>
                      <v-col cols="12">
                        <v-img
                          @click="onHandleNews(item)"
                          class="image-hover"
                          :aspect-ratio="4 / 3"
                          :src="item.thumbnail"
                          :alt="item.title"
                          lazy-src="../../../assets/images/default/default_4_3.png"
                        ></v-img>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <div class="card-detail">
                          <div class="card-date-thaipage">
                            {{ item.created_at }}
                          </div>
                          <div class="card-title ellipsis-3">
                            <span
                              class="link-hover font-weight-bold"
                              @click="onHandleNews(item)"
                            >
                              {{ item.title }}
                            </span>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </VueSlickCarousel>
            </div>
          </template>
        </v-container>
      </div>

      <loading-circular :loading="loading" />
    </div>

    <div v-else>
      <DataNotFound />
    </div>
  </div>
</template>

<script>
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import DataNotFound from "../../../components/DataNotFound.vue";
import { domain_front } from "../../../services/Constants";

export default {
  components: { LoadingCircular, VueSlickCarousel, DataNotFound },
  data() {
    return {
      thaipage_news: [],
      related_news: [],
      thaipage_id: "",
      loading: false,
      settings: {
        centerMode: true,
        centerPadding: "24px",
        focusOnSelect: true,
        touchMove: true,
        infinite: false,
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        dots: false,
      },
    };
  },
  metaInfo() {
    return {
      title: `${
        this.thaipage_news ? this.thaipage_news.title : ""
      } - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: this.thaipage_news
            ? this.thaipage_news.title + " on ThaiTradeUSA"
            : "",
        },
        {
          property: "og:title",
          content: `${
            this.thaipage_news ? this.thaipage_news.title : ""
          } - ThaiTradeUSA.com`,
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: this.thaipage_news
            ? this.thaipage_news.title + " on ThaiTradeUSA "
            : "",
        },
        { property: "og:type", content: "thaipage" },
        {
          property: "og:url",
          content: domain_front + "thaipage/" + this.$route.params.slug,
        },
        {
          property: "og:image",
          content: this.thaipage_news ? this.thaipage_news.thumbnail : "",
        },
      ],
    };
  },
  created() {
    this.getThaipageNewsDetail(this.$route.params.slug);
    window.scrollTo(0, 0);
  },
  watch: {
    thaipage_id(val) {
      this.getThaipageNewsDetail(val);
    },
  },
  methods: {
    getSlugId(slug) {
      return slug
        .split("-")
        .slice(-1)
        .pop();
    },
    getThaipageNewsDetail(slug) {
      var id = this.getSlugId(slug);
      window.scrollTo(0, 0);
      this.loading = true;
      this.$service.thaipage.getThaipageById(id).then((res) => {
        const { thaipage_news, related_news } = res.data;
        this.thaipage_news = thaipage_news;
        this.related_news = related_news;
        this.loading = false;
      });
    },
    onClickCate(cate, subcate) {
      if (this.thaipage_news.subcategory_thaipage_id) {
        this.$router.replace(
          `/thaipage?category=${this.thaipage_news.category_thaipage_id}&subcategory=${this.thaipage_news.subcategory_thaipage_id}`
        );
        this.$router.replace(
          `/thaipage?category=${this.thaipage_news.category_thaipage_id}`
        );
      }
    },
    onHandleNews(item) {
      this.thaipage_id = `${item.title.replaceAll(" ", "-")}-${item.id}`;
      this.thaipage_id = this.thaipage_id.replaceAll("/", "-");
      this.thaipage_id = this.thaipage_id.replaceAll("?", "-");

      this.$router.replace(`/thaipage/${this.thaipage_id}`);
    },
  },
};
</script>

<style scoped>
.v-html-thaipage >>> img {
  width: 100% !important;
  height: auto !important;
}
.v-html-thaipage >>> iframe {
  /* width: 100% !important;
  height: auto !important; */
  /* aspect-ratio: 1.78341013825; */
}
@media only screen and (max-width: 768px) {
  .v-html-thaipage >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html-thaipage >>> iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1.78341013825;
  }
}
</style>

<style lang="scss">
.thaipage-related-news {
  .title-detail {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 100%;
  }

  .title-detail-mobile {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
  }
}
.thaipage_news .news-title-detail {
  .title-detail {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 100%;
    // padding-bottom: 12%;
  }

  .title-detail-mobile {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
  }
  .created_date {
    font-family: "ChulabhornLikitText", "Prompt";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    color: #6a6a6a;
  }
  .author {
    font-family: "ChulabhornLikitText", "Prompt";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }
  .company {
    font-family: "ChulabhornLikitText", "Prompt";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }
}
.card-thaipage-news-detail {
  background: #f9f9f9;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}
.thaipage-related-news {
  background: #e8e8f2;
}
.thaipage-category {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  color: #000000;
}
.thaipage-subcategory {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
}
.thaipage-subcategory:hover {
  color: #0066b3 !important;
  cursor: pointer;
}
.card-date-thaipage {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */

  color: #6a6a6a;
}
</style>
<style lang="scss">
.related-news {
  .slick-list {
    padding: 0px 0px !important;
  }
}
</style>
