// import authService from "../authService";
import Service from "../Service";

const event = {
  getData() {
    return Service.get(`/api/faq`);
  },
};

export default event;
