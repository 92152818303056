<template>
  <div>
    <loader
      v-if="loading"
      object="#A40802"
      color1="#ffffff"
      color2="#0066B3"
      size="5"
      speed="2"
      bg="#F9F9F9"
      objectbg="#999793"
      opacity="100"
      name="circular"
    ></loader>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style></style>
