<template>
  <div>
    <label v-if="label" class="input-label" :for="label">
      {{ label }}
      <span style="color: red" v-if="required">
        *
      </span>
    </label>
    <v-text-field
      outlined
      dense
      hide-details
      :rules="
        required
          ? label === 'Email' || label === 'Email อีเมล์'
            ? [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
              ]
            : [(v) => !!v || 'Field is required']
          : []
      "
      :value="model"
      :required="required"
      :readonly="readonly"
      :placeholder="placeholder"
      @input="$emit('updateData', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: null,
    model: null,
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.input-label {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  color: #424040;
}
</style>
