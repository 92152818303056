<template>
  <div>
    <BannerComponent :data="banner" />

    <v-container>
      <v-layout justify-center>
        <v-flex xs10>
          <div
            v-if="contact"
            class="text-center mb-12 mt-8 contact-us-title"
            v-html="contact.contact_description"
          ></div>

          <!-- Button -->
          <v-layout justify-center>
            <v-flex lg9 md10 sm11 xs12>
              <v-row justify="center">
                <!-- button America -->
                <v-col cols="8" sm="4" class="mb-8 mb-sm-0">
                  <v-btn
                    height="57"
                    depressed
                    @click="onClickSelectFormContact(1)"
                    block
                    dark
                    tile
                    color="#012060"
                  >
                    <span class="font-18 text-capitalize font-weight-bold">
                      North America
                    </span>
                  </v-btn>
                  <div class="text-center mt-4 btn-text-link ">
                    CLICK HERE If you reside or have business in U.S.A., Canada,
                    Mexico, and the Caribbean
                  </div>

                  <!-- Form mobile America -->
                  <div class="mt-10 mx-n12">
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      v-if="$vuetify.breakpoint.xs && form.contact_zone == 1"
                    >
                      <v-row>
                        <v-col cols="12" class="topic-contact-us">
                          CONTACT INFORMATION (<span
                            style="font-weight: normal;"
                            >North America </span
                          >)
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            @updateData="form.company_name = $event"
                            :model="form.company_name"
                            label="Company"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Contact Name"
                            :model="form.contact_name"
                            @updateData="form.contact_name = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Title"
                            :model="form.title"
                            @updateData="form.title = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Email"
                            :model="form.email"
                            @updateData="form.email = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Telephone/Mobile"
                            :model="form.tel"
                            @updateData="form.tel = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Address 1"
                            :model="form.address1"
                            @updateData="form.address1 = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="City"
                            :model="form.city"
                            @updateData="form.city = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            label="Address 2"
                            :model="form.address2"
                            @updateData="form.address2 = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <Autocoplete
                            required
                            label="Country"
                            :items="state_usa"
                            itemText="name"
                            itemValue="id"
                            :model="form.country_id"
                            @updateData="
                              countries_state_usa = $event.country_state_usa;
                              form.country_id = $event.id;
                              form.country_name = $event.name;
                              form.province_id = '';
                              form.province_name = '';
                            "
                            returnObject
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Zip code/Postal code"
                            :model="form.zip_code"
                            @updateData="form.zip_code = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="12">
                          <Autocoplete
                            required
                            label="State/Province"
                            :items="countries_state_usa"
                            itemText="name"
                            itemValue="id"
                            :model="form.province_id"
                            @updateData="
                              form.province_id = $event.id;
                              form.province_name = $event.name;
                            "
                            returnObject
                          />
                          <span class="font-12" style="color: red">
                            Please select Country first.
                          </span>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <Autocoplete
                            required
                            label="Type of Business"
                            :items="business_type_usa"
                            itemText="business_type_name"
                            itemValue="business_type_id"
                            multiple
                            :model="form.business_type_ids"
                            @updateData="form.business_type_ids = $event"
                            returnObject
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <Autocoplete
                            required
                            label="Type of Industry"
                            :items="industry_type"
                            itemText="industry_type_name"
                            itemValue="industry_type_id"
                            multiple
                            :model="form.industry_type_ids"
                            @updateData="form.industry_type_ids = $event"
                            returnObject
                          />
                        </v-col>

                        <v-col cols="12">
                          <label class="input-label" for="Message">
                            Message<span style="color: red">*</span>
                          </label>
                          <v-textarea
                            required
                            :rules="[(v) => !!v || 'Field is required']"
                            rows="4"
                            v-model="form.message"
                            hide-details
                            outlined
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          class="pt-0"
                          v-if="
                            form.contact_zone == 1 &&
                              contact &&
                              contact.contact_information_description_status ==
                                1
                          "
                        >
                          <div
                            v-html="contact.contact_information_description"
                          ></div>
                        </v-col>
                        <v-col
                          cols="12"
                          class="px-6 mt-1 mb-10"
                          v-if="form.contact_zone == 1"
                        >
                          <v-row
                            class="newsletter-box px-6"
                            justify="space-between"
                            align="center"
                          >
                            <v-col
                              cols="12"
                              sm="auto"
                              class="text-center pb-0 pb-sm-3"
                            >
                              Sign up for Newsletters ?
                            </v-col>
                            <v-col
                              cols="12"
                              sm="auto"
                              class="d-flex  justify-sm-end justify-center py-0 py-sm-3"
                            >
                              <v-radio-group
                                v-model="form.is_ttc_newsletter"
                                row
                              >
                                <v-radio
                                  label="Yes"
                                  color="#61BB79"
                                  value="1"
                                ></v-radio>
                                <v-radio
                                  label="No"
                                  color="#61BB79"
                                  value="0"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <div>
                        <v-btn
                          height="40"
                          :disabled="!valid"
                          tile
                          depressed
                          :block="$vuetify.breakpoint.xs"
                          :width="$vuetify.breakpoint.smAndUp ? '400' : '100%'"
                          color="#555E7D"
                          class="px-12"
                          @click="validate"
                          :dark="valid"
                        >
                          <span
                            style="font-family: Chulabhorn Likit Text;"
                            class="font-weight-bold font-14  text-capitalize"
                          >
                            Submit
                          </span>
                        </v-btn>
                      </div>
                    </v-form>
                  </div>
                </v-col>

                <!-- button Thailand -->
                <v-col cols="8" sm="4" class="mb-8 mb-sm-0">
                  <v-btn
                    height="57"
                    depressed
                    @click="onClickSelectFormContact(2)"
                    block
                    tile
                    dark
                    color="#0066B3"
                  >
                    <span class="font-18 text-capitalize font-weight-bold"
                      >Thailand</span
                    >
                  </v-btn>
                  <div class="text-center mt-4 btn-text-link ">
                    CLICK HERE If you are Thai Exporters or have business in
                    Thailand <br />
                    สำหรับ ผู้ส่งออกไทย หรือ ผู้ประกอบธุรกิจอยู่ในประเทศไทย
                  </div>

                  <!-- Form mobile Thailand -->
                  <div class="mt-10 mx-n12">
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      v-if="$vuetify.breakpoint.xs && form.contact_zone == 2"
                    >
                      <v-row>
                        <v-col cols="12" class="topic-contact-us">
                          CONTACT INFORMATION ข้อมูลติดต่อ (<span
                            style="font-weight: normal;font-style: italic;"
                            >Thailand </span
                          >)
                        </v-col>
                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            @updateData="form.company_name = $event"
                            :model="form.company_name"
                            label="Company ชื่อบริษัท"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Contact Name ชื่อ-นามสกุล"
                            :model="form.contact_name"
                            @updateData="form.contact_name = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Title ตำแหน่ง"
                            :model="form.title"
                            @updateData="form.title = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Email อีเมล์"
                            :model="form.email"
                            @updateData="form.email = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Telephone/Mobile เบอร์โทรศัพท์"
                            :model="form.tel"
                            @updateData="form.tel = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Address 1 ที่อยู่ (1)"
                            :model="form.address1"
                            @updateData="form.address1 = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="City เขต"
                            :model="form.city"
                            @updateData="form.city = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            label="Address 2 ที่อยู่ (2)"
                            :model="form.address2"
                            @updateData="form.address2 = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <Autocoplete
                            required
                            label="State จังหวัด"
                            :items="provinces"
                            itemText="name"
                            itemValue="province_id"
                            :model="form.province_id"
                            @updateData="
                              form.province_id = $event.id;
                              form.province_name = $event.text;
                            "
                            returnObject
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Postal code รหัสไปรษณีย์"
                            :model="form.zip_code"
                            @updateData="form.zip_code = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="12">
                          <TextInput
                            readonly
                            label="Country ประเทศ"
                            :model="form.country_name"
                          />
                          <!-- <Autocoplete
                            required
                            label="Country ประเทศ"
                            :items="countries"
                            itemText="text"
                            itemValue="id"
                            :model="form.country_id"
                            @updateData="
                              form.country_id = $event.id;
                              form.country_name = $event.text;
                            "
                            returnObject
                          /> -->
                        </v-col>

                        <v-col cols="12" sm="6">
                          <Autocoplete
                            required
                            label="Type of Business ประเภทธุรกิจ"
                            :items="business_type_thai"
                            itemText="business_type_name_thai"
                            itemValue="business_type_id"
                            :model="form.business_type_ids"
                            @updateData="form.business_type_ids = $event"
                            multiple
                            returnObject
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <Autocoplete
                            required
                            label="Type of Industry ประเภทอุตสาหกรรม"
                            :items="industry_type"
                            itemText="industry_type_name"
                            itemValue="industry_type_id"
                            :model="form.industry_type_ids"
                            @updateData="form.industry_type_ids = $event"
                            multiple
                            returnObject
                          />
                        </v-col>

                        <v-col cols="12" sm="12">
                          <Autocoplete
                            required
                            label="Please select the office you wish to contact กรุณาเลือกสำนักงานที่ท่านต้องการติดต่อ"
                            :items="ttc_offices"
                            itemText="title"
                            itemValue="id"
                            :model="form.ttc_office_id"
                            @updateData="
                              form.ttc_office_id = $event.id;
                              form.ttc_office_name = $event.title;
                            "
                            returnObject
                          />
                        </v-col>

                        <v-col cols="12">
                          <label class="input-label" for="Message"
                            >Message ข้อความ<span style="color: red"
                              >*</span
                            ></label
                          >
                          <v-textarea
                            required
                            :rules="[(v) => !!v || 'Field is required']"
                            rows="3"
                            v-model="form.message"
                            hide-details
                            outlined
                          />
                        </v-col>
                        <v-col cols="12" class="px-6 mt-4">
                          <v-row
                            class="newsletter-box px-6 radio-contact"
                            justify="space-between"
                            align="center"
                          >
                            <v-col cols="12" sm="auto" class="pb-0 text-center">
                              Are you member of ...?
                              ปัจจุบันท่านเป็นสมาชิกของหน่วยงานใดหรือไม่
                            </v-col>
                            <v-col
                              cols="12"
                              sm="auto"
                              class="d-flex justify-sm-end justify-center py-0"
                            >
                              <v-radio-group
                                v-model="form.is_member"
                                row
                                class="mt-2"
                              >
                                <v-radio
                                  label="DITP| กรมส่งเสริมการส่งออก"
                                  color="#61BB79"
                                  value="1"
                                  class="mb-3 mb-sm-0 mt-0"
                                ></v-radio>
                                <v-radio
                                  label="ThaiTrade.com"
                                  color="#61BB79"
                                  value="2"
                                  class="mb-3 mb-sm-0 mt-0"
                                ></v-radio>
                                <v-radio
                                  label="No| ยังไม่ได้เป็นสมาชิก"
                                  color="#61BB79"
                                  value="3"
                                  class="mb-3 mb-sm-0 mt-0"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col
                          cols="12"
                          v-if="
                            contact &&
                              contact.trade_inquiry_description_status == 1
                          "
                        >
                          <div v-html="contact.trade_inquiry_description"></div>
                        </v-col>
                      </v-row>

                      <div class="text-center mt-6" v-if="form.contact_zone">
                        <v-btn
                          height="40"
                          :disabled="!valid"
                          tile
                          depressed
                          :block="$vuetify.breakpoint.xs"
                          :width="$vuetify.breakpoint.smAndUp ? '400' : '100%'"
                          color="#555E7D"
                          class="px-12"
                          @click="validate"
                          :dark="valid"
                        >
                          <span
                            style="font-family: Chulabhorn Likit Text;"
                            class="font-weight-bold font-14  text-capitalize"
                          >
                            Submit
                          </span>
                        </v-btn>
                      </div>
                    </v-form>
                  </div>
                </v-col>

                <!-- button Other -->
                <v-col cols="8" sm="4" class="mb-14 mb-sm-0">
                  <v-btn
                    height="57"
                    depressed
                    @click="onClickSelectFormContact(3)"
                    block
                    dark
                    tile
                    color="#7395AE"
                  >
                    <span class="font-18 text-capitalize font-weight-bold">
                      Other Countries
                    </span>
                  </v-btn>
                  <div class="text-center mt-4 btn-text-link ">
                    CLICK HERE If you are from other countries
                  </div>

                  <!-- Form mobile Other -->
                  <div class="mt-10 mx-n12">
                    <v-form
                      ref="form"
                      v-model="valid"
                      lazy-validation
                      v-if="$vuetify.breakpoint.xs && form.contact_zone == 3"
                    >
                      <v-row>
                        <v-col cols="12" class="topic-contact-us">
                          CONTACT INFORMATION (<span
                            style="font-weight: normal;font-style: italic;"
                            >Other Countries </span
                          >)
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            @updateData="form.company_name = $event"
                            :model="form.company_name"
                            label="Company"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Contact Name"
                            :model="form.contact_name"
                            @updateData="form.contact_name = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Title"
                            :model="form.title"
                            @updateData="form.title = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Email"
                            :model="form.email"
                            @updateData="form.email = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Telephone/Mobile"
                            :model="form.tel"
                            @updateData="form.tel = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Address 1"
                            :model="form.address1"
                            @updateData="form.address1 = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="City"
                            :model="form.city"
                            @updateData="form.city = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            label="Address 2"
                            :model="form.address2"
                            @updateData="form.address2 = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <Autocoplete
                            required
                            label="Country"
                            :items="all_countries"
                            itemText="name"
                            itemValue="id"
                            :model="form.country_id"
                            @updateData="
                              form.country_id = $event.id;
                              form.country_name = $event.name;
                            "
                            returnObject
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <TextInput
                            required
                            label="Zip code/Postal code"
                            :model="form.zip_code"
                            @updateData="form.zip_code = $event"
                          />
                        </v-col>

                        <v-col cols="12" sm="12">
                          <TextInput
                            v-if="form.contact_zone == 3"
                            required
                            label="State/Provincee"
                            :model="form.province_name"
                            @updateData="form.province_name = $event"
                          />
                          <Autocoplete
                            v-else
                            required
                            label="State/Province"
                            :items="countries_state_usa"
                            itemText="name"
                            itemValue="id"
                            :model="form.province_id"
                            @updateData="
                              form.province_id = $event.id;
                              form.province_name = $event.name;
                            "
                            returnObject
                          />
                          <span
                            v-if="form.contact_zone == 1"
                            class="font-12"
                            style="color: red"
                          >
                            Please select Country first.
                          </span>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <Autocoplete
                            required
                            label="Type of Business"
                            :items="business_type_usa"
                            itemText="business_type_name"
                            itemValue="business_type_id"
                            multiple
                            :model="form.business_type_ids"
                            @updateData="form.business_type_ids = $event"
                            returnObject
                          />
                        </v-col>

                        <v-col cols="12" sm="6">
                          <Autocoplete
                            required
                            label="Type of Industry"
                            :items="industry_type"
                            itemText="industry_type_name"
                            itemValue="industry_type_id"
                            multiple
                            :model="form.industry_type_ids"
                            @updateData="form.industry_type_ids = $event"
                            returnObject
                          />
                        </v-col>

                        <v-col cols="12">
                          <label class="input-label" for="Message"
                            >Message<span style="color: red">*</span></label
                          >
                          <v-textarea
                            required
                            :rules="[(v) => !!v || 'Field is required']"
                            rows="4"
                            v-model="form.message"
                            hide-details
                            outlined
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          class="pt-0"
                          v-if="
                            contact &&
                              contact.contact_information_description_status ==
                                1
                          "
                        >
                          <div
                            v-html="contact.contact_information_description"
                          ></div>
                        </v-col>
                        <v-col
                          cols="12"
                          class="px-6 mt-1 mb-10"
                          v-if="form.contact_zone == 1"
                        >
                          <v-row
                            class="newsletter-box px-6"
                            justify="space-between"
                            align="center"
                          >
                            <v-col cols="12" sm="auto" class="text-center">
                              Sign up for Newsletters ?
                            </v-col>
                            <v-col
                              cols="12"
                              sm="auto"
                              class="d-flex  justify-sm-end justify-center"
                            >
                              <v-radio-group
                                v-model="form.is_ttc_newsletter"
                                row
                              >
                                <v-radio
                                  label="Yes"
                                  color="#61BB79"
                                  value="1"
                                ></v-radio>
                                <v-radio
                                  label="No"
                                  color="#61BB79"
                                  value="0"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <div>
                        <v-btn
                          height="40"
                          :disabled="!valid"
                          tile
                          depressed
                          :block="$vuetify.breakpoint.xs"
                          :width="$vuetify.breakpoint.smAndUp ? '400' : '100%'"
                          color="#555E7D"
                          class="px-12"
                          @click="validate"
                          :dark="valid"
                        >
                          <span
                            style="font-family: Chulabhorn Likit Text;"
                            class="font-weight-bold font-14  text-capitalize"
                          >
                            Submit
                          </span>
                        </v-btn>
                      </div>
                    </v-form>
                  </div>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>

          <!-- Topic -->
          <v-row
            class="mt-0"
            v-if="form.contact_zone && $vuetify.breakpoint.smAndUp"
          >
            <v-col>
              <!-- Form -->
              <v-form ref="form" v-model="valid" lazy-validation>
                <!-- Form North America and Other -->
                <v-row
                  v-if="
                    form.contact_zone == 1 ||
                      form.contact_zone == 3 ||
                      $route.params.type == 'america' ||
                      $route.params.type == 'other'
                  "
                >
                  <v-col cols="12" class="topic-contact-us">
                    <span v-if="form.contact_zone == 1">
                      CONTACT INFORMATION (<span
                        style="font-weight: normal;font-style: italic;"
                        >North America </span
                      >)
                    </span>
                    <span v-if="form.contact_zone == 3">
                      CONTACT INFORMATION (<span
                        style="font-weight: normal;font-style: italic;"
                        >Other Countries</span
                      >)
                    </span>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      @updateData="form.company_name = $event"
                      :model="form.company_name"
                      label="Company"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Contact Name"
                      :model="form.contact_name"
                      @updateData="form.contact_name = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Title"
                      :model="form.title"
                      @updateData="form.title = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Email"
                      :model="form.email"
                      @updateData="form.email = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Telephone/Mobile"
                      :model="form.tel"
                      @updateData="form.tel = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Address 1"
                      :model="form.address1"
                      @updateData="form.address1 = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="City"
                      :model="form.city"
                      @updateData="form.city = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      label="Address 2"
                      :model="form.address2"
                      @updateData="form.address2 = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <Autocoplete
                      v-if="form.contact_zone == 1"
                      required
                      label="Country"
                      :items="state_usa"
                      itemText="name"
                      itemValue="id"
                      :model="form.country_id"
                      @updateData="
                        countries_state_usa = $event.country_state_usa;
                        form.country_id = $event.id;
                        form.country_name = $event.name;
                        form.province_id = '';
                        form.province_name = '';
                      "
                      returnObject
                    />
                    <Autocoplete
                      v-else
                      required
                      label="Country"
                      :items="all_countries"
                      itemText="name"
                      itemValue="id"
                      :model="form.country_id"
                      @updateData="
                        form.country_id = $event.id;
                        form.country_name = $event.name;
                        form.province_id = '';
                        form.province_name = '';
                      "
                      returnObject
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Zip code/Postal code"
                      :model="form.zip_code"
                      @updateData="form.zip_code = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div v-if="form.contact_zone == 1">
                      <Autocoplete
                        required
                        label="State/Province"
                        :items="countries_state_usa"
                        itemText="name"
                        itemValue="id"
                        :model="form.province_id"
                        @updateData="
                          form.province_id = $event.id;
                          form.province_name = $event.name;
                        "
                        returnObject
                      />
                      <span class="font-12" style="color: red">
                        Please select Country first.
                      </span>
                    </div>
                    <div v-else>
                      <TextInput
                        label="State/Province"
                        :model="form.province_name"
                        @updateData="form.province_name = $event"
                      />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <Autocoplete
                      required
                      label="Type of Business"
                      :items="business_type_usa"
                      itemText="business_type_name"
                      itemValue="business_type_id"
                      multiple
                      :model="form.business_type_ids"
                      @updateData="form.business_type_ids = $event"
                      returnObject
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <Autocoplete
                      required
                      label="Type of Industry"
                      :items="industry_type"
                      itemText="industry_type_name"
                      itemValue="industry_type_id"
                      multiple
                      :model="form.industry_type_ids"
                      @updateData="form.industry_type_ids = $event"
                      returnObject
                    />
                  </v-col>

                  <v-col cols="12">
                    <label class="input-label" for="Message"
                      >Message<span style="color: red">*</span></label
                    >
                    <v-textarea
                      required
                      :rules="[(v) => !!v || 'Field is required']"
                      rows="4"
                      v-model="form.message"
                      hide-details
                      outlined
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    class="px-6 my-4"
                    v-if="form.contact_zone == 1"
                  >
                    <v-row
                      class="newsletter-box px-6"
                      justify="space-between"
                      align="center"
                    >
                      <v-col cols="12" sm="auto" class="text-center">
                        Sign up for Newsletters ?
                      </v-col>
                      <v-col
                        cols="12"
                        sm="auto"
                        class="d-flex  justify-sm-end justify-center"
                      >
                        <v-radio-group v-model="form.is_ttc_newsletter" row>
                          <v-radio
                            label="Yes"
                            color="#61BB79"
                            value="1"
                          ></v-radio>
                          <v-radio
                            label="No"
                            color="#61BB79"
                            value="0"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    class="pt-0"
                    v-if="
                      form.contact_zone == 1 &&
                        contact &&
                        contact.contact_information_description_status == 1
                    "
                  >
                    <div v-html="contact.contact_information_description"></div>
                  </v-col>

                  <v-col
                    cols="12"
                    class="pt-0 d-flex flex-column"
                    v-if="
                      form.contact_zone == 3 &&
                        contact &&
                        contact.other_description_status == 1
                    "
                  >
                    <span
                      class="pb-0"
                      v-html="contact.other_description"
                    ></span>
                  </v-col>
                </v-row>

                <!-- Form Thailand -->
                <v-row
                  v-if="
                    form.contact_zone == 2 || $route.params.type == 'thailand'
                  "
                >
                  <v-col cols="12" class="topic-contact-us">
                    CONTACT INFORMATION ข้อมูลติดต่อ (<span
                      style="font-weight: normal;font-style: italic;"
                      >Thailand </span
                    >)
                  </v-col>
                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      @updateData="form.company_name = $event"
                      :model="form.company_name"
                      label="Company ชื่อบริษัท"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Contact Name ชื่อ-นามสกุล"
                      :model="form.contact_name"
                      @updateData="form.contact_name = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Title ตำแหน่ง"
                      :model="form.title"
                      @updateData="form.title = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Email อีเมล์"
                      :model="form.email"
                      @updateData="form.email = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Telephone/Mobile เบอร์โทรศัพท์"
                      :model="form.tel"
                      @updateData="form.tel = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Address 1 ที่อยู่ (1)"
                      :model="form.address1"
                      @updateData="form.address1 = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="City เขต"
                      :model="form.city"
                      @updateData="form.city = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      label="Address 2 ที่อยู่ (2)"
                      :model="form.address2"
                      @updateData="form.address2 = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <Autocoplete
                      required
                      label="State จังหวัด"
                      :items="provinces"
                      itemText="name"
                      itemValue="province_id"
                      :model="form.province_id"
                      @updateData="
                        form.province_id = $event.id;
                        form.province_name = $event.text;
                      "
                      returnObject
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <TextInput
                      required
                      label="Postal code รหัสไปรษณีย์"
                      :model="form.zip_code"
                      @updateData="form.zip_code = $event"
                    />
                  </v-col>

                  <v-col cols="12" sm="12">
                    <TextInput
                      readonly
                      label="Country ประเทศ"
                      :model="form.country_name"
                    />
                    <!-- <Autocoplete
                      required
                      label="Country ประเทศ"
                      :items="countries"
                      itemText="text"
                      itemValue="id"
                      :model="form.country_id"
                      @updateData="
                        form.country_id = $event.id;
                        form.country_name = $event.text;
                      "
                      returnObject
                    /> -->
                  </v-col>

                  <v-col cols="12" sm="6">
                    <Autocoplete
                      required
                      label="Type of Business ประเภทธุรกิจ"
                      :items="business_type_thai"
                      itemText="business_type_name_thai"
                      itemValue="business_type_id"
                      :model="form.business_type_ids"
                      @updateData="form.business_type_ids = $event"
                      multiple
                      returnObject
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <Autocoplete
                      required
                      label="Type of Industry ประเภทอุตสาหกรรม"
                      :items="industry_type"
                      itemText="industry_type_name"
                      itemValue="industry_type_id"
                      :model="form.industry_type_ids"
                      @updateData="form.industry_type_ids = $event"
                      multiple
                      returnObject
                    />
                  </v-col>

                  <v-col cols="12" sm="12">
                    <Autocoplete
                      required
                      label="Please select the office you wish to contact กรุณาเลือกสำนักงานที่ท่านต้องการติดต่อ"
                      :items="ttc_offices"
                      itemText="title"
                      itemValue="id"
                      :model="form.ttc_office_id"
                      @updateData="
                        form.ttc_office_id = $event.id;
                        form.ttc_office_name = $event.title;
                      "
                      returnObject
                    />
                  </v-col>

                  <v-col cols="12">
                    <label class="input-label" for="Message">
                      Message ข้อความ
                      <span style="color: red">
                        *
                      </span>
                    </label>
                    <v-textarea
                      required
                      :rules="[(v) => !!v || 'Field is required']"
                      rows="3"
                      v-model="form.message"
                      hide-details
                      outlined
                    />
                  </v-col>
                  <v-col cols="12" class="px-6 mt-4">
                    <v-row
                      class="newsletter-box px-6 radio-contact"
                      justify="space-between"
                      align="center"
                    >
                      <v-col cols="12" sm="auto" class="pb-0 text-center">
                        Are you member of ...?
                        ปัจจุบันท่านเป็นสมาชิกของหน่วยงานใดหรือไม่
                      </v-col>
                      <v-col
                        cols="12"
                        sm="auto"
                        class="d-flex justify-sm-end justify-center py-0"
                      >
                        <v-radio-group
                          v-model="form.is_member"
                          row
                          class="mt-2"
                        >
                          <v-radio
                            label="DITP| กรมส่งเสริมการส่งออก"
                            color="#61BB79"
                            value="1"
                            class="mb-3 mb-sm-0 mt-0"
                          ></v-radio>
                          <v-radio
                            label="ThaiTrade.com"
                            color="#61BB79"
                            value="2"
                            class="mb-3 mb-sm-0 mt-0"
                          ></v-radio>
                          <v-radio
                            label="No| ยังไม่ได้เป็นสมาชิก"
                            color="#61BB79"
                            value="3"
                            class="mb-3 mb-sm-0 mt-0"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    v-if="
                      contact && contact.trade_inquiry_description_status == 1
                    "
                  >
                    <div v-html="contact.trade_inquiry_description"></div>
                  </v-col>
                </v-row>

                <div class="text-center mt-6 mb-16" v-if="form.contact_zone">
                  <v-btn
                    height="40"
                    :disabled="!valid"
                    tile
                    depressed
                    :block="$vuetify.breakpoint.xs"
                    :width="$vuetify.breakpoint.smAndUp ? '400' : '100%'"
                    color="#555E7D"
                    class="px-12"
                    @click="validate"
                    :dark="valid"
                  >
                    <span
                      style="font-family: Chulabhorn Likit Text;"
                      class="font-weight-bold font-14  text-capitalize"
                    >
                      Submit
                    </span>
                  </v-btn>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>

    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import BannerComponent from "../../../components/banner/BannerComponent.vue";
import Autocoplete from "../../../components/form/Autocoplete.vue";
import TextInput from "../../../components/form/TextInput.vue";
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import { domain_front } from "../../../services/Constants";

export default {
  components: { BannerComponent, Autocoplete, TextInput, LoadingCircular },
  data() {
    return {
      is_contact: null,
      contact: null,
      isShowFormAmerica: false,
      loading: false,
      valid: true,
      banner: {},
      countries: [
        { id: 1, text: "foo" },
        { id: 2, text: "bar" },
      ],
      countries_state_usa: [],
      provinces: [],
      state_usa: [],
      all_countries: [],
      business_type: [],

      // Type of Business – For Thailand Form
      business_type_thai: [
        {
          business_type_id: 1,
          business_type_name_thai: "Manufacturers | โรงงานผลิต",
        },
        {
          business_type_id: 2,
          business_type_name_thai:
            "Manufacturer/exporter | โรงงานผลิตและส่งออก",
        },
        {
          business_type_id: 3,
          business_type_name_thai:
            "Exporter/Trading Company | ผู้ส่งออก/เทรดดิ้งคอมปานี",
        },
        {
          business_type_id: 4,
          business_type_name_thai: "Buying Agent | สำนักงานตัวแทนจัดหาสินค้า",
        },
        { business_type_id: 5, business_type_name_thai: "Others | อื่นๆ" },
      ],

      // Type of Business - For North America & Other Countries Form
      business_type_usa: [
        { business_type_id: 1, business_type_name: "Importer/Distributor" },
        {
          business_type_id: 2,
          business_type_name: "Retailers - Supermarket/Groceries",
        },
        {
          business_type_id: 3,
          business_type_name: "Retailers - Department Store",
        },
        {
          business_type_id: 4,
          business_type_name: "Retailers -  Discount Store",
        },
        { business_type_id: 5, business_type_name: "Retailers - E-Commerce" },
        {
          business_type_id: 6,
          business_type_name: "Retailers - Specialty Store",
        },
        { business_type_id: 7, business_type_name: "Manufacturer" },
        { business_type_id: 8, business_type_name: "Wholesaler" },
        { business_type_id: 9, business_type_name: "Buying Agent" },
        // {
        //   business_type_id: 10,
        //   business_type_name: "Entertainment",
        // },
        {
          business_type_id: 10,
          business_type_name: "Others",
        },
      ],

      //Type of Industry (For all forms)
      industry_type: [
        {
          industry_type_id: 1,
          industry_type_name:
            "Agricultural Products | อุตสาหกรรมเกษตร - Rice | ข้าว",
        },
        {
          industry_type_id: 2,
          industry_type_name:
            "Agricultural Products | อุตสาหกรรมเกษตร - Fresh/frozen fruits and fruit products | ผลไม้สด แข่เยือกแข็ง ผลิตภัณผลไม้",
        },
        {
          industry_type_id: 3,
          industry_type_name:
            "Agricultural Products | อุตสาหกรรมเกษตร - Fresh/frozen seafood and seafood products | อาหารทะเลสด แช่เยือกแข็ง ผลิตภัณฑ์อาหารทะเล",
        },
        {
          industry_type_id: 4,
          industry_type_name:
            "Agricultural Products | อุตสาหกรรมเกษตร - Rubber | ยางพารา",
        },
        {
          industry_type_id: 5,
          industry_type_name:
            "Agricultural Products | อุตสาหกรรมเกษตร - Others | สินค้าเกษตรอื่นๆ",
        },
        {
          industry_type_id: 6,
          industry_type_name:
            "Automotive, Parts and Accessories | สินค้ายานยนตร์, ชิ้นส่วนประกอบ และประดับยนตร์",
        },
        {
          industry_type_id: 7,
          industry_type_name:
            "Cosmetics, Personal Care and Hygiene | เครื่องสำอางค์, เครื่องใช้สำหรับร่างกายและเพื่อสุขอนามัย",
        },
        {
          industry_type_id: 8,
          industry_type_name:
            "Computers, Parts & Accessories | คอมพิวเตอร์และอุปกรณ์",
        },
        {
          industry_type_id: 9,
          industry_type_name: "Electronics | อิเล็กโทรนิกส์",
        },
        {
          industry_type_id: 10,
          industry_type_name: "Foods & Beverages | สินค้าอาหารและเครื่องดื่ม",
        },
        {
          industry_type_id: 11,
          industry_type_name:
            "Home decoration | ของแต่งบ้าน - Indoor/outfoor/office Furnitures | เฟอร์นิเจอร์ในบ้าน นอกบ้าน",
        },
        {
          industry_type_id: 12,
          industry_type_name:
            "Home decoration | ของแต่งบ้าน - Indoor decorations | ของตกแต่งภายในบ้าน",
        },
        {
          industry_type_id: 13,
          industry_type_name:
            "Home decoration | ของแต่งบ้าน - Outdoor/Garden Decorations | ของตกแต่งภายนอกและสวน",
        },
        {
          industry_type_id: 14,
          industry_type_name: "Household Products | สินค้าของใช้ในครัว",
        },
        {
          industry_type_id: 15,
          industry_type_name:
            "Leather products | สินค้าเครื่องหนัง - handbag | กระเป๋าถือ",
        },
        {
          industry_type_id: 16,
          industry_type_name:
            "Leather products | สินค้าเครื่องหนัง - Travelling bag | กระเป๋าเดินทาง",
        },
        {
          industry_type_id: 17,
          industry_type_name:
            "Leather products | สินค้าเครื่องหนัง - Gloves | ถุงมือทั่วไป",
        },
        {
          industry_type_id: 18,
          industry_type_name:
            "Leather products | สินค้าเครื่องหนัง - Others | อื่นๆ",
        },
        {
          industry_type_id: 19,
          industry_type_name:
            "Medical/Hospital Supplies | เครื่องมือและอุปกรณ์ทางการแพทย์ - Medical Gloves | ถุงมือทางการแพทย์",
        },
        {
          industry_type_id: 20,
          industry_type_name:
            "Medical/Hospital Supplies | เครื่องมือและอุปกรณ์ทางการแพทย์ - Surgical Gloves | ถุงมือผ่าตัด",
        },
        {
          industry_type_id: 21,
          industry_type_name:
            "Medical/Hospital Supplies | เครื่องมือและอุปกรณ์ทางการแพทย์ - Masks | หน้ากากอนามัย",
        },
        {
          industry_type_id: 22,
          industry_type_name:
            "Medical/Hospital Supplies | เครื่องมือและอุปกรณ์ทางการแพทย์ - Others | อื่นๆ",
        },
        { industry_type_id: 23, industry_type_name: "Footwear | รองเท้า" },
        {
          industry_type_id: 24,
          industry_type_name:
            "Garment & Textile | สิ่งทอ เครื่องนุ่งห่ม เสื้อผ้าสำเร็จรูป",
        },
        {
          industry_type_id: 25,
          industry_type_name:
            "Gems & Jewelry | อัญมณี และเครื่องประดับ - Fine jewelry | เครื่องประดับแท้",
        },
        {
          industry_type_id: 26,
          industry_type_name:
            "Gems & Jewelry | อัญมณี และเครื่องประดับ - Fashion jewelry | เครื่องประดับเทียม",
        },
        {
          industry_type_id: 27,
          industry_type_name:
            "Gems & Jewelry | อัญมณี และเครื่องประดับ - Precious stones | อัญมณีมีค่า",
        },
        {
          industry_type_id: 28,
          industry_type_name:
            "Gems & Jewelry | อัญมณี และเครื่องประดับ - Semi-precious stones | อัญมณีกึ่งมีค่า",
        },
        {
          industry_type_id: 29,
          industry_type_name:
            "Gems & Jewelry | อัญมณี และเครื่องประดับ - Silver jewelry | เครื่องประดับเงิน",
        },
        {
          industry_type_id: 30,
          industry_type_name: "Gifts | ของขวัญ/ของชำร่วย",
        },
        {
          industry_type_id: 31,
          industry_type_name: "Entertainment | ธุรกิจบันเทิง",
        },
        {
          industry_type_id: 32,
          industry_type_name: "Others | อื่นๆ",
        },
      ],
      ttc_offices: [],
      form: {
        country_id: "",
        country_name: "",
        province_id: "",
        province_name: "",
        company_name: "",
        contact_name: "",
        title: "",
        email: "",
        tel: "",
        address1: "",
        address2: "",
        city: "",
        zip_code: "",
        business_type_ids: "",
        industry_type_ids: "",
        message: "",
        ttc_office_id: "",
        ttc_office_name: "",
        is_ttc_newsletter: "",
        is_member: "",
        contact_zone: null,
      },
    };
  },
  metaInfo() {
    return {
      title: `Contact Us - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: "Contact Us Thai Trade USA",
        },
        {
          property: "og:title",
          content: "Contact Us - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: "Contact Us Thai Trade USA",
        },
        { property: "og:type", content: "Contact Us" },
        {
          property: "og:url",
          content: domain_front + "contact-us",
        },
        {
          property: "og:image",
          content: this.banner.path_mobile,
        },
      ],
    };
  },
  created() {
    this.getBanner();
  },
  mounted() {
    console.log(this.$route);
    if (this.$route.params.type) {
      if (this.$route.params.type === "america") {
        this.clearForm(1);
      }
      if (this.$route.params.type === "thailand") {
        this.clearForm(2);
      }
      if (this.$route.params.type === "other") {
        this.clearForm(3);
      }
    }
  },
  computed: {
    typeForm() {
      return this.$route.params.type;
    },
  },
  watch: {
    typeForm(val) {
      if (val === "thailand") {
        this.form.country_id = null;
        this.form.country_name = "Thailand";
      }
    },
  },
  methods: {
    getBanner() {
      this.loading = true;
      this.$service.contact_us.getBanner().then((res) => {
        this.banner = res.data.banner;
        this.contact = res.data.contact_description;
        this.provinces = res.data.provinces;
        this.state_usa = res.data.state_usa;
        this.all_countries = res.data.all_countries;
        this.ttc_offices = res.data.ttc_offices;
        this.loading = false;
      });
    },
    clearForm(id) {
      this.form = {
        country_id: "",
        country_name: id == 2 ? (this.form.country_name = "Thailand") : "",
        province_id: "",
        province_name: "",
        company_name: "",
        contact_name: "",
        title: "",
        email: "",
        tel: "",
        address1: "",
        address2: "",
        city: "",
        zip_code: "",
        business_type_ids: "",
        industry_type_ids: "",
        message: "",
        ttc_office_id: "",
        ttc_office_name: "",
        is_ttc_newsletter: "",
        is_member: "",
        contact_zone: id,
      };
    },
    handleReplaceRoute(id) {
      if (id == 1 && this.$route.params.type !== "america") {
        this.$router.replace("/contact-us/america");
        // this.$router.replace("/contact-us?type=america");
      }
      if (id == 2 && this.$route.params.type !== "thailand") {
        this.$router.replace("/contact-us/thailand");
        // this.$router.replace("/contact-us?type=thailand");
      }
      if (id == 3 && this.$route.params.type !== "other") {
        this.$router.replace("/contact-us/other");
        // this.$router.replace("/contact-us?type=other");
      }
    },
    onClickSelectFormContact(id) {
      this.clearForm(id);
      this.handleReplaceRoute(id);
      if (!this.valid) {
        this.$refs.form.resetValidation();
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    save() {
      this.loading = true;
      this.$service.contact_us
        .save(this.form)
        .then((res) => {
          this.$swal({
            icon: "success",
            title: "Send successfully",
            showConfirmButton: false,
            confirmButtonColor: "#0066B3",
            timer: 1500,
          });
          this.loading = false;
          this.clearForm(this.form.contact_zone);
          this.$refs.form.resetValidation();
        })
        .catch((err) => {
          console.error(err);
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });

          this.loading = false;
        });
    },
  },
};
</script>

<style>
.contact-us-title {
  font-family: Chulabhorn Likit Text;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.btn-contact-us {
  font-family: Chulabhorn Likit Text;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 85.39%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;

  color: #ffffff;
}
.btn-contact-us:hover {
  background: #012060 !important;
  color: #fff !important;
  border: 1px solid #012060;
}
.topic-contact-us {
  font-family: Chulabhorn Likit Text;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.newsletter-box {
  background: #e8e8f2;
  border: 1px solid #555e7d;
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  color: #555e7d;
}
.contact-desc {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #5d5c61;
}
.radio-contact {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  color: #555e7d;
}
.desc-contact-other {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #555e7d;
}
.btn-text-link {
  font-family: Chulabhorn Likit Text;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
}
.v-chip {
  display: inline-table !important;
  white-space: normal !important;
}
.v-select.v-input--dense .v-chip {
  margin: 4px 4px 0px 4px;
}
</style>
