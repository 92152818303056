<template>
  <v-card flat color="#F9F9F9" class="pa-4">
    <v-row>
      <v-col cols="auto">
        <v-img
          class="image-hover"
          width="122"
          :aspect-ratio="1"
          :src="item.thumbnail"
          :alt="item.title_event"
          lazy-src="../../assets/images/default/default_4_3.png"
        >
          <v-row class="white--text" no-gutters justfy="end" align="end">
            <v-col cols="12" align="end">
              <img
                v-if="item.type === 'ONLINE'"
                width="34"
                src="@/assets/images/events/type-online.png"
              />
              <img
                v-if="item.type === 'THAILAND'"
                width="34"
                src="@/assets/images/events/type-th.png"
              />
              <img
                v-if="item.type === 'USA'"
                width="34"
                src="@/assets/images/events/type-usa.png"
              />
              <img
                v-if="item.type === 'CANADA'"
                width="34"
                src="@/assets/images/events/type-canada.png"
              />
              <img
                v-if="item.type === 'MEXICO'"
                width="34"
                src="@/assets/images/events/type-mexico.png"
              />
              <img
                v-if="item.type === 'SPAIN'"
                width="34"
                src="@/assets/images/events/type-spain.png"
              />
            </v-col>
          </v-row>
        </v-img>
      </v-col>
      <v-col cols>
        <div class="font-14 font-weight-bold ellipsis-1">
          <span
            class="link-hover"
            @click="viewDetail(item.id, item.title_event)"
          >
            {{ item.title_event }}
          </span>
        </div>
        <div class="font-12">{{ item.start_date }} - {{ item.end_date }}</div>
        <div class="font-12">{{ item.start_time }} - {{ item.end_time }}</div>
        <div class="font-12 ellipsis-1">
          {{ item.location }} {{ item.city }}
        </div>
        <div class="ellipsis-2">
          <span
            class="event-category font-14"
            v-for="(subCate, i) in item.subcategorys"
            :key="i"
          >
            {{ subCate.name }}
            <span v-if="i + 1 != item.subcategorys.length">,</span>
          </span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    id: null,
    item: null,
  },
};
</script>

<style></style>
