<template>
  <div style="position: relative">
    <!-- Banner -->
    <v-layout
      :class="
        $vuetify.breakpoint.smAndUp
          ? 'btn-control-desktop'
          : 'btn-control-mobile'
      "
    >
      <v-flex
        shrink
        v-for="i in $vuetify.breakpoint.smAndUp
          ? checkLengthDesktop
          : checkLengthMobile"
        :key="i"
        class="d-flex align-end ml-2 mb-n2"
      >
        <v-btn
          width="25"
          height="25"
          fab
          depressed
          :outlined="isActiveCarousel == i - 1 ? false : true"
          :color="isActiveCarousel == i - 1 ? '#000' : '#000'"
          dark
          small
          @click="handleChange(i - 1)"
        >
          {{ i }}
        </v-btn>
      </v-flex>
    </v-layout>

    <div v-if="items">
      <v-carousel
        :continuous="true"
        v-if="$vuetify.breakpoint.smAndUp"
        v-model="currentIndex"
        class="banner-slide pa-0 ma-0"
        :show-arrows="false"
        hide-delimiter-background
        hide-delimiters
        height="auto"
        cycle
        @change="handleChange"
      >
        <v-carousel-item eager v-if="items.path_desktop1">
          <a :href="items.url_banner1" target="_blank">
            <v-img
              eager
              width="100%"
              height="auto"
              cover
              :src="items.path_desktop1"
            >
            </v-img>
          </a>
        </v-carousel-item>
        <v-carousel-item eager v-if="items.path_desktop2">
          <a :href="items.url_banner2" target="_blank">
            <v-img
              eager
              width="100%"
              height="auto"
              cover
              :src="items.path_desktop2"
            >
            </v-img>
          </a>
        </v-carousel-item>
        <v-carousel-item eager v-if="items.path_desktop3">
          <a :href="items.url_banner3" target="_blank">
            <v-img
              eager
              width="100%"
              height="auto"
              cover
              :src="items.path_desktop3"
            >
            </v-img>
          </a>
        </v-carousel-item>
      </v-carousel>

      <v-carousel
        v-else
        v-model="currentIndex"
        class="banner-slide"
        :continuous="true"
        :show-arrows="false"
        hide-delimiter-background
        hide-delimiters
        cycle
        height="auto"
        @change="handleChange"
      >
        <v-carousel-item eager v-if="items.path_mobile1">
          <a :href="items.url_banner1" target="_blank">
            <img
              class="slide-image-mobile"
              width="100%"
              height="100%"
              :src="items.path_mobile1"
            />
          </a>
        </v-carousel-item>
        <v-carousel-item eager v-if="items.path_mobile2">
          <a :href="items.url_banner2" target="_blank">
            <img
              class="slide-image-mobile"
              width="100%"
              height="100%"
              :src="items.path_mobile2"
            />
          </a>
        </v-carousel-item>
        <v-carousel-item eager v-if="items.path_mobile3">
          <a :href="items.url_banner3" target="_blank">
            <img
              class="slide-image-mobile"
              width="100%"
              height="100%"
              :src="items.path_mobile3"
            />
          </a>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: null,
  },
  data() {
    return {
      isActiveCarousel: 0,
      currentIndex: 0,
      data: {
        event_name: "Thai Food @home",
        banner_events: {
          desktop: [
            {
              banner: require("../../assets/images/events/banner/StyleBangkok_1282_303.png"),
            },
          ],
          mobile: [
            {
              banner: require("../../assets/images/events/banner/mobile/StyleBangkok_375_506.jpeg"),
            },
            {
              banner: require("../../assets/images/events/banner/mobile/OBM IDEA Lab_Aug 2021_375x506.jpeg"),
            },
          ],
        },
      },
    };
  },
  computed: {
    checkLengthDesktop() {
      let i = 0;
      if (this.items) {
        if (this.items.path_desktop1) {
          i = i + 1;
        }
        if (this.items.path_desktop2) {
          i = i + 1;
        }
        if (this.items.path_desktop3) {
          i = i + 1;
        }
      }
      return i;
    },
    checkLengthMobile() {
      let i = 0;
      if (this.items) {
        if (this.items.path_mobile1) {
          i = i + 1;
        }
        if (this.items.path_mobile2) {
          i = i + 1;
        }
        if (this.items.path_mobile3) {
          i = i + 1;
        }
      }
      return i;
    },
  },
  methods: {
    handleChange(val) {
      this.currentIndex = val;
      this.isActiveCarousel = val;
    },
  },
};
</script>

<style>
.img-banner {
  width: 100%;
  max-height: 303px;
  object-fit: cover;
}
.banner-slide .v-carousel__controls {
  justify-content: flex-end !important;
}
.btn-control-desktop {
  position: absolute;
  z-index: 99;
  bottom: 5%;
  right: 2%;
}
.btn-control-mobile {
  position: absolute;
  z-index: 99;
  bottom: 5%;
  right: 3%;
}
.slide-image-mobile {
  max-height: 500px;
  object-fit: cover;
}
</style>
