// import authService from "../authService";
import Service from "../Service";

const event = {
  getData() {
    return Service.get(`/api/categorys`);
  },
  getCategoryById(id) {
    return Service.get(`/api/categorys/${id}`);
  },
};

export default event;
