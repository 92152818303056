<template>
  <div>
    <BannerComponent :data="data_banner" />

    <v-container class="mb-8">
      <template>
        <v-row justify="center" class="my-6 my-sm-12 px-6  px-md-0">
          <!-- <v-col cols="10" sm="12"> -->
          <v-expansion-panels inset tile flat>
            <v-expansion-panel
              style="border-bottom: 1px solid #CACACA"
              v-for="(item, i) in faq"
              :key="i"
              active-class="active"
              class="py-3"
            >
              <!-- style="border-bottom: 1px solid #CACACA" -->
              <v-expansion-panel-header>
                <span style="font-size: clamp(12px, 2.5vw, 14px)">
                  {{ i + 1 }}. {{ item.question }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider style="background: #000" />
                <div
                  class="mt-4"
                  style="font-size: clamp(10px, 2.5vw, 12px);color: #555E7D;"
                >
                  <div v-html="item.answer"></div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- </v-col> -->
        </v-row>
      </template>
    </v-container>

    <div
      class="py-sm-12 py-6 px-6 px-md-0 contact-card"
      :class="$vuetify.breakpoint.xs ? 'con-contact-mobile' : ''"
    >
      <v-container
        class="pb-10 pt-6 pb-sm-12 pt-sm-12  text-center"
        style="background: #fff;"
        :class="$vuetify.breakpoint.xs ? 'contact-mobile' : ''"
      >
        <div class="mb-3 font-14">Have more questions ?</div>
        <button-outline
          @click.native="$router.push('/contact-us')"
          text="Contact Us"
          color="#0066B3"
        />
      </v-container>
    </div>
    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import BannerComponent from "../../../components/banner/BannerComponent.vue";
import ButtonOutline from "../../../components/button/ButtonOutline.vue";
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import { domain_front } from "../../../services/Constants";

export default {
  components: { BannerComponent, ButtonOutline, LoadingCircular },
  data() {
    return {
      loading: false,
      data_banner: {},
      faq: [],
    };
  },
  metaInfo() {
    return {
      title: `Faq - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: "Faq Thai Trade USA",
        },
        {
          property: "og:title",
          content: "Faq - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: "Faq Thai Trade USA",
        },
        { property: "og:type", content: "Faq" },
        {
          property: "og:url",
          content: domain_front + "Faq",
        },
        {
          property: "og:image",
          content: this.data_banner.path_mobile,
        },
      ],
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.$service.faq.getData().then((res) => {
        const { banner, faq } = res.data;
        this.data_banner = banner;
        this.faq = faq;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="css">
button.v-expansion-panel-header.v-expansion-panel-header--active {
  font-weight: bold;
}
</style>
<style scoped>
.active {
  /* padding-right: 3rem !important;
  padding-left: 3rem !important; */
}
.contact-card {
  background: #0066b3;
  color: #0066b3;
  font-family: "ChulabhornLikitText", "Prompt";
  font-size: clamp(10px, 2.5vw, 14px);
  font-style: normal;
  font-weight: normal;
  line-height: 23px;
}
.con-contact-mobile {
  /* margin-bottom: -2.7rem; */
}
.contact-mobile {
  position: relative;
  z-index: 99;
}
</style>
