<template>
  <v-card min-height="122" flat color="#F9F9F9" class="pa-4">
    <v-row>
      <v-col cols="auto">
        <v-img
          class="image-hover"
          width="122"
          :aspect-ratio="4 / 3"
          :src="item.cover_image"
          :alt="item.title"
          lazy-src="../../assets/images/default/default_4_3.png"
        >
        </v-img>
      </v-col>
      <v-col cols class="d-flex flex-column">
        <div class="font-14 font-weight-bold ">
          <span class="link-hover ellipsis-1" @click="$emit('onClick')">
            {{ item.title }}
          </span>
        </div>
        <div class="font-12 d-flex align-center my-1" style="color: #6A6A6A">
          <v-icon class="mr-1" style="font-size: 14px" color="#6A6A6A">
            mdi-calendar
          </v-icon>
          {{ item.publish_date }}
        </div>
        <div class="font-14 ellipsis-2">{{ item.description || "-" }}</div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    item: null,
  },
};
</script>

<style></style>
