// import authService from "../authService";
import Service from "../Service";

const event = {
  getData() {
    return Service.get(`/api/event-data`);
  },
  getEvents(payload) {
    return Service.get(
      `/api/events?page=${payload.page}&paginate=${
        payload.paginate
      }&category_id=${payload.category_id}&search=${
        payload.search ? payload.search : ""
      }`
    );
  },
  getEventDetail(id) {
    return Service.get(`/api/events/${id}`);
  },
};

export default event;
