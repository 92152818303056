<template>
  <div class="">
    <div v-if="event">
      <!-- Title -->

      <v-layout
        justify-start
        class="con-title-detail"
        :style="$vuetify.breakpoint.mdAndUp ? 'background: #f9f9f9;' : ''"
      >
        <v-flex>
          <div
            class="my-1 my-sm-10"
            :class="
              $vuetify.breakpoint.smAndUp
                ? 'title-detail'
                : 'title-detail-mobile'
            "
          >
            <v-layout justify-center class="container pb-0">
              <v-flex>
                {{ event.title_event }}
              </v-flex>
            </v-layout>
            <v-layout
              justify-start
              class="container pb-0"
              v-if="$vuetify.breakpoint.smAndUp"
            >
              <v-flex shrink v-if="event.link_register">
                <a :href="event.link_register" target="_blank">
                  <v-btn text depressed large tile dark class="px-0">
                    <v-img
                      width="170"
                      src="../../../assets/images/register.png"
                    />
                  </v-btn>
                </a>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>

      <div style="display: none">
        <div
          :style="$vuetify.breakpoint.mdAndUp && 'background: #f9f9f9;'"
          :class="$vuetify.breakpoint.mdAndUp ? 'bg-detail' : ''"
        >
          <v-layout justify-center class="con-detail container">
            <v-flex>
              <v-row no-gutters justfy="center">
                <!-- Image -->
                <v-col
                  order="2"
                  order-md="1"
                  cols="12"
                  sm="12"
                  md="6"
                  style="position: relative"
                >
                  <div style="position: absolute; top:0; left:0;  width: 100%">
                    <v-img
                      width="100%"
                      v-if="$vuetify.breakpoint.mdAndUp && event.cover"
                      :aspect-ratio="1"
                      :src="event.cover"
                      :alt="event.title_event"
                    />
                    <v-img
                      width="100%"
                      v-else
                      :aspect-ratio="1"
                      src="../../../assets/images/img-overlay.png"
                      :alt="event.title_event"
                    />
                  </div>
                </v-col>

                <!-- Description -->
                <v-col
                  order="1"
                  order-md="2"
                  class="pl-sm-6 pl-0"
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      order="last"
                      order-sm="first"
                      class=" mt-3 mb-3 my-sm-0"
                    >
                      <!-- category -->
                      <v-layout v-if="event.categorys.length > 0">
                        <v-flex
                          class="shrink mr-2"
                          v-for="item in event.categorys"
                          :key="item.id"
                        >
                          <ButtonOutline :text="item.name" />
                        </v-flex>
                      </v-layout>
                    </v-col>

                    <v-col cols="12">
                      <div class="my-4 font-14" style="color: #6A6A6A">
                        <!-- date -->
                        <div class="d-flex align-center mb-1">
                          <v-icon class="mr-2" color="#A40802">
                            mdi-calendar-blank
                          </v-icon>
                          {{ event.start_date }} - {{ event.end_date }}
                        </div>
                        <!-- time -->
                        <div class="d-flex align-center mb-1">
                          <v-icon class="mr-2" color="#A40802">
                            mdi-clock-outline
                          </v-icon>
                          {{ event.start_time }} - {{ event.end_time }}
                        </div>
                        <!-- location -->
                        <div class="d-flex align-center mb-1">
                          <v-icon class="mr-2" color="#A40802">
                            mdi-map-marker-outline
                          </v-icon>
                          {{ event.location }} {{ event.city }}
                        </div>
                        <!-- view -->
                        <div class="d-flex align-center mb-1">
                          <v-icon class="mr-2" color="#A40802">
                            mdi-eye-outline
                          </v-icon>
                          {{ viewFormatter(event.view) }}
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <!-- Sub Category -->
                      <v-layout wrap v-if="event.subcategorys.length > 0">
                        <v-flex
                          class="shrink text-uppercase font-weight-bold d-flex align-center font-14"
                          style="line-height: 30px;"
                          v-for="(item, i) in event.subcategorys"
                          :key="i"
                        >
                          {{ item.name }}
                          <v-divider
                            v-if="i + 1 !== event.subcategorys.length"
                            style="border-color: #000"
                            vertical
                            class="mx-2"
                          ></v-divider>
                        </v-flex>
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </div>

        <v-layout justify-center class="container">
          <v-flex>
            <v-row no-gutters justfy="center">
              <v-col order="2" order-md="1" cols="12" sm="12" md="6"> </v-col>
              <v-col
                order="1"
                order-md="2"
                class="pl-sm-6 pl-0"
                cols="12"
                sm="12"
                md="6"
              >
                <div
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="font-14 v-html"
                  :style="{ width: '100%' }"
                  v-html="event.about_event"
                ></div>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>

        <!-- Detail -->
        <div
          style="display: none"
          :class="$vuetify.breakpoint.mdAndUp ? 'bg-detail' : ''"
        >
          <v-layout justify-center class="container">
            <v-flex>
              <v-row no-gutters justfy="center">
                <!-- Image -->
                <v-col order="2" order-md="1" cols="12" sm="12" md="6">
                  <v-img
                    v-if="$vuetify.breakpoint.mdAndUp"
                    :aspect-ratio="1"
                    :src="event.cover"
                    :alt="event.title_event"
                  />
                </v-col>

                <!-- Description -->
                <v-col
                  order="1"
                  order-md="2"
                  class="pl-sm-6 pl-0"
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      order="last"
                      order-sm="first"
                      class=" mt-3 mb-3 my-sm-0"
                    >
                      <!-- category -->
                      <v-layout v-if="event.categorys.length > 0">
                        <v-flex
                          class="shrink mr-2"
                          v-for="item in event.categorys"
                          :key="item.id"
                        >
                          <ButtonOutline :text="item.name" />
                        </v-flex>
                      </v-layout>
                    </v-col>

                    <v-col cols="12">
                      <div class="my-4 font-14">
                        <!-- date -->
                        <div class="d-flex align-center mb-1">
                          <v-icon class="mr-2" color="#A40802"
                            >mdi-calendar-blank</v-icon
                          >
                          {{ event.start_date }} - {{ event.end_date }}
                        </div>
                        <!-- time -->
                        <div class="d-flex align-center mb-1">
                          <v-icon class="mr-2" color="#A40802"
                            >mdi-clock-outline</v-icon
                          >
                          {{ event.start_time }} - {{ event.end_time }}
                        </div>
                        <!-- location -->
                        <div class="d-flex align-center mb-1">
                          <v-icon class="mr-2" color="#A40802"
                            >mdi-map-marker-outline</v-icon
                          >
                          {{ event.location }} {{ event.city }}
                        </div>
                        <!-- view -->
                        <div class="d-flex align-center mb-1">
                          <v-icon class="mr-2" color="#A40802"
                            >mdi-eye-outline</v-icon
                          >
                          {{ viewFormatter(event.view) }}
                        </div>
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <!-- Sub Category -->
                      <v-layout wrap v-if="event.subcategorys.length > 0">
                        <v-flex
                          class="shrink text-uppercase font-weight-bold d-flex align-center font-14"
                          style="line-height: 30px;"
                          v-for="(item, i) in event.subcategorys"
                          :key="i"
                        >
                          {{ item.name }}
                          <v-divider
                            v-if="i + 1 !== event.subcategorys.length"
                            style="border-color: #000"
                            vertical
                            class="mx-2"
                          ></v-divider>
                        </v-flex>
                      </v-layout>
                    </v-col>
                  </v-row>

                  <div
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="mt-12 font-14 v-html"
                    style="width:100%"
                    v-html="event.about_event"
                  ></div>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </div>

        <v-img
          v-if="$vuetify.breakpoint.smAndDown"
          class="ma-0 pa-0"
          :aspect-ratio="1"
          :src="event.cover"
          :alt="event.title_event"
        />

        <div
          v-if="$vuetify.breakpoint.smAndDown"
          class="mt-6 font-14 container v-html"
          :style="{ width: '100%' }"
          v-html="event.about_event"
        ></div>
      </div>

      <!-- Detail -->
      <div
        style="display: block"
        :class="$vuetify.breakpoint.mdAndUp ? 'bg-detail' : ''"
      >
        <v-layout justify-center class="container">
          <v-flex>
            <v-row no-gutters justfy="center">
              <!-- Image -->
              <v-col order="2" order-md="1" cols="12" sm="12" md="6">
                <v-img
                  v-if="$vuetify.breakpoint.mdAndUp"
                  :aspect-ratio="1"
                  :src="event.cover"
                  :alt="event.title_event"
                />
              </v-col>

              <!-- Description -->
              <v-col
                order="1"
                order-md="2"
                class="pl-sm-6 pl-0"
                cols="12"
                sm="12"
                md="6"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    order="last"
                    order-sm="first"
                    class=" mt-3 mb-3 my-sm-0"
                  >
                    <!-- category -->
                    <v-layout v-if="event.categorys.length > 0">
                      <v-flex
                        class="shrink mr-2"
                        v-for="item in event.categorys"
                        :key="item.id"
                      >
                        <ButtonOutline :text="item.name" />
                      </v-flex>
                    </v-layout>
                  </v-col>

                  <v-col cols="12">
                    <div class="my-4 font-14">
                      <!-- date -->
                      <div class="d-flex align-center mb-1">
                        <v-icon class="mr-2" color="#A40802"
                          >mdi-calendar-blank</v-icon
                        >
                        {{ event.start_date }} - {{ event.end_date }}
                      </div>
                      <!-- time -->
                      <div class="d-flex align-center mb-1">
                        <v-icon class="mr-2" color="#A40802"
                          >mdi-clock-outline</v-icon
                        >
                        {{ event.start_time }} - {{ event.end_time }}
                      </div>
                      <!-- location -->
                      <div class="d-flex align-center mb-1">
                        <v-icon class="mr-2" color="#A40802"
                          >mdi-map-marker-outline</v-icon
                        >
                        {{ event.location }} {{ event.city }}
                      </div>
                      <!-- view -->
                      <div class="d-flex align-center mb-1">
                        <v-icon class="mr-2" color="#A40802"
                          >mdi-eye-outline</v-icon
                        >
                        {{ viewFormatter(event.view) }}
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <!-- Sub Category -->
                    <v-layout wrap v-if="event.subcategorys.length > 0">
                      <v-flex
                        class="shrink text-uppercase font-weight-bold d-flex align-center font-14"
                        style="line-height: 30px;"
                        v-for="(item, i) in event.subcategorys"
                        :key="i"
                      >
                        {{ item.name }}
                        <v-divider
                          v-if="i + 1 !== event.subcategorys.length"
                          style="border-color: #000"
                          vertical
                          class="mx-2"
                        ></v-divider>
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>

                <div
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="mt-12 font-14 v-html"
                  style="width:100%"
                  v-html="event.about_event"
                ></div>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
      </div>

      <v-img
        v-if="$vuetify.breakpoint.smAndDown"
        class="ma-0 pa-0"
        :aspect-ratio="1"
        :src="event.cover"
        :alt="event.title_event"
      />

      <div
        v-if="$vuetify.breakpoint.smAndDown"
        class="mt-6 font-14 container v-html"
        :style="{ width: '100%' }"
        v-html="event.about_event"
      ></div>
      <!-- Detail -->

      <!-- Banner Detail -->
      <v-layout class="banner-detail">
        <v-flex v-if="event.banner">
          <img height="auto" width="100%" :src="event.banner" />
        </v-flex>
      </v-layout>

      <!-- Description Event -->
      <v-layout
        wrap
        justify-center
        class="pa-0 detail-event container d-flex align-start"
      >
        <v-flex pa-3 xs12 sm12 md6 pr-md-3>
          <div class="v-html" v-html="event.description_left"></div>
        </v-flex>
        <v-flex pa-3 xs12 sm12 md6 pl-md-3>
          <div class="v-html" v-html="event.description_right"></div>
        </v-flex>
      </v-layout>

      <v-divider
        v-if="$vuetify.breakpoint.smAndUp"
        class="mt-12 container"
      ></v-divider>

      <!-- MEET TRADE PARTNERS -->
      <v-layout mt-6 justify-center>
        <v-flex
          class="partner"
          v-if="event.meet_trade_partner_events.length > 0"
        >
          <v-layout class="container mb-4 mb-sm-0 align-center">
            <v-flex
              shrink
              :style="
                $vuetify.breakpoint.smAndUp
                  ? 'font-size: 48px;'
                  : 'font-size: 36px;'
              "
              style="font-family: ChulabhornLikitDisplay;"
            >
              <div class="text-uppercase">
                {{ event.title_meet_trade_partner }}
              </div>
            </v-flex>
            <template v-if="event.meet_trade_partner_events.length > 0">
              <v-flex
                shrink
                v-for="i in $vuetify.breakpoint.smAndUp
                  ? checkLength(event.meet_trade_partner_events.length)
                  : checkLengthMobile(event.meet_trade_partner_events.length)"
                :key="i"
                class="d-flex ml-2 pt-5"
              >
                <v-btn
                  width="25"
                  height="25"
                  fab
                  depressed
                  :outlined="isActiveCarousel == i - 1 ? false : true"
                  :dark="isActiveCarousel == i - 1 ? true : false"
                  :color="isActiveCarousel == i - 1 ? '#a40802' : '#000'"
                  small
                  @click="handleChange(i - 1)"
                >
                  {{ i }}
                </v-btn>
              </v-flex>
            </template>
          </v-layout>

          <div v-if="$vuetify.breakpoint.smAndUp">
            <v-carousel
              v-model="currentIndex"
              show-arrows
              hide-delimiters
              delimiter-icon="mdi-minus"
              cycle
              hide-delimiter-background
              height="auto"
              @change="handleChange"
              v-if="event.meet_trade_partner_events.length > 0"
            >
              <template v-slot:prev="{ on, attrs }">
                <v-icon
                  style="background-color: #fff"
                  large
                  color="#E2E2E2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-chevron-left
                </v-icon>
              </template>
              <template v-slot:next="{ on, attrs }">
                <v-icon
                  style="background-color: #fff"
                  large
                  color="#E2E2E2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-chevron-right
                </v-icon>
              </template>
              <v-carousel-item
                v-for="n in checkLength(event.meet_trade_partner_events.length)"
                :key="n"
              >
                <v-layout class="container" wrap>
                  <v-flex
                    xs4
                    class="pa-3 mb-4"
                    v-for="(item, i) in event.meet_trade_partner_events.slice(
                      n * 6 - 6,
                      n * 6
                    )"
                    :key="i"
                  >
                    <v-card flat tile>
                      <v-img
                        :aspect-ratio="1.77 / 1"
                        :src="item.thumbnail"
                        :alt="item.title"
                        @click="
                          getPartnerDetail(
                            item.id,
                            item.status_form,
                            item.website,
                            item.title
                          )
                        "
                      />
                      <div class="font-weight-bold font-14 mt-3 mb-2">
                        <span
                          class="link-hover"
                          @click="
                            getPartnerDetail(
                              item.id,
                              item.status_form,
                              item.website,
                              item.title
                            )
                          "
                        >
                          {{ item.title }}
                        </span>
                      </div>
                      <div>
                        <span
                          class="event-category font-14 mr-3"
                          v-for="subcate in item.subcategory"
                          :key="subcate.id"
                        >
                          {{ subcate.name }}
                        </span>
                      </div>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-carousel-item>
            </v-carousel>
          </div>

          <div v-else>
            <v-carousel
              v-model="currentIndex"
              hide-delimiters
              delimiter-icon="mdi-minus"
              cycle
              :show-arrows="false"
              hide-delimiter-background
              height="auto"
              @change="handleChange"
              v-if="event.meet_trade_partner_events.length > 0"
            >
              <v-carousel-item
                v-for="n in checkLengthMobile(
                  event.meet_trade_partner_events.length
                )"
                :key="n"
              >
                <v-layout class="container" wrap>
                  <v-flex
                    xs12
                    v-for="(item, i) in event.meet_trade_partner_events.slice(
                      n * 3 - 3,
                      n * 3
                    )"
                    :key="i"
                  >
                    <v-card flat tile>
                      <v-layout>
                        <v-flex xs3>
                          <v-img
                            :aspect-ratio="1"
                            :src="item.thumbnail"
                            :alt="item.title"
                            @click="
                              getPartnerDetail(
                                item.id,
                                item.status_form,
                                item.website,
                                item.title
                              )
                            "
                          />
                        </v-flex>
                        <v-flex
                          xs9
                          class="pl-5 pr-3"
                          style="position: relative"
                        >
                          <div
                            class="font-weight-bold font-13 mb-2 title-meet-trade"
                          >
                            <span
                              @click="
                                getPartnerDetail(
                                  item.id,
                                  item.status_form,
                                  item.website,
                                  item.title
                                )
                              "
                            >
                              {{ item.title }}
                            </span>
                          </div>
                          <div class="font-13 ellipsis-1">
                            {{ item.description }}
                          </div>
                          <div>
                            <span
                              class="event-category font-12 mr-3"
                              v-for="subcate in item.subcategory"
                              :key="subcate.id"
                            >
                              {{ subcate.name }}
                            </span>
                          </div>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-spacer />
                        <v-flex xs9>
                          <v-divider v-if="i + 1 != 3" class="my-3" />
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-carousel-item>
            </v-carousel>
          </div>

          <div class="text-center mt-sm-0 mt-10">
            <!-- <button-outline text="View All >" /> -->
            <button-outline
              text="View All >"
              @click.native="$router.push('/partners')"
            />
          </div>
        </v-flex>
      </v-layout>

      <!-- RELATED NEWS and EVENTS -->
      <v-layout wrap mt-12 mb-4>
        <!-- NEWS -->
        <v-flex
          xs12
          sm7
          style="background: linear-gradient(180deg, #555E7D 0%, #252E4D 100%);"
        >
          <v-layout wrap class=" fill-height justify-md-space-between">
            <v-flex xs12>
              <div
                :style="$vuetify.breakpoint.smAndUp ? 'padding-left: 15%' : ''"
                class="pr-sm-10  my-12 container"
              >
                <div class="releted-1">RELATED</div>
                <div class="releted-2">NEWS</div>

                <v-layout
                  v-if="$vuetify.breakpoint.smAndUp"
                  wrap
                  mt-6
                  style="color: #fff"
                >
                  <v-flex
                    xs12
                    v-for="(item, rn) in event.related_news"
                    :key="rn"
                  >
                    <v-layout v-if="item.news">
                      <v-flex shrink>
                        <v-img
                          @click="viewDetailNews(item.news)"
                          width="121"
                          :src="item.news.cover_image"
                          aspect-ratio="1"
                          :alt="item.news.title"
                        ></v-img>
                      </v-flex>
                      <v-flex xs10 pl-6 class="font-14">
                        <v-layout class="d-flex column fill-height">
                          <v-flex>
                            <div
                              class="title-news font-weight-bold link-news mb-1"
                            >
                              <span
                                class="link-hover"
                                @click="viewDetailNews(item.news)"
                              >
                                {{ item.news.title }}
                              </span>
                            </div>
                            <div class="mb-1">
                              {{ item.news.author }}
                            </div>
                            <div class="mb-1">
                              {{ item.news.company }}
                            </div>
                          </v-flex>
                          <v-flex class="d-flex align-end">
                            <span>{{ item.news.publish_date }}</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <div v-if="item.news">
                      <v-divider
                        v-if="event.related_news.length != rn + 1 || item.news"
                        style="border-color: #fff"
                        class="my-8"
                      />
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout v-else mt-7>
                  <v-flex>
                    <v-carousel
                      :show-arrows="false"
                      hide-delimiter-background
                      hide-delimiters
                      v-model="newsSlide"
                      height="auto"
                      @change="changeNews"
                    >
                      <v-carousel-item
                        v-for="(item, i) in event.related_news"
                        :key="'mb-' + i"
                      >
                        <v-layout class="d-flex" v-if="item.news">
                          <v-flex shrink>
                            <v-img
                              @click="viewDetailNews(item.news)"
                              width="121"
                              :src="item.news.cover_image"
                              aspect-ratio="1"
                              :alt="item.news.title"
                            ></v-img>
                          </v-flex>
                          <v-flex xs8 pl-6 class="font-12" style="color: #fff">
                            <v-layout class="d-flex column fill-height">
                              <v-flex
                                class="title-news-mobile font-weight-bold"
                              >
                                <span
                                  class="link-news"
                                  @click="viewDetailNews(item.news)"
                                >
                                  {{ item.news.title }}
                                </span>
                              </v-flex>
                              <v-flex>{{ item.news.author }}</v-flex>
                              <v-flex>{{ item.news.company }}</v-flex>
                              <v-flex class="d-flex align-end">
                                {{ item.news.publish_date }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-carousel-item>
                    </v-carousel>

                    <v-layout justify-center mt-12>
                      <v-flex
                        shrink
                        v-for="i in event.related_news.length"
                        :key="i"
                        class="d-flex align-end ml-2 mb-n2"
                      >
                        <v-btn
                          width="8"
                          height="8"
                          fab
                          depressed
                          :outlined="
                            isActiveCarouselNews == i - 1 ? false : true
                          "
                          :color="
                            isActiveCarouselNews == i - 1 ? '#fff' : '#fff'
                          "
                          small
                          @click="changeNews(i - 1)"
                        >
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-flex
              xs12
              class="d-flex align-end justify-center"
              style="padding-bottom: 5rem"
            >
              <button-outline
                text="View All >"
                color="#fff"
                @click.native="$router.push('/news')"
              />
            </v-flex>
          </v-layout>
        </v-flex>

        <!-- EVENTS -->
        <v-flex xs12 sm5 style="background-color: #F9F9F9;">
          <v-layout wrap class=" fill-height justify-md-space-between">
            <v-flex>
              <div
                class="pl-sm-12 pr-sm-10 mt-12 mb-6 container"
                :style="$vuetify.breakpoint.smAndUp ? 'padding-right: 7%' : ''"
              >
                <div class="releted-event-1">RELATED</div>
                <div class="releted-event-2">EVENTS</div>

                <v-layout
                  v-if="$vuetify.breakpoint.smAndUp"
                  wrap
                  mt-6
                  style="color: #000"
                >
                  <v-flex
                    xs12
                    v-for="(item, re) in event.related_events"
                    :key="re"
                  >
                    <div v-if="item.events">
                      <div
                        class="font-14 mb-3"
                        @click="
                          item.related_event_id != getSlugId($route.params.slug)
                            ? $router.replace(
                                `/events/${item.events.title_event.replaceAll(
                                  ' ',
                                  '-'
                                )}-${item.related_event_id}`
                              )
                            : ''
                        "
                      >
                        <span class="event-title font-weight-bold">
                          {{ item.events.title_event }}
                        </span>
                      </div>
                      <div class="event-date font-12">
                        {{ item.events.start_date }} -
                        {{ item.events.end_date }}
                      </div>
                      <div class="event-time font-12">
                        {{ item.events.start_time }} -
                        {{ item.events.end_time }}
                      </div>
                      <div class="event-location mb-1 font-12">
                        {{ item.events.location }} {{ item.events.city }}
                      </div>

                      <v-divider
                        v-if="event.related_events.length != re + 1"
                        style="border-color: #E2E2E2"
                        class="my-4"
                      />
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout v-else mt-8>
                  <v-flex>
                    <v-carousel
                      :show-arrows="false"
                      hide-delimiter-background
                      hide-delimiters
                      v-model="eventSlide"
                      height="auto"
                      @change="changeEvent"
                    >
                      <v-carousel-item
                        v-for="(item, re) in event.related_events"
                        :key="'mb-event-' + re"
                      >
                        <v-layout class="d-flex align-center">
                          <v-flex class="font-14">
                            <div
                              class="font-14 mb-3"
                              @click="
                                item.related_event_id !=
                                getSlugId($route.params.slug)
                                  ? $router.replace(
                                      `/events/${item.events.title_event.replaceAll(
                                        ' ',
                                        '-'
                                      )}-${item.related_event_id}`
                                    )
                                  : ''
                              "
                            >
                              <span class="event-title font-weight-bold">
                                {{ item.events.title_event }}
                              </span>
                            </div>
                            <div class="event-date font-12">
                              {{ item.events.start_date }} -
                              {{ item.events.end_date }}
                            </div>
                            <div class="event-time font-12">
                              {{ item.events.start_time }} -
                              {{ item.events.end_time }}
                            </div>
                            <div class="event-location mb-1 font-12">
                              {{ item.events.location }} {{ item.events.city }}
                            </div>
                          </v-flex>
                        </v-layout>
                      </v-carousel-item>
                    </v-carousel>

                    <v-layout justify-center mt-6>
                      <v-flex
                        shrink
                        v-for="i in event.related_events.length"
                        :key="i"
                        class="d-flex align-end ml-2 mb-n2"
                      >
                        <v-btn
                          :width="isActiveCarouselEvent == i - 1 ? '8' : '8'"
                          :height="isActiveCarouselEvent == i - 1 ? '8' : '8'"
                          fab
                          depressed
                          :color="
                            isActiveCarouselEvent == i - 1
                              ? '#0066B3'
                              : 'rgb(0 102 179 / 30%)'
                          "
                          small
                          @click="changeEvent(i - 1)"
                        >
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-flex
              xs12
              class="d-flex align-end justify-center"
              style="padding-bottom: 5rem"
            >
              <button-outline
                text="View All >"
                @click.native="$router.push('/events')"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <!-- navbar footer -->
      <div class="navbar-footer" v-if="$vuetify.breakpoint.xs">
        <v-layout justify-space-between class="d-flex align-center">
          <v-flex
            style="background: #ffffff;opacity: 0.8;"
            v-if="event.link_register"
            xs12
            class="d-flex pa-3 justify-center register-bar align-center "
          >
            <a :href="event.link_register" target="_blank">
              <v-btn class="text-capitalize" block color="#fff" depressed tile>
                <i class="font-24 fas fa-user-plus mr-3"></i>
                <span class="font-24">Register</span>
              </v-btn>
            </a>
          </v-flex>
          <v-spacer />
          <v-flex shrink class="d-flex justify-lg-end">
            <v-btn color="#000" width="69" height="60" depressed tile>
              <i class="far fa-envelope font-30" style="color: #fff"></i>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </div>

    <div v-else>
      <DataNotFound />
    </div>
    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import ButtonOutline from "../../../components/button/ButtonOutline.vue";
import DataNotFound from "../../../components/DataNotFound.vue";
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import { domain_front } from "../../../services/Constants";

export default {
  components: { ButtonOutline, LoadingCircular, DataNotFound },
  data() {
    return {
      event: null,
      loading: false,
      currentIndex: 0,
      isActiveCarousel: 0,
      newsSlide: 0,
      isActiveCarouselNews: 0,
      eventSlide: 0,
      isActiveCarouselEvent: 0,
      test:
        '<iframe width="560" height="315" src="https://www.youtube.com/embed/CkoQkM-OVRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
      text:
        '<iframe width="560" height="315" src="https://www.youtube.com/embed/0GDAWJqBzOg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    };
  },
  metaInfo() {
    return {
      title: `${this.event ? this.event.title_event : ""} - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: this.event
            ? this.event.title_event +
              " on ThaiTradeUSA - " +
              this.event.about_event
            : "",
        },
        {
          property: "og:title",
          content: `${
            this.event ? this.event.title_event : ""
          } - ThaiTradeUSA.com`,
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: this.event
            ? this.event.title_event +
              " on ThaiTradeUSA - " +
              this.event.about_event
            : "",
        },
        { property: "og:type", content: "event" },
        {
          property: "og:url",
          content: domain_front + "events/" + this.$route.params.slug,
        },
        {
          property: "og:image",
          content: this.event ? this.event.thumbnail : "",
        },
      ],
    };
  },
  created() {
    this.getEventDetail(this.$route.params.slug);
  },
  computed: {
    onChangeEvent() {
      return this.$route.params.slug;
    },
  },
  watch: {
    onChangeEvent(id) {
      this.getEventDetail(id);
    },
  },
  methods: {
    getSlugId(slug) {
      return slug
        .split("-")
        .slice(-1)
        .pop();
    },
    getEventDetail(slug) {
      var id = this.getSlugId(slug);
      window.scrollTo(0, 0);
      this.loading = true;
      this.$service.event
        .getEventDetail(id)
        .then((res) => {
          this.loading = false;
          this.event = res.data;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleChange(val) {
      this.currentIndex = val;
      this.isActiveCarousel = val;
    },
    changeNews(val) {
      this.newsSlide = val;
      this.isActiveCarouselNews = val;
    },
    changeEvent(val) {
      this.eventSlide = val;
      this.isActiveCarouselEvent = val;
    },
    checkLength(length) {
      return length > 0
        ? (length % 6 == 0 ? 0 : 1) + Math.floor(length / 6)
        : 1;
    },
    checkLengthMobile(length) {
      return length > 0
        ? (length % 3 == 0 ? 0 : 1) + Math.floor(length / 3) > 3
          ? 4
          : (length % 3 == 0 ? 0 : 1) + Math.floor(length / 3)
        : 1;
    },
    openNews(link) {
      window.open(link.link, "_blank");
    },
    onClick(id) {
      this.$router.push(`/events/${id}`);
      window.location.reload();
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
.v-html >>> img {
  /* width: 100% !important; */
  height: auto !important;
}
.v-html >>> iframe {
  /* width: 100% !important; */
  height: auto !important;
  aspect-ratio: 1.78341013825;
}
@media only screen and (max-width: 768px) {
  .v-html >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html >>> iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1.78341013825;
  }
}
</style>

<style lang="scss">
.con-title-detail {
  .title-detail {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 100%;
    // padding-bottom: 12%;
  }

  .title-detail-mobile {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
    // padding-bottom: 10rem;
  }
}
.comment-list {
  max-height: 250px;
  overflow-y: scroll;
}

.comment-list::-webkit-scrollbar {
  width: 4px;
}
.comment-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.comment-list::-webkit-scrollbar-thumb {
  background-color: #938e94;
  outline: 4px solid #938e94;
}

.active-carousel {
  background-color: #a40802;
  color: #fff;
}
.title-news {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title-news-mobile {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title-meet-trade {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
a.link-news {
  text-decoration: none;
  color: #ffffff !important;
}
a {
  text-decoration: none;
}
.releted-1 {
  font-family: "ChulabhornLikitDisplay";
  font-size: 36px;
  line-height: 20px;
  font-weight: 500;
  color: #fff;
}
.releted-2 {
  font-family: "ChulabhornLikitDisplay";
  font-size: 46px;
  font-weight: 500;
  color: #fff;
}
.releted-event-1 {
  font-family: "ChulabhornLikitDisplay";
  font-size: 36px;
  line-height: 20px;
  font-weight: 500;
  color: #555e7d;
}
.releted-event-2 {
  font-family: "ChulabhornLikitDisplay";
  font-size: 46px;
  font-weight: 500;
  color: #000;
}
.line-dot {
  border: 1px dashed #e2e2e2;
}
.contact-title {
  font-family: "ChulabhornLikitDisplay";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
}
form .v-text-field--outlined,
.v-text-field--solo {
  border-radius: 0px;
}

.comments {
  font-family: "ChulabhornLikitDisplay";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
}

.register-bar {
  font-family: "ChulabhornLikitDisplay";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 85.39%;
}
.navbar-footer {
  position: fixed;
  bottom: 0;
  z-index: 2;

  width: 100%;
}
.contact-detail {
  font-size: 13px;
  line-height: 25px;
  letter-spacing: 0.02em;
}
.banner-detail {
  margin: 4rem 0;
}

@media only screen and (min-width: 1024px) {
  .partner {
    .v-window__prev,
    .v-window__next {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      position: absolute;
      margin: 0 5rem;
      top: 45% !important;
      z-index: 1;
    }
  }
  .detail-event {
    img {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    iframe {
      width: 100%;
      display: block;
      min-height: 100px;
      // height: 100px;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .partner {
    .v-window__prev,
    .v-window__next {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      position: absolute;
      margin: 0 2rem;
      top: 45% !important;
      z-index: 1;
    }
  }
}
@media only screen and (max-width: 425px) {
  .partner {
    .v-window__prev,
    .v-window__next {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      position: absolute;
      margin: 0;
      top: 45% !important;
      z-index: 1;
    }
  }
}
@media only screen and (max-width: 768px) {
  .partner {
    .v-window__prev,
    .v-window__next {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      position: absolute;
      margin: 0 -10px;
      top: 45% !important;
      z-index: 1;
    }
  }
  .banner-detail {
    margin: 1.5rem 0;
  }
  .detail-event {
    img {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    iframe {
      width: 100%;
      height: 450px;
      display: flex;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 600px) {
  .contact-title {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
  }
  .detail-event {
    img {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    iframe {
      width: 100%;
      display: flex;
      height: 220px;
      justify-content: center;
    }
  }
}
</style>
