<template>
  <v-app @mouseleave.native="mouseLeave">
    <router-view />

    <!-- Dialog Home -->
    <v-dialog
      :overlay-opacity="0.8"
      overlay-color="#000"
      v-model="dialog"
      width="393"
    >
      <div class="d-flex justify-end mb-2">
        <v-btn
          max-width="15"
          max-height="15"
          @click="onHandleCloseModal"
          fab
          small
          class=""
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-layout>
        <v-flex xs3 style="background-color: #A40802; height: 5px"></v-flex>
        <v-flex xs3 style="background-color: #0066B3; height: 5px"></v-flex>
        <v-flex xs6 style="background-color: #E2E2E2; height: 5px"></v-flex>
      </v-layout>

      <v-card tile class="pb-12 px-12 pt-6">
        <!-- Question Home -->
        <v-layout
          wrap
          justify-center
          v-if="$route.name === 'Home' && questionHome"
        >
          <v-flex>
            <!-- Question -->
            <div class="font-cl mt-3 mb-4" v-if="questionHome">
              <div v-html="questionHome.qeustion"></div>
            </div>

            <!-- Answer -->
            <v-radio-group v-model="answerHome" row>
              <v-layout wrap>
                <v-flex xs6 class="mb-3" v-if="questionHome.answer1">
                  <v-radio :value="questionHome.answer1">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionHome.answer1 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>

                <v-flex xs6 class="mb-3" v-if="questionHome.answer2">
                  <v-radio :value="questionHome.answer2">
                    <template v-slot:label>
                      <span class="font-12 black--text">
                        {{ questionHome.answer2 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>

                <v-flex xs6 class="mb-3" v-if="questionHome.answer3">
                  <v-radio :value="questionHome.answer3">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionHome.answer3 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>
                <v-flex xs6 class="mb-3" v-if="questionHome.answer4">
                  <v-radio :value="questionHome.answer4">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionHome.answer4 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>
                <v-flex xs6 class="mb-3" v-if="questionHome.answer5">
                  <v-radio :value="questionHome.answer5">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionHome.answer5 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>
              </v-layout>
            </v-radio-group>
          </v-flex>
        </v-layout>

        <!-- Question EventDetail -->
        <v-layout
          wrap
          justify-center
          v-if="$route.name === 'EventDetail' && questionEventDetail"
        >
          <v-flex xs10>
            <!-- Question -->
            <div class="font-cl mt-3 mb-4" v-if="questionEventDetail">
              <div v-html="questionEventDetail.qeustion"></div>
            </div>

            <!-- Answer -->
            <v-radio-group v-model="answerEventDetail" row>
              <v-layout wrap>
                <v-flex xs6 class="mb-3">
                  <v-radio :value="questionEventDetail.answer1">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionEventDetail.answer1 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>

                <v-flex xs6 class="mb-3">
                  <v-radio :value="questionEventDetail.answer2">
                    <template v-slot:label>
                      <span class="font-12 black--text">
                        {{ questionEventDetail.answer2 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>

                <v-flex xs6 class="mb-3">
                  <v-radio :value="questionEventDetail.answer3">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionEventDetail.answer3 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>
                <v-flex xs6 class="mb-3">
                  <v-radio :value="questionEventDetail.answer4">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionEventDetail.answer4 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>
                <v-flex xs6 class="mb-3">
                  <v-radio :value="questionEventDetail.answer5">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionEventDetail.answer5 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>
              </v-layout>
            </v-radio-group>
          </v-flex>
        </v-layout>

        <!-- Question NewsDetail -->
        <v-layout
          wrap
          justify-center
          v-if="$route.name === 'NewsDetail' && questionNewsDetail"
        >
          <v-flex>
            <!-- Question -->
            <div class="font-cl mt-3 mb-4" v-if="questionNewsDetail">
              <div v-html="questionNewsDetail.qeustion"></div>
            </div>

            <!-- Answer -->
            <v-radio-group v-model="answerNewsDetail" row>
              <v-layout wrap>
                <v-flex xs6 class="mb-3" v-if="questionNewsDetail.answer1">
                  <v-radio :value="questionNewsDetail.answer1">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionNewsDetail.answer1 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>

                <v-flex xs6 class="mb-3" v-if="questionNewsDetail.answer2">
                  <v-radio :value="questionNewsDetail.answer2">
                    <template v-slot:label>
                      <span class="font-12 black--text">
                        {{ questionNewsDetail.answer2 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>

                <v-flex xs6 class="mb-3" v-if="questionNewsDetail.answer3">
                  <v-radio :value="questionNewsDetail.answer3">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionNewsDetail.answer3 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>
                <v-flex xs6 class="mb-3" v-if="questionNewsDetail.answer4">
                  <v-radio :value="questionNewsDetail.answer4">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionNewsDetail.answer4 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>
                <v-flex xs6 class="mb-3" v-if="questionNewsDetail.answer5">
                  <v-radio :value="questionNewsDetail.answer5">
                    <template v-slot:label>
                      <span class="font-12  black--text">
                        {{ questionNewsDetail.answer5 }}
                      </span>
                    </template>
                  </v-radio>
                </v-flex>
              </v-layout>
            </v-radio-group>
          </v-flex>
        </v-layout>

        <div class="d-flex justify-center ">
          <v-btn
            block
            color="#0066B3"
            dark
            tile
            depressed
            @click="checkAnswer(typeQuestion)"
            class="text-center font-cl font-13 font-weight-bold text-capitalize"
          >
            Send
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- ref: https://www.npmjs.com/package/vue-cookie-law -->
    <cookie-law
      v-if="acceptCookie"
      class="cookie-card"
      theme="blood-orange"
      storageType="cookies"
      ref="cookieBanner"
    >
      <div slot-scope="props">
        <p class="mt-2" v-html="detailCookies.detail"></p>
        <div class="d-flex justify-end">
          <v-btn
            dark
            tile
            depressed
            color="#fff"
            class=" text-capitalize"
            @click="props.accept"
            @click.native="acceptCookie"
          >
            <span style="color: #0066B3">
              I Agree
            </span>
          </v-btn>
        </div>
      </div>
    </cookie-law>

    <loading-circular :loading="loading" />
  </v-app>
</template>

<script>
import CookieLaw from "vue-cookie-law";
import { setCookie } from "tiny-cookie";

import { mapState } from "vuex";
import LoadingCircular from "./components/loading/LoadingCircular.vue";
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      dialogHome: false,
      dialogEventDetail: false,
      dialogNewsDetail: false,

      answerHome: "",
      answerEventDetail: "",
      answerNewsDetail: "",
    };
  },
  components: { CookieLaw, LoadingCircular },
  computed: {
    typeQuestion() {
      switch (this.$route.name) {
        case "Home":
          return "Home";
        case "EventDetail":
          return "EventDetail";
        case "NewsDetail":
          return "NewsDetail";
        default:
          return false;
      }
    },
    ...mapState({
      // Cookies Token
      cookie_accepted: (state) => state.common.cookie_accepted,
      cookie_token: (state) => state.common.cookie_token,

      // modal cookies detail
      detailCookies: (state) => state.common.detailCookies,

      // Question list
      questionHome: (state) => state.common.questionHome,
      questionEventDetail: (state) => state.common.questionEventDetail,
      questionNewsDetail: (state) => state.common.questionNewsDetail,

      // Status todo Question
      isTodoQuestionHome: (state) => state.common.isTodoQuestionHome,
      isTodoQuestionEventDetail: (state) =>
        state.common.isTodoQuestionEventDetail,
      isTodoQuestionNewsDetail: (state) =>
        state.common.isTodoQuestionNewsDetail,
    }),
    areCookiesAccepted() {
      return this.$refs.cookieBanner.isAccepted();
    },
  },

  created() {
    this.getQuestion();
  },
  mounted() {
    if (this.areCookiesAccepted !== true) {
      // ถ้า cookie Banner โดน clear แล้ว ให้ clear cookie ที่อยู่ใน local storage ด้วย
      this.$store.commit("common/CLEAR_COOKIE");
    }
  },
  methods: {
    getQuestion() {
      this.$store.dispatch("common/getQuestion");
    },
    // save cookies to db and set to local storage
    acceptCookie() {
      this.$store.dispatch("common/setCookie");

      // set expires cookies 6 month after accepted
      const now = new Date();
      now.setMonth(now.getMonth() + 6);
      setCookie("cookie:accepted", true, { expires: now.toGMTString() });
    },
    mouseLeave() {
      // check ว่ากด accept cookies หรือยัง
      if (this.cookie_accepted) {
        // check question list จาก router => this.$route.name = ?
        switch (this.$route.name) {
          case "Home":
            if (this.questionHome) {
              // เช็คว่ามีคำถามไหม => questionHome และเช็คว่าได้ตอบคำถาม(isTodoQuestionHome) แล้วหรือยัง
              if (!this.isTodoQuestionHome) {
                console.log("กรุณาตอบคำถาม Home");
                this.dialog = true;
              }
              // กรณีเคยตอบแล้ว แต่คำถามเปลี่ยนใหม่
              else if (
                this.isTodoQuestionHome !== this.questionHome.updated_at
              ) {
                console.log("ตอบใหม่ Home");
                this.dialog = true;
              } else {
                console.log("ทำแล้ว Home");
              }
            }

            break;

          case "EventDetail":
            if (this.questionEventDetail) {
              // เช็คว่ามีคำถามไหม => questionEventDetail และเช็คว่าได้ตอบคำถาม(isTodoQuestionEventDetail) แล้วหรือยัง
              if (!this.isTodoQuestionEventDetail) {
                // console.log("กรุณาตอบคำถาม EventDetail");
                this.dialog = true;
              }
              // กรณีเคยตอบแล้ว แต่คำถามเปลี่ยนใหม่
              else if (
                this.isTodoQuestionEventDetail !==
                this.questionEventDetail.updated_at
              ) {
                // console.log("ตอบใหม่ EventDetail");
                this.dialog = true;
              } else {
                // console.log("ทำแล้ว EventDetail");
              }
            }

            break;

          case "NewsDetail":
            if (this.questionNewsDetail) {
              // เช็คว่ามีคำถามไหม => questionNewsDetail และเช็คว่าได้ตอบคำถาม(isTodoQuestionNewsDetail) แล้วหรือยัง
              if (!this.isTodoQuestionNewsDetail) {
                // console.log("กรุณาตอบคำถาม NewsDetail");
                this.dialog = true;
              }
              // กรณีเคยตอบแล้ว แต่คำถามเปลี่ยนใหม่
              else if (
                this.isTodoQuestionNewsDetail !==
                this.questionNewsDetail.updated_at
              ) {
                // console.log("ตอบใหม่ NewsDetail");
                this.dialog = true;
              } else {
                // console.log("ทำแล้ว NewsDetail");
              }
            }

            break;

          default:
            // console.log("path นี้ไม่มีคำถาม");
            false;
        }
      } else {
        // console.log("ยังไม่ได้ accept cookies");
      }
    },
    onHandleCloseModal() {
      switch (this.$route.name) {
        case "Home":
          this.$store.commit("common/SET_ANSWER", {
            type: this.$route.name,
            question_date: this.questionHome.updated_at,
          });
          break;
        case "EventDetail":
          this.$store.commit("common/SET_ANSWER", {
            type: this.$route.name,
            question_date: this.questionEventDetail.updated_at,
          });
          break;
        case "NewsDetail":
          this.$store.commit("common/SET_ANSWER", {
            type: this.$route.name,
            question_date: this.questionNewsDetail.updated_at,
          });
          break;
        default:
          return false;
      }
      this.dialog = false;
    },
    saveAnswer(data) {
      this.$store.dispatch("common/saveAnswer", data);
      this.dialog = false;
    },
    checkAnswer(type) {
      if (type === "Home") {
        if (!this.answerHome) {
          this.$swal({
            icon: "warning",
            title: "Please Selected",
            confirmButtonColor: "#0066B3",
          });
        } else {
          this.saveAnswer({
            type: type,
            question_id: this.questionHome.id,
            question_name: this.questionHome.qeustion,
            question_date: this.questionHome.updated_at,
            answer: this.answerHome,
          });
        }
      }
      if (type === "EventDetail") {
        if (!this.answerEventDetail) {
          this.$swal({
            icon: "warning",
            title: "Please Selected",
            confirmButtonColor: "#0066B3",
          });
        } else {
          this.saveAnswer({
            type: type,
            question_id: this.questionEventDetail.id,
            question_name: this.questionEventDetail.qeustion,
            question_date: this.questionEventDetail.updated_at,
            answer: this.answerEventDetail,
          });
        }
      }
      if (type === "NewsDetail") {
        if (!this.answerNewsDetail) {
          this.$swal({
            icon: "warning",
            title: "Please Selected",
            confirmButtonColor: "#0066B3",
          });
        } else {
          this.saveAnswer({
            type: type,
            question_id: this.questionNewsDetail.id,
            question_name: this.questionNewsDetail.qeustion,
            question_date: this.questionNewsDetail.updated_at,
            answer: this.answerNewsDetail,
          });
        }
      }
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@100;300&display=swap");
@font-face {
  font-family: "ChulabhornLikitDisplay";
  src: local("ChulabhornLikitDisplay"),
    url("./assets/fonts/Chulabhorn_Likit/desktop/ChulabhornLikitDisplay-Medium.otf")
      format("truetype");
}
#app {
  font-family: "Prompt", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<style lang="css">
.Cookie a {
  color: #fff !important;
  text-decoration: underline !important;
}
.Cookie--bottom {
  bottom: 50px !important;
  left: 0;
  right: 0;
  margin: auto;
  background: #0066b3 !important;
  opacity: 0.9;
}
.Cookie--blood-orange .Cookie__button {
  background: #fff;
  padding: 0.625em 3.125em !important;
  color: #000;
  border-radius: 0;
  border: 0;
  font-size: 1em !important;
}
.Cookie--blood-orange .Cookie__button:hover {
  background: rgb(225, 225, 225) !important;
}
.Cookie {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 9999;
  width: 55% !important;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
  padding: 2.5rem !important;
}

@media only screen and (max-width: 768px) {
  .Cookie {
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 9999;
    width: 90% !important;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
    padding: 2.5rem !important;
  }
  .Cookie--bottom {
    bottom: 7rem !important;
  }
}
.Cookie > * {
  margin: 0.9375rem 0 !important;
  align-self: end !important;
}
@media only screen and (max-width: 1024px) {
  .Cookie {
    width: 80% !important;
  }
  .Cookie--bottom {
    bottom: 15px !important;
  }
}
@media only screen and (max-width: 425px) {
  .Cookie {
    width: 239px !important;
    /* width: 60% !important; */
    padding: 1rem !important;
    font-size: 10px !important;
  }
  .Cookie--bottom {
    bottom: 7rem !important;
  }
}

.v-dialog {
  box-shadow: unset !important;
}
</style>
