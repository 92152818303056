<template>
  <div>
    <label v-if="label" :for="label" class="select-label">
      {{ label }}
      <span style="color: red" v-if="required">
        *
      </span>
    </label>
    <v-autocomplete
      :rules="required ? [(v) => !!v || 'Select is required'] : []"
      :value="model"
      :items="items"
      outlined
      :item-text="itemText"
      :item-value="itemValue"
      dense
      :placeholder="placeholder"
      :label="placeholder"
      @input="$emit('updateData', $event)"
      :return-object="returnObject"
      hide-details
      :required="required"
      :multiple="multiple"
      :chips="multiple ? true : false"
      deletable-chips
      :small-chips="multiple ? true : false"
    >
      <template v-slot:item="data">
        <div class="font-12" v-if="itemText === 'business_type_name'">
          <span class="font-weight-bold">
            {{ textBold(data.item[itemText]) }}
          </span>
          <span v-if="textNormal(data.item[itemText])">
            - {{ textNormal(data.item[itemText]) }}</span
          >
        </div>
        <div class="font-12 " v-else-if="itemText === 'industry_type_name'">
          <span class="font-weight-bold">
            {{ textBold(data.item[itemText]) }}
          </span>
          <span v-if="textNormal(data.item[itemText])">
            - {{ textNormal(data.item[itemText]) }}</span
          >
        </div>

        <div v-else class="font-12">
          {{ data.item[itemText] }}
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    label: null,
    model: null,
    items: Array,
    itemText: String,
    itemValue: String,
    returnObject: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "---  Select  ---",
    },
  },
  methods: {
    textBold(text) {
      const text1 = text.split(" - ");
      return text1[0];
    },
    textNormal(text) {
      const text1 = text.split(" - ");
      return text1[1];
    },
  },
};
</script>

<style>
.select-label {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 26px;
  color: #424040;
}
.v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  border-radius: 0 !important;
}
</style>
