// import authService from "../authService";
import Service from "../Service";

const event = {
  getHome() {
    return Service.get(`/api/home`);
  },
};

export default event;
