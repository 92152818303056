<template>
  <div>
    <div v-if="banner">
      <BannerComponent :data="banner" />
    </div>

    <v-container class="mt-0 mt-md-16">
      <v-row class="section-1 mb-md-6 ">
        <v-col cols="12" sm="12" md="9" order="2" order-md="1">
          <v-card flat tile v-if="news_highlight">
            <v-img
              @click="viewDetailNews(news_highlight)"
              class="image-hover"
              lazy-src="../../../assets/images/default/default_16_9.png"
              :aspect-ratio="16 / 9"
              :contain="news_highlight.cover_image ? false : true"
              :src="news_highlight.cover_image"
              :alt="news_highlight.title"
            ></v-img>
            <div class="card-detail">
              <div class="card-date-news my-1">
                {{ news_highlight.publish_date }}
              </div>
              <div class="card-title">
                <span
                  class="link-hover font-weight-bold"
                  @click="viewDetailNews(news_highlight)"
                >
                  {{ news_highlight.title }}
                </span>
              </div>
              <div
                class="card-des"
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'ellipsis-2' : 'ellipsis-4'
                "
              >
                {{ news_highlight.description }}
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="3"
          order="1"
          order-md="2"
          class="years-list"
        >
          <div v-if="years">
            <!-- Desktop size -->
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <v-list v-if="years.length > 0" class="py-0">
                <v-list-group
                  color="#F9F9F9"
                  class="mb-1 unactive-year"
                  v-for="(item_year, y) in years"
                  :key="y"
                  no-action
                  :value="true"
                  active-class="active-year"
                >
                  <template v-slot:activator>
                    <v-list-item-content class="title-year">
                      <v-list-item-title v-text="item_year"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item
                    v-for="(item_month, m) in months"
                    :key="m"
                    class="mb-1 bg-sub-list"
                    :class="
                      year && item_year == year && month == item_month
                        ? 'active-month'
                        : ''
                    "
                    @click="onClickFilter(item_year, item_month)"
                  >
                    <v-list-item-content class="title-month mb-1">
                      <v-list-item-title
                        v-text="item_month"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </div>
            <!-- Mobile size -->
            <div v-else class="filter-mobile mt-4">
              <v-row>
                <v-col cols="5">
                  <v-select
                    menu-props="offsetY"
                    placeholder="Year"
                    v-model="year"
                    hide-details
                    clearable
                    :items="years"
                    outlined
                    @change="clear()"
                  ></v-select>
                </v-col>
                <v-col cols="7">
                  <v-select
                    menu-props="offsetY"
                    placeholder="Month"
                    v-model="month"
                    hide-details
                    clearable
                    :items="year ? months : []"
                    outlined
                    @change="onClickFilter(year, month)"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- News -->
      <v-row v-if="news.length > 0">
        <v-col cols="12" sm="12" md="4" v-for="(item, i) in news" :key="i">
          <!-- v-if="$vuetify.breakpoint.mdAndUp" -->
          <v-card flat tile class="mb-2">
            <v-row>
              <v-col md="12" sm="3" cols="4" class="py-0">
                <v-img
                  @click="viewDetailNews(item)"
                  class="image-hover"
                  lazy-src="../../../assets/images/default/default_4_3.png"
                  :aspect-ratio="$vuetify.breakpoint.mdAndUp ? 4 / 3 : 1"
                  :src="item.cover_image"
                  :alt="item.title"
                  :contain="item.cover_image ? false : true"
                >
                </v-img>
              </v-col>
              <v-col md="12" sm="9" cols="8" class="pt-0">
                <div class="card-detail pb-md-3 py-0">
                  <div class="card-date-news my-1">
                    {{ item.publish_date }}
                  </div>
                  <div class="card-title ellipsis-1">
                    <span
                      class="link-hover font-weight-bold font-14"
                      @click="viewDetailNews(item)"
                    >
                      {{ item.title }}
                    </span>
                  </div>
                  <div
                    class="card-des"
                    :class="
                      $vuetify.breakpoint.sm ? 'ellipsis-4' : 'ellipsis-2'
                    "
                  >
                    {{ item.description }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Button Home Page -->
    <div v-if="news.length > 0" class="my-16">
      <v-layout
        justify-center
        mt-6
        mb-12
        v-if="paginate.last_page != paginate.current_page"
      >
        <v-flex shrink>
          <btn-more :loading="loadingBtn" @on-click="onClickMore()" />
        </v-flex>
      </v-layout>
    </div>

    <div v-if="new_project" class="bg-new-project">
      <NewProject :data="new_project" />
    </div>

    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import BannerComponent from "../../../components/banner/BannerComponent.vue";
import BtnMore from "../../../components/button/BtnMore.vue";
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import NewProject from "../../../components/new-project/NewProject.vue";
import Service from "../../../services/";
import { domain_front } from "../../../services/Constants";

export default {
  components: { BannerComponent, NewProject, BtnMore, LoadingCircular },
  data() {
    return {
      banner: "",
      news: [],
      news_highlight: "",
      new_project: null,
      years: null,
      year: "",
      months: null,
      month: "",
      paginate: {
        current_page: 1,
        last_page: 1,
      },
      loading: false,
      loadingBtn: false,
    };
  },
  metaInfo() {
    return {
      title: `News - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: "News on Thai Trade USA",
        },
        {
          property: "og:title",
          content: "News - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: "News on Thai Trade USA",
        },
        { property: "og:type", content: "News" },
        {
          property: "og:url",
          content: domain_front + `news`,
        },
        {
          property: "og:image",
          content: this.banner ? this.banner.path_mobile : "",
        },
      ],
    };
  },
  async created() {
    window.scrollTo(0, 0);
    this.loading = true;
    await Promise.all([
      this.getNewsData(),
      this.getNews(this.paginate.current_page),
    ])
      .then((res) => {
        this.loading = false;
      })
      .catch((error) => {
        console.log("error", error);
        this.loading = false;
      });
  },
  methods: {
    async getNews(page = 1) {
      const { data } = await Service.news.getNews({
        page: page,
        year: this.year,
        month: this.month,
      });
      this.news = data.data;
      this.paginate = {
        current_page: data.current_page,
        last_page: data.last_page,
      };
      return true;
    },
    async getNewsData() {
      const { data } = await Service.news.getNewsData();
      const { banner, news_highlight, new_project, year_list } = data;
      this.banner = banner;
      this.news_highlight = news_highlight;
      this.new_project = new_project;
      this.years = year_list.years.reverse();
      this.months = year_list.months;
      return true;
    },
    onClickFilter(year, month) {
      this.year = year;
      this.month = month;
      this.loading = true;
      this.getNews(1).then((res) => {
        this.loading = false;
      });
    },
    onClickMore() {
      this.loadingBtn = true;
      Service.news
        .getNews({
          page: this.paginate.current_page + 1,
          year: this.year,
          month: this.month,
        })
        .then((res) => {
          const { data } = res;
          this.news = [...this.news, ...data.data];
          this.paginate = {
            current_page: data.current_page,
            last_page: data.last_page,
          };
          this.loadingBtn = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingBtn = false;
        });
    },
    onHandleNews(item) {
      this.$router.push(`news/${item.title.replaceAll(" ", "-")}-${item.id}`);
    },
    clear() {
      if (this.month) {
        this.month = "";
        this.year = "";
        this.loading = true;
        this.getNews(1).then((res) => {
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style>
.filter-mobile .v-select {
  border-radius: unset;
}
.years-list {
  max-height: 580px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #938e94;
}
.years-list::-webkit-scrollbar {
  width: 3px;
}
.years-list::-webkit-scrollbar-track {
  background: #e2e2e2;
}
.years-list::-webkit-scrollbar-thumb {
  background-color: #938e94;
  border-radius: 6px;
  border: 3px solid #938e94;
}
.v-list-group__header.v-list-item.v-list-item--link.theme--light {
  background-color: #f9f9f9;
}
.bg-sub-list {
  background-color: #f9f9f9;
}
.bg-sub-list:hover {
  cursor: pointer;
  background: #e8e8f2;
}
.active-year {
  background: #0066b3 !important;
  color: #fff !important;
}
.active-month {
  background: #e8e8f2 !important;
}
.title-year {
  font-family: Chulabhorn Likit Text;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.title-month {
  font-family: Chulabhorn Likit Text;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}
.bg-new-project {
  background: #f9f9f9;
  padding: 3rem 0;
}
</style>
