// import authService from "../authService";
import Service from "../Service";

const partner = {
  getDataPartner() {
    return Service.get(`/api/data-partner`);
  },
  getPartnerById(id) {
    return Service.get(`/api/partners/${id}`);
  },
  getPartners(data) {
    return Service.get(
      `/api/partners?page=${data.page}&category_id=${
        data.category_id
      }&subcategory_id=${data.subcategory_id}&limit=${
        data.limit ? data.limit : ""
      }&search=${data.search ? data.search : ""}`
    );
  },
};

export default partner;
