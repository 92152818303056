<template>
  <div>
    <div v-if="category">
      <v-img
        v-if="category.banner_desktop"
        width="100%"
        :height="$vuetify.breakpoint.smAndUp ? '238' : '211'"
        :src="
          $vuetify.breakpoint.smAndUp
            ? category.banner_desktop
            : category.banner_mobile
            ? category.banner_mobile
            : category.banner_desktop
        "
        cover
        :alt="category.name"
        lazy-src="../../../assets/images/default/default_1_1.png"
      >
      </v-img>
      <v-img
        v-else
        width="100%"
        :height="$vuetify.breakpoint.smAndUp ? '238' : 'auto'"
        :src="category.thumbnail"
        cover
        :alt="category.name"
        lazy-src="../../../assets/images/default/default_1_1.png"
      >
        <div class="container fill-height d-flex align-center topic-banner">
          {{ category.name }}
        </div>
      </v-img>
    </div>

    <v-container class="mt-md-12 mt-3" style="margin-bottom: 5rem">
      <v-row align="center" class="mb-6 mb-sm-0">
        <v-col
          cols="12"
          sm="6"
          class="title-subcate pb-2 pb-sm-3"
          v-if="subcategory_name"
        >
          {{ subcategory_name }}
        </v-col>
        <v-col
          class="pt-0 pt-sm-3"
          cols="12"
          sm="6"
          v-if="subcategorys.length > 1"
        >
          <v-row align="center">
            <v-col cols="12" sm="auto" class="mr-4 pb-1 pb-sm-3"
              >Subcategory</v-col
            >
            <v-col class="pt-0 pt-sm-3">
              <v-autocomplete
                v-model="subcategory_id"
                :items="subcategorys"
                outlined
                item-text="name"
                item-value="id"
                dense
                hide-details
                return-object
                @change="onChangeSubcate"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Meet trade partner list -->
      <div v-if="partners.length > 0" class="mt-6">
        <v-card
          v-for="partner in partners"
          :key="partner.id"
          color="#F9F9F9"
          class="mb-sm-4 mb-2 pa-4"
          flat
          tile
        >
          <v-row>
            <v-col cols="5" sm="2">
              <v-img
                class="meet"
                width="100%"
                lazy-src="../../../assets/images/default/default_1_1.png"
                :src="partner.thumbnail"
                aspect-ratio="1"
                :alt="partner.title"
              >
              </v-img>
            </v-col>
            <v-col cols="7" sm="10" class="pl-1 pl-sm-3">
              <div
                class="font-main font-weight-bold font-14 mb-sm-2 mb-1 link-hover"
                :class="$vuetify.breakpoint.xs ? 'ellipsis-1' : ''"
                :style="
                  $vuetify.breakpoint.xs
                    ? { color: '#0066B3' }
                    : { color: '#0066B3' }
                "
                @click="
                  getPartnerDetail(
                    partner.id,
                    partner.status_form,
                    partner.website,
                    partner.title
                  )
                "
              >
                {{ partner.title }}
              </div>
              <div
                class="font-main font-14"
                :class="$vuetify.breakpoint.xs ? 'ellipsis-2' : ''"
              >
                {{ partner.description }}
              </div>
              <div class="font-main font-14 mt-0 mt-sm-2">
                <span
                  class="partner-subcategory mr-3"
                  v-for="meet_subcate in partner.meet_trade_partner_subcategorys"
                  :key="meet_subcate.id"
                >
                  {{ meet_subcate.subcategory.name }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <div class="d-flex justify-end mt-12 mt-sm-10 mb-10">
          <v-pagination
            color="#555E7D"
            v-model="paginate.current_page"
            :length="paginate.last_page"
            :total-visible="7"
            @input="onClickPaginate"
          ></v-pagination>
        </div>
      </div>
      <div v-if="no_data" class="text-center mt-12">
        -- No data --
      </div>
    </v-container>

    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import { domain_front } from "../../../services/Constants";

export default {
  components: { LoadingCircular },
  data() {
    return {
      no_data: false,
      loading: false,
      category: [],
      category_id: "",
      subcategory_id: "",
      subcategorys: [
        { id: 0, name: "All", category_id: this.$route.query.category },
      ],
      subcategory_name: "",
      partners: [],
      paginate: {
        current_page: 1,
        last_page: 1,
      },
    };
  },
  metaInfo() {
    return {
      title: `Partner List - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: "Partner List on Thai Trade USA",
        },
        {
          property: "og:title",
          content: "Partner List - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: "Partner List on Thai Trade USA",
        },
        { property: "og:type", content: "Partner List" },
        {
          property: "og:url",
          content:
            domain_front +
            `partner-list?category=${this.$route.query.category}`,
        },
        {
          property: "og:image",
          content: this.category ? this.category.banner_mobile : "",
        },
      ],
    };
  },
  async created() {
    console.log(this.$route.query.category);
    window.scrollTo(0, 0);

    if (
      this.$route.query.category == undefined ||
      this.$route.query.category == ""
    ) {
      this.$router.go(-1);
    }
    this.category_id = this.$route.query.category;
    this.subcategory_id = this.$route.query.subcategory
      ? this.$route.query.subcategory
      : 0;
    this.loading = true;
    await Promise.all([
      this.getCategoryById(),
      this.getPartners(
        this.paginate.current_page,
        this.category_id,
        this.subcategory_id
      ),
    ])
      .then((res) => {
        this.loading = false;
      })
      .catch((error) => {
        console.log("error", error);
        this.loading = false;
      });
  },
  computed: {
    routeQuery() {
      return this.$route.query;
    },
  },
  watch: {
    partners(val) {
      if (val.length == 0) {
        this.no_data = true;
      } else {
        this.no_data = false;
      }
    },
    routeQuery() {
      if (this.category_id != this.routeQuery.category) {
        this.category_id = this.routeQuery.category;
        this.loading = true;
        // get Category
        this.getCategoryById();
        // get meet trade partner
        this.getPartners(1, this.category_id, this.subcategory_id)
          .then((res) => {
            this.partners = res.data;
            this.paginate = {
              current_page: res.current_page,
              last_page: res.last_page,
            };
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.error(err);
          });
      }
    },
  },
  methods: {
    getCategoryById() {
      // this.loading = true;
      this.$service.category
        .getCategoryById(this.$route.query.category)
        .then((res) => {
          this.category = res.data;
          if (res.data && res.data.subcategorys.length > 0) {
            this.subcategorys = [
              { id: 0, name: "All", category_id: this.$route.query.category },
            ];
            this.subcategorys = [
              ...this.subcategorys,
              ...res.data.subcategorys,
            ];
          }
          // Set subcategory_name and subcategory_id
          const subcategorys = this.subcategorys.find(
            (element) => element.id == this.subcategory_id
          );
          this.subcategory_name = subcategorys ? subcategorys.name : "All";
          this.subcategory_id = subcategorys ? subcategorys.id : 0;

          return true;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          throw err;
        });
    },
    async getPartners(page = 1, category_id = "", subcategory_id = "") {
      const res = await this.$service.partner.getPartners({
        page: page,
        category_id: category_id,
        subcategory_id: subcategory_id == 0 ? "" : subcategory_id,
      });
      if (res.status == 200) {
        const data = res.data;
        this.partners = data.data;
        this.paginate = {
          current_page: data.current_page,
          last_page: data.last_page,
        };
        return data;
      } else {
        return false;
      }
    },
    onChangeSubcate(event) {
      if (event.id == 0) {
        this.$router.replace(`partner-list?category=${this.category_id}`);
      } else {
        this.$router.replace(
          `partner-list?category=${this.category_id}&subcategory=${event.id}`
        );
      }

      this.loading = true;
      this.subcategory_name = event.name;
      this.subcategory_id = event.id;
      // get meet trade partner
      this.getPartners(1, this.category_id, this.subcategory_id)
        .then((res) => {
          this.partners = res.data;
          this.paginate = {
            current_page: res.current_page,
            last_page: res.last_page,
          };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },
    onClickPaginate(page) {
      this.loading = true;
      this.getPartners(page, this.category_id, this.subcategory_id)
        .then((res) => {
          this.partners = res.data;
          this.paginate = {
            current_page: res.current_page,
            last_page: res.last_page,
          };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },
  },
};
</script>

<style>
.partner-subcategory {
  padding-bottom: 1px;
  border-bottom: 2px solid #000;
  line-height: 30px;
  font-size: clamp(12px, 1.5vw, 14px);
}
</style>
