<template>
  <div
    class="spanish"
    :class="$vuetify.breakpoint.xs ? 'v-html-mobile' : 'v-html-desktop'"
  >
    <v-layout
      justify-start
      class="pt-3 pt-am-6 pb-3"
      style="background: #f9f9f9;"
    >
      <v-flex>
        <v-container>
          <v-layout
            justify-center
            :class="
              $vuetify.breakpoint.smAndUp
                ? 'title-spanish'
                : 'title-mobile-spanish'
            "
          >
            <v-flex>
              {{ data.title }}
            </v-flex>
          </v-layout>

          <v-layout class="title-desc mt-3 mt-sm-6">
            <v-flex xs5 sm3 md2 class="font-weight-bold font-14">
              Contacto con Thai Trade Center Mexico :
            </v-flex>
            <v-flex xs9 class="pl-2">
              {{ data.contace_thai_trade_center_mexico }}
            </v-flex>
          </v-layout>

          <v-layout class="title-desc">
            <v-flex xs5 sm3 md2 class="font-weight-bold"> Tel : </v-flex>
            <v-flex xs9 class="pl-2">{{ data.tel }}</v-flex>
          </v-layout>

          <v-layout class="title-desc">
            <v-flex xs5 sm3 md2 class="font-weight-bold"> Fax : </v-flex>
            <v-flex xs9 class="pl-2">{{ data.fax }}</v-flex>
          </v-layout>

          <v-layout class="title-desc">
            <v-flex xs5 sm3 md2 class="font-weight-bold">
              Email :
            </v-flex>
            <v-flex xs9 class="pl-2">
              <a :href="'mailto:' + data.email" class="mr-3" target="_blank">
                {{ data.email }}
              </a>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-container class="detail-spanish">
      <div class="v-html" v-html="data.detail"></div>
    </v-container>

    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import { domain_front } from "../../../services/Constants";

export default {
  components: { LoadingCircular },
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  metaInfo() {
    return {
      title: `Spanish - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: "Spanish News",
        },
        {
          property: "og:title",
          content: "Spanish - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: "Spanish News",
        },
        { property: "og:type", content: "spanish" },
        {
          property: "og:url",
          content: domain_front,
        },
        {
          property: "og:image",
          content: "../../../assets/images/default.png",
        },
      ],
    };
  },
  created() {
    this.getData();
    window.scrollTo(0, 0);
  },
  methods: {
    getData() {
      window.scrollTo(0, 0);
      this.loading = true;
      this.$service.spanish.getData().then((res) => {
        this.data = res.data;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.v-html-desktop {
  margin-bottom: 7rem;
}
.v-html-mobile {
  margin-bottom: 4rem;
}
.v-html >>> img {
  width: 100% !important;
  height: auto !important;
}
.v-html >>> iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 1.78341013825;
}
@media only screen and (max-width: 768px) {
  .v-html >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html >>> iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1.78341013825;
  }
}
.title-spanish {
  font-family: "ChulabhornLikitDisplay";
  font-style: normal;
  font-weight: 500;
  font-size: clamp(28px, 4.5vw, 64px);
  line-height: 100%;
}
.title-mobile-spanish {
  font-family: "ChulabhornLikitDisplay";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
}
.title-desc {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
}
.detail-spanish {
  margin-top: 2rem;
}
</style>
