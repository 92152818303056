<template>
  <div>
    <div v-if="banner">
      <BannerComponent :data="banner" />
    </div>

    <v-container style="margin-bottom: 7rem">
      <v-row class="mt-4 mt-sm-0 mt-md-6">
        <v-col
          cols="12"
          sm="6"
          v-for="category in categorys"
          :key="category.id"
          class="px-md-8 py-md-8 px-sm-6 py-sm-4 px-6 py-6"
        >
          <v-row>
            <v-col cols="5">
              <v-img
                :src="category.thumbnail"
                aspect-ratio="1"
                :alt="category.name"
                lazy-src="../../../assets/images/default/default_1_1.png"
              ></v-img>
            </v-col>
            <v-col cols="7" class="pl-4 pl-sm-3 pl-md-6">
              <span
                class="title-category text-uppercase font-weight-bold"
                @click="$router.push(`/partner-list?category=${category.id}`)"
              >
                {{ category.name }}
              </span>
              <template v-if="category.subcategorys.length > 0">
                <div
                  v-for="subcategory in category.subcategorys"
                  :key="subcategory.id"
                >
                  <span
                    class="title-subcategory"
                    @click="
                      $router.push(
                        `/partner-list?category=${category.id}&subcategory=${subcategory.id}`
                      )
                    "
                  >
                    {{ subcategory.name }}
                  </span>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import BannerComponent from "../../../components/banner/BannerComponent.vue";
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import { domain_front } from "../../../services/Constants";

export default {
  components: { BannerComponent, LoadingCircular },
  data() {
    return {
      banner: null,
      categorys: [],
    };
  },
  metaInfo() {
    return {
      title: `Meet Trade Partner Category - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: "Meet Trade Partner Category on Thai Trade USA",
        },
        {
          property: "og:title",
          content: "Meet Trade Partner Category - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: "Meet Trade Partner Category on Thai Trade USA",
        },
        { property: "og:type", content: "Partner Category" },
        {
          property: "og:url",
          content: domain_front + `partners`,
        },
        {
          property: "og:image",
          content: this.banner ? this.banner.path_mobile : "",
        },
      ],
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getDataPartner();
  },
  methods: {
    getDataPartner() {
      this.loading = true;
      this.$service.partner
        .getDataPartner()
        .then((res) => {
          const { banner, categorys } = res.data;
          this.banner = banner;
          this.categorys = categorys;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title-category {
  font-family: "ChulabhornLikitText";
  font-style: normal;
  font-weight: 600;
  font-size: clamp(14px, 1.5vw, 18px);
  line-height: 26px;
}
.title-category:hover {
  cursor: pointer;
  color: #0066b3;
}
.title-subcategory {
  font-family: "ChulabhornLikitText";
  font-style: normal;
  font-weight: normal;
  font-size: clamp(12px, 1.5vw, 14px);
  line-height: 26px;
}
.title-subcategory:hover {
  cursor: pointer;
  color: #0066b3;
}
</style>
