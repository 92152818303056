<template>
  <div>
    <div class="news" v-if="news">
      <v-layout
        justify-start
        class="news-title-detail pb-3 pt-2"
        style="background: #f9f9f9;"
      >
        <v-flex>
          <v-container class="mt-0 pt-0">
            <v-layout
              justify-center
              :class="
                $vuetify.breakpoint.smAndUp
                  ? 'title-detail'
                  : 'title-detail-mobile'
              "
            >
              <v-flex>
                {{ news.title }}
              </v-flex>
            </v-layout>
            <div class="created_date mt-3">{{ news.created_at }}</div>
            <div class="author">{{ news.author }}</div>
            <div class="company">{{ news.company }}</div>
          </v-container>
        </v-flex>
      </v-layout>
      <v-container>
        <div class="detail mt-md-6 mb-16 v-html" v-html="news.detail"></div>
      </v-container>
    </div>
    <loading-circular :loading="loading" />
  </div>
</template>

<script>
import LoadingCircular from "../../../components/loading/LoadingCircular.vue";
import { domain_front } from "../../../services/Constants";

export default {
  components: { LoadingCircular },
  data() {
    return {
      news: [],
      loading: false,
    };
  },
  metaInfo() {
    return {
      title: `${this.news ? this.news.title : ""} - ThaiTradeUSA.com`,
      meta: [
        {
          name: "description",
          content: this.news ? this.news.title : "" + " on Thai Trade USA",
        },
        {
          property: "og:title",
          content: this.news ? this.news.title : "" + " - ThaiTradeUSA.com",
        },
        { property: "og:site_name", content: "ThaiTradeUSA" },
        {
          property: "og:description",
          content: this.news ? this.news.title : "" + " on ThaiTradeUSA.com",
        },
        { property: "og:type", content: "News Project Detail" },
        {
          property: "og:url",
          content: domain_front + `project/${this.$route.params.domain}`,
        },
        {
          property: "og:image",
          content: "../../../assets/images/default.png",
        },
      ],
    };
  },
  created() {
    this.getNewsProductDetail();
    window.scrollTo(0, 0);
  },
  methods: {
    getNewsProductDetail() {
      this.loading = true;
      this.$service.news
        .getNewsProductDetail(this.$route.params.domain)
        .then((res) => {
          this.news = res.data;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.v-html >>> img {
  width: 100% !important;
  height: auto !important;
}
.v-html >>> iframe {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 1.78341013825;
}
@media only screen and (max-width: 768px) {
  .v-html >>> img {
    width: 100% !important;
    height: auto !important;
  }
  .v-html >>> iframe {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 1.78341013825;
  }
}
</style>

<style lang="scss">
.news .news-title-detail {
  .title-detail {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 100%;
    // padding-bottom: 12%;
  }

  .title-detail-mobile {
    font-family: "ChulabhornLikitDisplay";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
  }
  .created_date {
    font-family: "ChulabhornLikitText", "Prompt";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 26px;
    color: #6a6a6a;
  }
  .author {
    font-family: "ChulabhornLikitText", "Prompt";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }
  .company {
    font-family: "ChulabhornLikitText", "Prompt";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }
}
</style>
