<template>
  <v-btn
    height="52"
    min-width="254"
    class="text-capitalize font-weight-bold font-cl px-12 py-3"
    outlined
    :color="color"
    tile
    style="font-size: clamp(14px, 2.5vw, 14px)"
  >
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    text: {
      ype: String,
      default: "Text",
    },
    color: {
      type: String,
      default: "#000",
    },
  },
};
</script>

<style></style>
