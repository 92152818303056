<template>
  <v-row>
    <v-col cols="5" sm="2">
      <v-img
        width="100%"
        :src="item.thumbnail"
        aspect-ratio="1"
        :alt="item.title"
        cover
        lazy-src="../../assets/images/default/default_4_3.png"
      ></v-img>
    </v-col>
    <v-col cols="7" sm="10" class="pl-1 pl-sm-3">
      <div
        class="font-main font-weight-bold font-14 mb-sm-2 mb-1 "
        :class="$vuetify.breakpoint.xs ? 'ellipsis-1' : ''"
        :style="$vuetify.breakpoint.xs ? { color: '#0066B3' } : ''"
      >
        <span
          class="link-hover"
          @click="
            getPartnerDetail(
              item.id,
              item.status_form,
              item.website,
              item.title
            )
          "
        >
          {{ item.title }}
        </span>
      </div>
      <div
        class="font-main font-14"
        :class="$vuetify.breakpoint.xs ? 'ellipsis-2' : 'ellipsis-2'"
      >
        {{ item.description }}
      </div>
      <div class="font-main font-14 mt-0 mt-sm-2">
        <span
          class="partner-subcategory mr-3"
          v-for="meet_subcate in item.meet_trade_partner_subcategorys"
          :key="meet_subcate.id"
        >
          {{ meet_subcate.subcategory.name }}
        </span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    item: null,
  },
};
</script>

<style></style>
