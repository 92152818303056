<template>
  <v-btn
    fab
    small
    :loading="loading"
    depressed
    style="background: #E2E2E2"
    class="text-capitalize font-cl font-weight-bold"
    @click="$emit('on-click')"
  >
    <v-icon>mdi-arrow-down</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    onClick: Function,
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
