<template>
  <div>
    <!-- Desktop size -->
    <v-layout wrap justify-center v-if="$vuetify.breakpoint.mdAndUp">
      <v-flex
        class="shrink new-project-card ma-3 pa-3"
        v-if="data.status_1 == 1"
      >
        <v-img
          @click="onClickData(data.link_1)"
          width="80"
          aspect-ratio="1"
          :src="data.image_1"
          :alt="data.link_1"
        >
        </v-img>
      </v-flex>
      <v-flex
        class="shrink new-project-card ma-3 pa-3"
        v-if="data.status_2 == 1"
      >
        <v-img
          @click="onClickData(data.link_2)"
          width="80"
          aspect-ratio="1"
          :src="data.image_2"
          :alt="data.link_2"
        >
        </v-img>
      </v-flex>
      <v-flex
        class="shrink new-project-card ma-3 pa-3"
        v-if="data.status_3 == 1"
      >
        <v-img
          @click="onClickData(data.link_3)"
          width="80"
          aspect-ratio="1"
          :src="data.image_3"
          :alt="data.link_3"
        >
        </v-img>
      </v-flex>
      <v-flex
        class="shrink new-project-card ma-3 pa-3"
        v-if="data.status_4 == 1"
      >
        <v-img
          @click="onClickData(data.link_4)"
          width="80"
          aspect-ratio="1"
          :src="data.image_4"
          :alt="data.link_4"
        >
        </v-img>
      </v-flex>
      <v-flex
        class="shrink new-project-card ma-3 pa-3"
        v-if="data.status_5 == 1"
      >
        <v-img
          @click="onClickData(data.link_5)"
          width="80"
          contain
          aspect-ratio="1"
          :src="data.image_5"
          :alt="data.link_5"
        >
        </v-img>
      </v-flex>
    </v-layout>

    <!-- Mobile size -->
    <v-layout wrap justify-center v-else class="project-mobile">
      <v-flex class="shrink mt-6 mb-8">
        <v-sheet :color="color" class="mx-auto" elevation="0" width="100%">
          <v-slide-group class="">
            <slide-item
              v-if="data.status_1 == 1"
              :onClickItem="onClickData"
              :image="data.image_1"
              :link="data.link_1"
            />
            <slide-item
              v-if="data.status_2 == 1"
              :onClickItem="onClickData"
              :image="data.image_2"
              :link="data.link_2"
            />
            <slide-item
              v-if="data.status_3 == 1"
              :onClickItem="onClickData"
              :image="data.image_3"
              :link="data.link_3"
            />
            <slide-item
              v-if="data.status_4 == 1"
              :onClickItem="onClickData"
              :image="data.image_4"
              :link="data.link_4"
            />
            <slide-item
              v-if="data.status_5 == 1"
              :onClickItem="onClickData"
              :image="data.image_5"
              :link="data.link_5"
            />
          </v-slide-group>
        </v-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import SlideItem from "../slide/SlideItem.vue";
export default {
  components: { SlideItem },
  props: {
    data: null,
    color: {
      type: String,
      default: "#F9F9F9",
    },
  },
  methods: {
    onClickData(link) {
      window.open(link);
    },
    checkImage(n) {},
  },
};
</script>

<style></style>
