<template>
  <div style="position: relative">
    <!-- Banner type video and image -->
    <div v-if="data.type == 1" class="container-slide">
      <v-layout wrap class="d-flex justify-md-center align-center">
        <v-flex xs12 sm12 md5>
          <div class="v-html" v-html="data.youtube_url"></div>
        </v-flex>
        <v-flex xs12 sm12 md7 class="mt-n2 mt-sm-0">
          <v-img
            cover
            width="100%"
            height="auto"
            :src="
              $vuetify.breakpoint.mdAndUp
                ? data.banner_image_desktop
                : data.banner_image_mobile
            "
          >
          </v-img>
        </v-flex>
      </v-layout>
    </div>

    <!-- Banner type image slide -->
    <div v-if="data.type == 2">
      <v-layout
        :class="
          $vuetify.breakpoint.mdAndUp
            ? 'btn-slide-control-desktop'
            : 'btn-slide-control-mobile'
        "
      >
        <v-flex
          shrink
          v-for="i in $vuetify.breakpoint.smAndUp
            ? checkLengthDesktop
            : checkLengthMobile"
          :key="i"
          class="d-flex align-end ml-2 mb-n2"
        >
          <v-btn
            width="25"
            height="25"
            fab
            depressed
            :outlined="isActiveCarousel == i - 1 ? false : true"
            :color="isActiveCarousel == i - 1 ? '#000' : '#000'"
            small
            dark
            @click="handleChange(i - 1)"
          >
            {{ i }}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-carousel
        class="banner-slide pa-0 ma-0"
        :continuous="true"
        :show-arrows="false"
        hide-delimiter-background
        height="auto"
        cycle
        v-model="currentIndex"
        @change="handleChange"
        hide-delimiters
      >
        <v-carousel-item
          eager
          v-if="data.slide_image_desktop_1 && data.slide_image_mobile_1"
        >
          <a :href="data.url_banner1" target="_blank">
            <v-img
              eager
              width="100%"
              height="auto"
              cover
              :src="
                $vuetify.breakpoint.mdAndUp
                  ? data.slide_image_desktop_1
                  : data.slide_image_mobile_1
              "
            />
          </a>
        </v-carousel-item>

        <v-carousel-item
          eager
          v-if="data.slide_image_desktop_2 && data.slide_image_mobile_2"
        >
          <a :href="data.url_banner2" target="_blank">
            <v-img
              eager
              width="100%"
              height="auto"
              cover
              :src="
                $vuetify.breakpoint.mdAndUp
                  ? data.slide_image_desktop_2
                  : data.slide_image_mobile_2
              "
            />
          </a>
        </v-carousel-item>

        <v-carousel-item
          eager
          v-if="data.slide_image_desktop_3 && data.slide_image_mobile_3"
        >
          <a :href="data.url_banner3" target="_blank">
            <v-img
              eager
              width="100%"
              height="auto"
              cover
              :src="
                $vuetify.breakpoint.mdAndUp
                  ? data.slide_image_desktop_3
                  : data.slide_image_mobile_3
              "
            >
            </v-img>
          </a>
        </v-carousel-item>

        <v-carousel-item
          eager
          v-if="data.slide_image_desktop_4 && data.slide_image_mobile_4"
        >
          <a :href="data.url_banner4" target="_blank">
            <v-img
              eager
              width="100%"
              height="auto"
              cover
              :src="
                $vuetify.breakpoint.mdAndUp
                  ? data.slide_image_desktop_4
                  : data.slide_image_mobile_4
              "
            />
          </a>
        </v-carousel-item>

        <v-carousel-item
          eager
          v-if="data.slide_image_desktop_5 && data.slide_image_mobile_5"
        >
          <a :href="data.url_banner5" target="_blank">
            <v-img
              eager
              width="100%"
              height="auto"
              cover
              :src="
                $vuetify.breakpoint.mdAndUp
                  ? data.slide_image_desktop_5
                  : data.slide_image_mobile_5
              "
            />
          </a>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: null,
  },
  data() {
    return {
      isActiveCarousel: 0,
      currentIndex: 0,
    };
  },
  computed: {
    checkLengthDesktop() {
      let i = 0;
      if (this.data) {
        if (this.data.slide_image_desktop_1) {
          i = i + 1;
        }
        if (this.data.slide_image_desktop_2) {
          i = i + 1;
        }
        if (this.data.slide_image_desktop_3) {
          i = i + 1;
        }
        if (this.data.slide_image_desktop_4) {
          i = i + 1;
        }
        if (this.data.slide_image_desktop_5) {
          i = i + 1;
        }
      }
      return i;
    },
    checkLengthMobile() {
      let i = 0;
      if (this.data) {
        if (this.data.slide_image_mobile_1) {
          i = i + 1;
        }
        if (this.data.slide_image_mobile_2) {
          i = i + 1;
        }
        if (this.data.slide_image_mobile_3) {
          i = i + 1;
        }
        if (this.data.slide_image_mobile_4) {
          i = i + 1;
        }
        if (this.data.slide_image_mobile_5) {
          i = i + 1;
        }
      }
      return i;
    },
  },
  methods: {
    handleChange(val) {
      this.currentIndex = val;
      this.isActiveCarousel = val;
    },
  },
};
</script>

<style scoped>
.v-html {
  /* position: relative;
  padding-bottom: 50.25%;
  padding-top: 25px;
  height: 0; */
  max-height: 300px;
}
.v-html >>> iframe {
  /* position: absolute !important;
  top: 0 !important;
  left: 0 !important; */
  width: 100%;
  height: 300px;
  /* min-height: 300px; */
  max-height: 300px;
}
/* @media screen and (max-width: 768px) {
  .v-html >>> iframe {
    width: 100% !important;
    max-height: 432px !important;
  }
}

@media screen and (max-width: 425px) {
  .v-html >>> iframe {
    width: 100% !important;
    max-height: 211px !important;
  }
}
} */
</style>

<style>
.img-banner {
  width: 100%;
  max-height: 303px;
  object-fit: cover;
}
.banner-slide .v-carousel__controls {
  justify-content: flex-end !important;
}
/* @media (max-width: 768px) { */
.btn-slide-control-mobile {
  font-family: Chulabhorn Likit Text;
  position: absolute;
  z-index: 99;
  bottom: 5%;
  right: 3%;
}
/* } */
.btn-slide-control-desktop {
  font-family: Chulabhorn Likit Text;
  position: absolute;
  z-index: 9999999;
  bottom: 5%;
  right: 2%;
}
</style>
