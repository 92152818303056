<template>
  <div class=" fill-height">
    <v-layout class="d-flex justify-center align-center fill-height">
      <v-flex xs12 class="text-center mt-n16">
        <v-icon style="font-size: 7rem" color="red">mdi-alert</v-icon>
        <div style="font-size: 48px" class=" font-weight-bold">
          404 Page Not Found.
        </div>

        <div class="mt-3">
          <v-btn outlined to="/" depressed tile>
            Back to Homepage
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
