<template>
  <!-- <div> -->
  <div
    class=" d-flex pl-3 pr-1 px-sm-6 navbar-con-fixed"
    style="border-bottom: 1px solid #F9F9F9; z-index: 9999999999"
  >
    <!-- :class="
      $vuetify.breakpoint.smAndDown ? 'navbar-con-fixed' : 'navbar-con-fixed'
    " -->
    <v-layout class="justify-space-between d-flex align-center">
      <!-- logo -->
      <v-flex class="xs6 sm4 md4 pr-6">
        <v-img
          width="269"
          height="53"
          @click="$router.push('/')"
          class="cursor--pointer"
          src="@/assets/images/logo.png"
          alt="Thai Trade Center"
          contain
        ></v-img>
      </v-flex>

      <!-- Search -->
      <v-flex
        v-if="$vuetify.breakpoint.mdAndUp"
        class="md4 d-flex justify-center align-center"
        style="color: #000"
      >
        <v-layout class="input--search">
          <v-flex class="shrink d-flex align-center">
            <span class="pl-3 pr-1" style="border-right: 1px solid #E8E8F2"
              >Search</span
            >
          </v-flex>
          <v-flex class="d-flex align-center">
            <v-text-field
              flat
              solo
              dense
              hide-details
              v-model="keyword"
              @keyup.enter="onHandleSearch()"
            ></v-text-field>
          </v-flex>
          <v-flex v-if="keyword" class="shrink d-flex align-center pr-2">
            <v-btn
              depressed
              fab
              small
              icon
              width="30"
              height="30"
              color="#fff"
              @click="keyword = ''"
            >
              <v-icon color="#BCBCBC">
                mdi-close-circle-outline
              </v-icon>
            </v-btn>
          </v-flex>
          <v-flex class="shrink d-flex align-center pr-2">
            <v-btn
              depressed
              fab
              small
              width="30"
              height="30"
              color="#fff"
              @click="onHandleSearch()"
            >
              <v-icon color="#000">
                mdi-magnify
              </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex class="xs6 sm6 md4 d-flex align-center justify-end">
        <v-layout justify-end align-center>
          <!-- Language -->
          <v-flex shrink class="mr-2 mr-md-8 font-14">
            <v-layout>
              <v-flex
                shrink
                class="th link-hover"
                @click="$router.push('/thaipage')"
              >
                <span v-if="$vuetify.breakpoint.smAndUp">ภาษาไทย</span>
                <span v-else>ไทย</span>
              </v-flex>
              <v-flex class="mx-2">
                <v-divider style="border-color:  #000" vertical></v-divider>
              </v-flex>
              <v-flex
                shrink
                class="es link-hover"
                @click="$router.push('/spanish')"
              >
                <span v-if="$vuetify.breakpoint.smAndUp">Español</span>
                <span v-else>Es</span>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-divider
            style="border-color:  #000"
            v-if="$vuetify.breakpoint.mdAndUp"
            class=" mr-3"
            vertical
          ></v-divider>

          <!-- Search Mobile Size -->
          <v-flex
            shrink
            v-if="$vuetify.breakpoint.smAndDown"
            class="search-mobile"
          >
            <v-dialog
              transition="dialog-top-transition"
              v-model="dialog"
              width="90%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small fab color="#000" v-bind="attrs" v-on="on">
                  <v-icon color="#0066B3">
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </template>

              <v-card style="border-radius: 15px">
                <v-card-title class="text-h5 grey lighten-2">
                  Search
                </v-card-title>

                <v-card-actions>
                  <v-text-field
                    class="search-mobie"
                    flat
                    outlined
                    dense
                    color="#000"
                    hide-details
                    v-model="keyword"
                  ></v-text-field>
                </v-card-actions>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    color="#0066B3"
                    depressed
                    dark
                    width="100"
                    @click="onHandleSearch()"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>

          <!-- Icon Hamberger -->
          <v-flex shrink>
            <!-- Menu -->
            <v-menu
              offset-y
              min-width="100%"
              tile
              nudge-bottom="7"
              allow-overflow
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  text
                  small
                  fab
                  color="#000"
                  @click="isOpenMenu = !isOpenMenu"
                >
                  <img width="25" src="@/assets/images/hamburger2.png" />
                </v-btn>
              </template>
              <v-card tile>
                <v-sheet class="card-menu" height="auto" color="#0066B3">
                  <v-btn
                    top
                    absolute
                    right
                    text
                    color="#fff"
                    @click="isOpenMenu = !isOpenMenu"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-container
                    class="py-12"
                    :class="$vuetify.breakpoint.xs ? 'menu-mobile' : ''"
                  >
                    <v-row align="start" justify="center">
                      <v-col lg="10" md="11" sm="12">
                        <v-layout
                          v-if="$vuetify.breakpoint.smAndUp"
                          wrap
                          :column="$vuetify.breakpoint.xs"
                          class="menu-list justify-space-around d-flex align-start"
                        >
                          <v-flex shrink>
                            <ul class="no-bullets">
                              <li class="mb-2">
                                <a href="/">
                                  <span class="topic-menu">HOME</span>
                                </a>
                              </li>
                              <li class="mb-2">
                                <a href="/news">
                                  <span class="topic-menu">NEWS</span>
                                </a>
                              </li>
                              <li class="mb-2">
                                <a href="/about-us">
                                  <span class="topic-menu">ABOUT US</span>
                                </a>
                              </li>
                              <li class="mb-2">
                                <a href="/faq">
                                  <span class="topic-menu">FAQ</span>
                                </a>
                              </li>
                            </ul>
                          </v-flex>
                          <v-flex shrink>
                            <div class="topic-menu">
                              <a href="/partners">
                                MEET TRADE PARTNERS
                              </a>
                            </div>
                            <ul class="no-bullets">
                              <li v-for="(category, i) in categorys" :key="i">
                                <span
                                  @click="
                                    onClickCategory(category.id, 'partner')
                                  "
                                >
                                  {{ category.name }}
                                </span>
                              </li>
                            </ul>
                          </v-flex>
                          <v-flex shrink>
                            <div class="topic-menu">
                              <a href="/events">
                                EVENTS
                              </a>
                            </div>
                            <ul class="no-bullets">
                              <li v-for="(category, i) in categorys" :key="i">
                                <span
                                  @click="onClickCategory(category, 'events')"
                                >
                                  {{ category.name }}
                                </span>
                              </li>
                            </ul>
                          </v-flex>
                          <v-flex shrink>
                            <div class="topic-menu">
                              <a href="/contact-us">
                                CONTACT US
                              </a>
                            </div>
                            <ul class="no-bullets">
                              <li>
                                <a href="/contact-us/america">
                                  <span>North America</span>
                                </a>
                              </li>
                              <li>
                                <a href="/contact-us/thailand">
                                  <span>Thailand</span>
                                </a>
                              </li>
                              <li>
                                <a href="/contact-us/other">
                                  <span>Other Countries</span>
                                </a>
                              </li>
                            </ul>
                          </v-flex>
                        </v-layout>

                        <v-layout
                          v-else
                          wrap
                          column
                          class="menu-list-mobile justify-start d-flex align-start pl-6 mb-16"
                        >
                          <v-flex shrink>
                            <ul class="no-bullets">
                              <li class="mb-2">
                                <a href="/">
                                  <span class="topic-menu">HOME</span>
                                </a>
                              </li>
                            </ul>
                          </v-flex>
                          <v-flex shrink mt-6>
                            <div class="topic-menu">
                              <a href="/partners">
                                MEET TRADE PARTNERS
                              </a>
                            </div>
                            <ul class="no-bullets">
                              <li v-for="(category, i) in categorys" :key="i">
                                <span
                                  @click="
                                    onClickCategory(category.id, 'partner')
                                  "
                                >
                                  {{ category.name }}
                                </span>
                              </li>
                            </ul>
                          </v-flex>
                          <v-flex shrink mt-6>
                            <div class="topic-menu">
                              <a href="/events">
                                EVENTS
                              </a>
                            </div>
                            <ul class="no-bullets">
                              <li v-for="(category, i) in categorys" :key="i">
                                <span
                                  @click="onClickCategory(category, 'events')"
                                >
                                  {{ category.name }}
                                </span>
                              </li>
                            </ul>
                          </v-flex>

                          <v-flex shrink mt-6>
                            <ul class="no-bullets">
                              <li class="mb-6">
                                <a href="/news">
                                  <span class="topic-menu">NEWS</span>
                                </a>
                              </li>
                              <li class="mb-6">
                                <a href="/about-us">
                                  <span class="topic-menu">ABOUT US</span>
                                </a>
                              </li>
                              <li class="mb-6">
                                <a href="/faq">
                                  <span class="topic-menu">FAQ</span>
                                </a>
                              </li>
                            </ul>
                          </v-flex>
                          <v-flex shrink>
                            <div class="topic-menu">
                              <a href="/contact-us">
                                CONTACT US
                              </a>
                            </div>
                            <ul class="no-bullets">
                              <li>
                                <a href="/contact-us/america">
                                  <span>North America</span>
                                </a>
                              </li>
                              <li>
                                <a href="/contact-us/thailand">
                                  <span>Thailand</span>
                                </a>
                              </li>
                              <li>
                                <a href="/contact-us/other">
                                  <span>Other Countries</span>
                                </a>
                              </li>
                            </ul>
                          </v-flex>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-card>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- <v-bottom-sheet
      class="menu-desktop"
      v-model="isOpenMenu"
      transition="slide-y-transition"
    >
      <v-sheet class="card-menu" height="auto" color="#0066B3">
        <v-btn
          top
          absolute
          right
          text
          color="#fff"
          @click="isOpenMenu = !isOpenMenu"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-container
          class="py-12"
          :class="$vuetify.breakpoint.xs ? 'menu-mobile' : ''"
        >
          <v-row align="start" justify="center">
            <v-col lg="10" md="11" sm="12">
              <v-layout
                v-if="$vuetify.breakpoint.smAndUp"
                wrap
                :column="$vuetify.breakpoint.xs"
                class="menu-list justify-space-around d-flex align-start"
              >
                <v-flex shrink>
                  <ul class="no-bullets">
                    <li class="mb-2">
                      <a href="/">
                        <span class="topic-menu">HOME</span>
                      </a>
                    </li>
                    <li class="mb-2">
                      <a href="/news">
                        <span class="topic-menu">NEWS</span>
                      </a>
                    </li>
                    <li class="mb-2">
                      <a href="/about-us">
                        <span class="topic-menu">ABOUT US</span>
                      </a>
                    </li>
                    <li class="mb-2">
                      <a href="/faq">
                        <span class="topic-menu">FAQ</span>
                      </a>
                    </li>
                  </ul>
                </v-flex>
                <v-flex shrink>
                  <div class="topic-menu">
                    <a href="/partners">
                      MEET TRADE PARTNERS
                    </a>
                  </div>
                  <ul class="no-bullets">
                    <li v-for="(category, i) in categorys" :key="i">
                      <span @click="onClickCategory(category.id, 'partner')">
                        {{ category.name }}
                      </span>
                    </li>
                  </ul>
                </v-flex>
                <v-flex shrink>
                  <div class="topic-menu">
                    <a href="/events">
                      EVENTS
                    </a>
                  </div>
                  <ul class="no-bullets">
                    <li v-for="(category, i) in categorys" :key="i">
                      <span @click="onClickCategory(category.id, 'events')">
                        {{ category.name }}
                      </span>
                    </li>
                  </ul>
                </v-flex>
                <v-flex shrink>
                  <div class="topic-menu">
                    <a href="/contact-us">
                      CONTACT US
                    </a>
                  </div>
                  <ul class="no-bullets">
                    <li>
                      <a href="/contact-us/america">
                        <span>North America</span>
                      </a>
                    </li>
                    <li>
                      <a href="/contact-us/thailand">
                        <span>Thailand</span>
                      </a>
                    </li>
                    <li>
                      <a href="/contact-us/other">
                        <span>Other Countries</span>
                      </a>
                    </li>
                  </ul>
                </v-flex>
              </v-layout>

              <v-layout
                v-else
                wrap
                column
                class="menu-list-mobile justify-start d-flex align-start pl-6 mb-16"
              >
                <v-flex shrink>
                  <ul class="no-bullets">
                    <li class="mb-2">
                      <a href="/">
                        <span class="topic-menu">HOME</span>
                      </a>
                    </li>
                  </ul>
                </v-flex>
                <v-flex shrink mt-6>
                  <div class="topic-menu">
                    <a href="/partners">
                      MEET TRADE PARTNERS
                    </a>
                  </div>
                  <ul class="no-bullets">
                    <li v-for="(category, i) in categorys" :key="i">
                      <span @click="onClickCategory(category.id, 'partner')">
                        {{ category.name }}
                      </span>
                    </li>
                  </ul>
                </v-flex>
                <v-flex shrink mt-6>
                  <div class="topic-menu">
                    <a href="/events">
                      EVENTS
                    </a>
                  </div>
                  <ul class="no-bullets">
                    <li v-for="(category, i) in categorys" :key="i">
                      <span @click="onClickCategory(category.id, 'events')">
                        {{ category.name }}
                      </span>
                    </li>
                  </ul>
                </v-flex>

                <v-flex shrink mt-6>
                  <ul class="no-bullets">
                    <li class="mb-6">
                      <a href="/news">
                        <span class="topic-menu">NEWS</span>
                      </a>
                    </li>
                    <li class="mb-6">
                      <a href="/about-us">
                        <span class="topic-menu">ABOUT US</span>
                      </a>
                    </li>
                    <li class="mb-6">
                      <a href="/faq">
                        <span class="topic-menu">FAQ</span>
                      </a>
                    </li>
                  </ul>
                </v-flex>
                <v-flex shrink>
                  <div class="topic-menu">
                    <a href="/contact-us">
                      CONTACT US
                    </a>
                  </div>
                  <ul class="no-bullets">
                    <li>
                      <a href="/contact-us/america">
                        <span>North America</span>
                      </a>
                    </li>
                    <li>
                      <a href="/contact-us/thailand">
                        <span>Thailand</span>
                      </a>
                    </li>
                    <li>
                      <a href="/contact-us/other">
                        <span>Other Countries</span>
                      </a>
                    </li>
                  </ul>
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-bottom-sheet> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    keyword: null,
    dialog: false,
    drawer: false,
    isOpenMenu: false,
    group: null,
    acctiveMenu: "Home",
    menu: [
      { name: "Home", link: "/", icon: "mdi-home" },
      { name: "Event", link: "/events", icon: "mdi-checkerboard" },
    ],
    cookie: false,
  }),
  computed: {
    ...mapState({
      categorys: (state) => state.category.categorys,
    }),
    styleMenu() {
      return this.sheet
        ? {
            height: "100px",
            opacity: "1",
            transition: "height 1s opacity 3s",
          }
        : {
            height: "0px",
            opacity: "0",
            transition: "height 1s opacity 3s",
          };
    },
  },
  watch: {
    sheet(val) {
      this.style_menu;
      this.opacity = val ? "1" : "0";
      this.height = val ? "100px" : "0px";
    },
    $route(to, from) {
      if (to.name !== "Search") {
        this.keyword = "";
      }
    },
  },
  created() {
    this.getCategorys();
    if (this.$route.name === "Search") {
      this.keyword = this.$route.query.keyword;
    }
  },
  mounted() {
    this.cookie = localStorage.getItem("cookie:accepted");
  },
  methods: {
    getCategorys() {
      this.$store.dispatch("category/getCategorys");
    },
    onClickMenu(item) {
      this.acctiveMenu = item.name;
      this.drawer = false;
    },
    onClickCategory(id, type = "") {
      if (type == "events") {
        this.$router.push(
          `/events/category/${id.name.replaceAll(" ", "-")}-${id.id}`
        );
      } else {
        this.$router.push(`/partner-list?category=${id}`);
      }
      this.isOpenMenu = false;
    },
    onHandleSearch() {
      if (this.keyword) {
        this.$router
          .push({
            path: `/search/events?keyword=${this.keyword}`,
            params: { type: "events" },
          })
          .catch(() => {});
        this.dialog = false;
      }
    },
  },
};
</script>
<style lang="css">
.navbar-con-fixed {
  position: fixed;
  background: #fcfcfc;
  width: 100%;
  /* z-index: 99999999 !important; */
}
.navbar-con {
  position: relative;
  height: 60px;
  overflow-y: scroll;
  background: #fcfcfc;
  /* z-index: 99999999 !important; */
}
.topic-menu {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  margin-bottom: 5px;
}
.topic-menu:hover {
  text-decoration: underline;
  cursor: pointer;
}
ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul.no-bullets li {
  font-family: "ChulabhornLikitText", "Prompt";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
  width: 100%;
}
ul li span:hover {
  text-decoration: underline;
  cursor: pointer;
}
.card-menu {
  position: relative;
  color: #fff !important;
  box-shadow: 0px 3px 5px -3px rgb(0 0 0) !important;
}
.btn-register {
  background: linear-gradient(95.03deg, #0066b3 0%, #0f1e5f 100%);
}
button.btn-language.text-capitalize.v-btn.v-btn--text.theme--dark.v-size--default:before {
  background-color: #fff !important;
}
@media only screen and (max-width: 600px) {
  .search-mobie i.v-icon.notranslate.mdi.mdi-magnify.theme--light {
    color: #0066b3;
  }
}
@media only screen and (min-width: 600px) {
  .input--search {
    border: 1px solid #000;
  }
  .search--nav .v-input__icon.v-input__icon--append {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
  .search--nav i.v-icon.notranslate.mdi.mdi-magnify.theme--light {
    color: rgb(8, 5, 5);
  }
  .search--nav
    .v-text-field.v-text-field--solo.v-input--dense
    > .v-input__control {
    border-radius: 21px;
    min-height: 35px;
  }
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 12px;
    padding-top: 0px;
    padding-right: 3px !important;
    padding-bottom: 0px;
    padding-left: 15px !important;
  }
}
.active--menu {
  background-color: #0065b35e;
  color: #fff;
}
.active--lang {
  background-color: #0065b35e;
  color: #fff;
}

.menu-mobile {
  overflow-y: scroll;
  height: 100vh;
}
</style>
