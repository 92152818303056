<template>
  <div>
    <v-slide-item class="ma-3 new-project-card">
      <v-card width="95" color="#fff" flat>
        <v-row class="fill-heigh" align="center" justify="center">
          <v-scale-transition>
            <v-img
              contain
              width="100%"
              height="95"
              @click="onClickItem(link)"
              aspect-ratio="1"
              :src="image"
            >
            </v-img>
          </v-scale-transition>
        </v-row>
      </v-card>
    </v-slide-item>
  </div>
</template>

<script>
export default {
  props: {
    link: String,
    image: String,
    onClickItem: Function,
  },
};
</script>

<style></style>
