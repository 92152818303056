import Service from "../../services";

const state = {
  categorys: [],
};

const getters = {};

const actions = {
  getCategorys({ commit }) {
    return Service.category.getData().then((res) => {
      commit("SET_CATEGORY", res.data);
    });
  },
};

const mutations = {
  SET_CATEGORY(state, data) {
    state.categorys = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
