var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.label)?_c('label',{staticClass:"input-label",attrs:{"for":_vm.label}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" * ")]):_vm._e()]):_vm._e(),_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","rules":_vm.required
        ? _vm.label === 'Email' || _vm.label === 'Email อีเมล์'
          ? [
              (v) => !!v || 'E-mail is required',
              (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ]
          : [(v) => !!v || 'Field is required']
        : [],"value":_vm.model,"required":_vm.required,"readonly":_vm.readonly,"placeholder":_vm.placeholder},on:{"input":function($event){return _vm.$emit('updateData', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }