<template>
  <div>
    <v-img
      width="100%"
      :height="$vuetify.breakpoint.smAndUp ? '238' : 'auto'"
      :src="
        $vuetify.breakpoint.smAndUp
          ? data.path_desktop
          : data.path_mobile
          ? data.path_mobile
          : data.path_desktop
      "
      cover
      :alt="data.title"
    >
    </v-img>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
};
</script>

<style></style>
